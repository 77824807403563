import React, { useState, useRef, useEffect } from "react";
import "./LandingPage.scss";
import Div100vh from "react-div-100vh";
import { Link, Navigate, useNavigate } from "react-router-dom";

interface FullScreenVideoProps {
  authed?: boolean;
}

const FullScreenVideo: React.FC<FullScreenVideoProps> = ({ authed }) => {
  const [videoError, setVideoError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 740);
  const [videoPlaying, setVideoPlaying] = useState(false); // Represents if the video is currently playing
  const [videoLoaded, setVideoLoaded] = useState(false); // New state for if the video has loaded and is ready to be played
  const videoRef = useRef<HTMLVideoElement | null>(null);

  //const linkTarget = authed ? "/positive-ev" : "/login";
  const linkTarget = "/positive-ev";
  const handleVideoError = () => {
    setVideoError(true);
  };

  // const navigate = useNavigate();

  // const handleCta = () => {
  //   // if (authed) {
  //   <Link to="/positive-ev" className="your-cta-class">Call to Action Text</Link>
  //   // } else {
  //   // navigate("/login");
  //   // }
  // };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }

      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 740);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="video-container lower-z">
      {videoError ? (
        <img
          src="https://storage.googleapis.com/assestsbookiebrawler/background-images/brawler-landing-page-pic.jpg"
          alt="poster"
          className="fullscreen-video"
        />
      ) : (
        <>
          <video
            ref={videoRef}
            loop
            autoPlay
            muted
            playsInline
            className="fullscreen-video"
            onError={handleVideoError}
            onPlaying={() => setVideoPlaying(true)}
            onPause={() => setVideoPlaying(false)}
            onEnded={() => setVideoPlaying(false)}
            onLoadedMetadata={() => setVideoLoaded(true)} // Set videoLoaded to true when the video is ready to be played
          >
            <source
              src="https://storage.googleapis.com/assestsbookiebrawler/demos/ev-new-demo-vid.mov"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </>
      )}
      <div className="overlay"></div>
      <div className="zInd ctaContainer">
        <h1 className="ctaText f-display-2 mb-3">
          Cultivating tools for collective advantage of the sports betting
          community
        </h1>
        <Link to={linkTarget} className="no-underline">
          <button className="ctaButton rounded">Begin Betting Smarter</button>
        </Link>
      </div>
      {videoLoaded && (
        <button onClick={handlePlayPause} className="play-pause-button p-2 dark-bg">
          {isPlaying ? (
            <>
              <span className="inline-flex items-center">
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  className="w-4 h-4" // Tailwind class for width and height
                >
                  <g fill="currentColor">
                    <rect x="9.81" y="0" width="1.38" height="12"></rect>
                    <rect x="4.81" y="0" width="1.38" height="12"></rect>
                  </g>
                </svg>
                <span className="ml-2">{!isMobile && " Pause Video"}</span>
              </span>
            </>
          ) : isMobile ? (
            "\u25B6"
          ) : (
            "\u25B6 Play Video"
          )}
        </button>
      )}
    </div>
  );
};

export default FullScreenVideo;
