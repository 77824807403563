import React, { useState, useEffect } from 'react';

const NoVigCalculator: React.FC = () => {
  const [odds1, setOdds1] = useState<number | string>(110);
  const [odds2, setOdds2] = useState<number | string>(110);
  const [noVigPct1, setNoVigPct1] = useState<number>(0);
  const [noVigOdds1, setNoVigOdds1] = useState<number>(0);
  const [noVigPct2, setNoVigPct2] = useState<number>(0);
  const [noVigOdds2, setNoVigOdds2] = useState<number>(0);

  useEffect(() => {
    if (odds1 !== '' && odds2 !== '') {
      const o1 = Number(odds1);
      const o2 = Number(odds2);

      const impliedProb1 = o1 > 0 ? 100 / (o1 + 100) : -o1 / (-o1 + 100);
      const impliedProb2 = o2 > 0 ? 100 / (o2 + 100) : -o2 / (-o2 + 100);
      const totalImpliedProb = impliedProb1 + impliedProb2;

      const noVigProb1 = impliedProb1 / totalImpliedProb;
      const noVigProb2 = impliedProb2 / totalImpliedProb;

      const newNoVigOdds1 = noVigProb1 > 0.5 ? -100 * noVigProb1 / (1 - noVigProb1) : 100 / noVigProb1 - 100;
      const newNoVigOdds2 = noVigProb2 > 0.5 ? -100 * noVigProb2 / (1 - noVigProb2) : 100 / noVigProb2 - 100;

      setNoVigPct1(noVigProb1 * 100);
      setNoVigOdds1(newNoVigOdds1);
      setNoVigPct2(noVigProb2 * 100);
      setNoVigOdds2(newNoVigOdds2);
    }
  }, [odds1, odds2]);

  return (
    <div className="container max-w-[500px] w-auto p-4 bg-gray-800 rounded-lg text-white border-[0.1px]">
      <div className="grid grid-cols-3 gap-4 items-center ">
        <div className="col-span-1">
          <span className='block mb-2 h-12 flex items-center font-bold text-sm '>Odds</span>
          <input
            type="number"
            id="odds1"
            value={odds1}
            onChange={(e) => setOdds1(e.target.value)}
            className="w-full p-2 bg-gray-900 rounded h-12"
          />
          <input
            type="number"
            id="odds2"
            value={odds2}
            onChange={(e) => setOdds2(e.target.value)}
            className="w-full p-2 bg-gray-900 rounded mt-2 h-12"
          />
        </div>
        <div className="col-span-1">
          <span className='block mb-2 h-12 flex items-center font-bold text-sm'>No-Vig %</span>
          <div className="p-2 h-12 flex items-center justify-center  text-sm font-medium">{noVigPct1.toFixed(2)}%</div>
          <div className="p-2 mt-2 h-12 flex items-center justify-center  text-sm font-medium">{noVigPct2.toFixed(2)}%</div>
        </div>
        <div className="col-span-1">
          <span className='block mb-2 h-12 flex items-center  font-bold text-sm'>No-Vig Odds</span>
          <div className="p-2 h-12 flex items-center justify-center  text-sm font-medium">{noVigOdds1 > 0 ? `+${noVigOdds1.toFixed(2)}` : noVigOdds1.toFixed(2)}</div>
          <div className="p-2 mt-2 h-12 flex items-center justify-center  text-sm font-medium">{noVigOdds2 > 0 ? `+${noVigOdds2.toFixed(2)}` : noVigOdds2.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};

export default NoVigCalculator;
