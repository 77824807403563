import { Suggestion } from "./SuggestionWall";

  
  export const previewSuggestions: Suggestion[] = [
    {
      id: 1,
      content: "A well developed filtering system is a must in my experience. Being able to exclude certain odds ranges, leagues, matches, and even markets on a match is also crucial",
      created_user_id: 0, // Can be replaced by the actual user ID
      voted_user_details: {},
      rating: 8
    },
    {
      id: 2,
      content: "The tool should find and display new value bets for pre-match in a matter of seconds. Otherwise it's pretty useless unfortunately, because bettors with a premium or a decent paid tool will bet before you could even open the bookie.",
      created_user_id: 0, // Can be replaced by the actual user ID
      voted_user_details: {},
      rating: 7
    },
    {
      id: 3,
      content: "Option to view odds in decimal or american format",
      created_user_id: 0, // Can be replaced by the actual user ID
      voted_user_details: {},
      rating: 4
    },
    {
      id: 4,
      content: "Using real time statistics to historically compare multiple sports books and their accuracy - maybe even giving them a grade. That way when we are line shopping or +EV we can easily and quickly compare odds on multiple books",
      created_user_id: 0, // Can be replaced by the actual user ID
      voted_user_details: {},
      rating: 4
    },
    {
      id: 5,
      content: "A notification system that sends updates on odd changes on the different platforms for a specific sport or actual event. This can help if someone is a sharp better and want to focus on one specific thing.",
      created_user_id: 0, // Can be replaced by the actual user ID
      voted_user_details: {},
      rating: 3
    },
    {
      id: 6,
      content: "seconds is too much time. it should be done in a second.",
      created_user_id: 0, // Can be replaced by the actual user ID
      voted_user_details: {},
      rating: 1
    }
  ];
  