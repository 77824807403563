import React, { useState, useEffect } from 'react';

interface VigCalculatorProps {
  isHold?: boolean;
}

const VigCalculator: React.FC<VigCalculatorProps> = ({ isHold }) => {
  const [odds1, setOdds1] = useState<number | string>(100);
  const [odds2, setOdds2] = useState<number | string>(110);
  const [percentage, setPercentage] = useState<number>(0);

  const calculateImpliedProbability = (odd: number) => {
    return odd > 0 ? 100 / (odd + 100) : Math.abs(odd) / (Math.abs(odd) + 100);
  };

  useEffect(() => {
    const o1 = Number(odds1);
    const o2 = Number(odds2);

    const impliedProb1 = calculateImpliedProbability(o1);
    const impliedProb2 = calculateImpliedProbability(o2);

    const totalImpliedProbability = impliedProb1 + impliedProb2;
    const vig = totalImpliedProbability - 1;

    setPercentage(vig * 100);
  }, [odds1, odds2]);

  return (
    <div className="container max-w-[280px] w-full p-4 bg-gray-800 rounded-lg text-white border-[0.5px]">
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <span className='w-1/2 font-bold text-sm'>Bet #1 Odds</span>
          <div className="relative w-1/2">
            <input
              type="number"
              value={odds1}
              onChange={(e) => setOdds1(e.target.value)}
              className="w-full p-2 bg-gray-900 rounded"
            />
          </div>
        </div>
        <div className="flex items-center">
          <span className='w-1/2 font-bold text-sm'>Bet #2 Odds</span>
          <div className="relative w-1/2">
            <input
              type="number"
              value={odds2}
              onChange={(e) => setOdds2(e.target.value)}
              className="w-full p-2 bg-gray-900 rounded"
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">{isHold ? 'Hold %' : 'Vig %'}</span>
            <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">
              {percentage.toFixed(2)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VigCalculator;
