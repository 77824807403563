import React from 'react';
import FAQComponent from "./Faq";
import styles from "../../login/login.module.scss";
import { FAQ } from './faqs.types';


interface FAQSComponentProps {
  faqs: FAQ[];
}

const FAQSComponent: React.FC<FAQSComponentProps> = ({ faqs }) => {
  return (
    <div className="faqs-container dark-bg p-2 m-4 light-top-brder">
      <h2 className={styles.shine + ' text-center pt-2'}>Frequently Asked Questions</h2>
      <div id="faqAccordion">  
        {faqs.map((faq, index) => (
          <FAQComponent key={index} faq={faq} />
        ))}
      </div>
    </div>
  );
};

export default FAQSComponent;
