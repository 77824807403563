// User.ts
export interface User {
  id: number;
  firstname: string;
  handle: string;
  lastname: string;
  email: string;
  password: string;
  picture: string;
  customer_id: string | null;
  role: Role;
  enabled: boolean;
  username: string;
  blacklist: Blacklist;
  account_non_expired: boolean;
  account_non_locked: boolean;
  credentials_non_expired: boolean;
  tokens: Token[];
}

interface Blacklist {
  bookmaker_keys: string[];
}


// Role.ts
export enum Role {
  USER = "User",
  BRONZE = "Bronze",
  SILVER = "Silver",
  GOLD = "Gold",
  ADMIN = "Admin"
}


export function stringToRole(roleStr: string): Role | null {
  const normalizedRoleStr = roleStr.toUpperCase();

  switch (normalizedRoleStr) {
    case "USER":
      return Role.USER;
    case "BRONZE":
      return Role.BRONZE;
    case "SILVER":
      return Role.SILVER;
    case "GOLD":
      return Role.GOLD;
    case "ADMIN":
      return Role.ADMIN;
    default:
      console.warn(`Unknown role: ${roleStr}`);
      return null;
  }
}




// Token.ts
enum TokenType {
  BEARER = "BEARER",
}

interface Token {
  id: number;
  token: string;
  tokenType: TokenType;
  revoked: boolean;
  expired: boolean;
  refreshToken: boolean;
  user: User;


}


