import React from "react";
import GoogleButtonLogin from "./GoogleButtonLogin";

interface SignInModalProps {
  show: boolean;
  toggleModal: () => void;
  onLogin: () => void;
  isAuthenticated: boolean;
}

const SignInModal: React.FC<SignInModalProps> = ({
  show,
  toggleModal,
  onLogin,
  isAuthenticated,
}) => {
  if (!show) {
    return null;
  }


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 bg-gray-700">
      {/* Modal content container */}
      <div className="p-3  min-w-[20vw]  rounded-lg shadow-xl dark-bg">
        {/* Modal header */}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl text-white leading-6 font-medium text-gray-900">Sign Up/In</h3>
          <button onClick={toggleModal} className="text-gray-500 hover:text-gray-700 rounded p-2">
            <span className="text-white">✕</span>
          </button>
        </div>
        {/* Modal body */}
        <div className="flex justify-center">
          <GoogleButtonLogin
            onLogin={onLogin}
            showOneTap={!isAuthenticated}
          />
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
