import React, { useState } from "react";
import { postRequest } from "../../services/apiService";
import { Suggestion } from "./SuggestionWall";
import { Modal, Button } from "react-bootstrap";
import "./SuggestionComp.scss";
import { getVoteStatus } from "./suggestion-service";
import { Helmet } from "react-helmet";

interface SuggestionProps {
  suggestion: Suggestion;
  userId: number;
  onDelete: (suggestionId: number) => void;
}

const SuggestionComp: React.FC<SuggestionProps> = ({
  suggestion,
  userId,
  onDelete,
}) => {
  const [currentSuggestion, setCurrentSuggestion] =
    useState<Suggestion>(suggestion);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = () => {
    onDelete(suggestion.id);
    handleClose();
  };

  const handleVote = async (isUpvote: boolean) => {
    const action = isUpvote ? "upvote" : "downvote";
    const endpoint = `/api/v1/suggestions/${currentSuggestion.id}/${action}`;
    try {
      const updatedSuggestion = await postRequest(endpoint, {});
      if (updatedSuggestion && updatedSuggestion.id) {
        setCurrentSuggestion(updatedSuggestion);
      } else {
        console.error(
          `Unexpected data format from endpoint: ${endpoint}`,
          updatedSuggestion
        );
      }
    } catch (error) {
      console.error(`Error upvoting suggestion: ${endpoint}`, error);
    }
  };

  // console.log(getVoteStatus(currentSuggestion, userId), "voteStatus");

  return (
    <>
    <Helmet>
    <title>Suggestions | BookieBrawler </title>
    <link rel="canonical" href="https://bookiebrawler.com/suggestions" />
    <meta
      name="description"
      content="Offer suggestions and feedback to help BookBrawler improve!"
    />
    <meta name="robots" content="index,follow"></meta>
  </Helmet>
    <div className="suggestion-border-bottom height-auto w-100 ">
      <div key={currentSuggestion.id} className="suggestion-grid mt-3 mb-3">
        <div className="icon-container">
          {" "}
          {/* This div will hold the icons and the rating */}
          {/* <div className="effect"></div> */}
          <img
            src="https://storage.googleapis.com/assestsbookiebrawler/icons/upvote.svg"
            className={` p-2 vote-icon ${
              getVoteStatus(currentSuggestion, userId) === "upvoted"
                ? "upvoted"
                : ""
            }`}
            alt="Upvote"
            onClick={() => handleVote(true)}
            style={{ cursor: "pointer", width: "32px", height: "32px" }}
          />
          <div>{currentSuggestion.rating}</div>
          <img
            src="https://storage.googleapis.com/assestsbookiebrawler/icons/downvote.svg"
            className={`p-2 vote-icon ${
              getVoteStatus(currentSuggestion, userId) === "downvoted"
                ? "downvoted"
                : ""
            }`}
            alt="Downvote"
            onClick={() => handleVote(false)}
            style={{ cursor: "pointer", width: "32px", height: "32px" }}
          />
        </div>
        <p className="text-container post-text-light mb-0">
          {currentSuggestion.content}
        </p>
        <div className="d-flex" style={{}}></div>
       
        <div className="d-flex justify-content-end">
        

       
       {suggestion.created_user_id ===userId &&( <img
            src="https://storage.googleapis.com/assestsbookiebrawler/icons/delete-icon.svg"
            className={`p-2 delete-icon`}
            alt="Delete Suggestion"
            onClick={handleShow}
            style={{ cursor: "pointer", width: "32px", height: "32px" }}
          />)}

          {/* <button className="w-50" onClick={handleShow}>
            Delete
          </button> */}
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          size="sm"       
        >
          <Modal.Header closeButton className="custom-modal-text custom-modal-content">
            <Modal.Title className="custom-modal-text ">
              Confirm Deletion
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-modal-text custom-modal-content">
            Are you sure you want to delete this suggestion? This action cannot
            be undone.
          </Modal.Body>
          <Modal.Footer className="custom-modal-text custom-modal-content">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
    </>
  );
};

export default SuggestionComp;
