import { FAQ } from "../../../components/landing-page/faqs/faqs.types";
import { ReactNode } from "react";
import {
  arbitrageBettingFAQs,
  bonusBetCalculatorFAQs,
  evCalculatorFAQs,
  impliedProbabilityFAQs,
  noVigCalculatorFAQs,
  parlayCalculatorFAQs,
  vigCalculatorFAQs,
  kellyCriterionFAQs,
  holdCalculatorFAQs,
  oddsConverterFAQs,
  poissonCriterionFAQs
} from "../../../components/landing-page/faqs/faq.constants";
import NoVigCalculator from "./calculator-nodes/no-vig-calculator";
import BettingCalculator from "./calculator-nodes/arbitrage-calculator";
import ExpectedValueCalculator from "./calculator-nodes/expected-value-calculator";
import ParlayCalculator from "./calculator-nodes/parlay-calculator";
import ParlayCalculatorWriteup from "./calculator-writeups/parlay-calculator-writeup";
import VigCalculator from "./calculator-nodes/vig-calculator";
import VigCalculatorWriteup from "./calculator-writeups/vig-calculator-writeup";
import BonusBetConverter from "./calculator-nodes/bonus-bet-converter";
import BonusBetConverterWriteup from "./calculator-writeups/bonus-bet-converter";
import ImpliedOddsCalculator from "./calculator-nodes/implied-odds-calculator";
import ImpliedOddsWriteUp from "./calculator-writeups/implied-odds-calculator-writeup";
import KellyCriterionCalculator from "./calculator-nodes/kelly-calculator";
import KellyCriterionWriteUp from "./calculator-writeups/kelly-calculator-writeup";
import HoldCalculatorWriteUp from "./calculator-writeups/hold-calculator-writeup";
import OddsConverterWriteUp from "./calculator-writeups/odds-converter-writeup";
import PoissonCalculator from "./calculator-nodes/poisson-calculator";
import PoissonCalculatorWriteUp from "./calculator-writeups/poisson-calculator-writeup";
import { createATag } from "../../../services/common-util-service";

export interface ICalculator {
  slug: string;
  title: string;
  description: string;
  content: ReactNode;
  shortTitle: string;
  calculatorNode?: ReactNode;
  faqs?: FAQ[];
}

export const calculators: ICalculator[] = [
  {
    slug: "arbitrage-calculator",
    title: "Arbitrage Calculator",
    description:
      "The Arbitrage Calculator finds optimal bet amounts for risk free betting opportunities",
    content: (
      <>
        <p className="mb-3">
          Hedge bet calculators are critical in sports betting, as they show you
          how to reduce risk.
        </p>
        <p className="mb-3">
          Arbitrage betting, or hedging a bet, is a less risky approach to
          betting. It involves placing proportional bets on every possible
          outcome of an event (with different bookmakers) so regardless of what
          happens, you will find success. A sports betting arbitrage calculator,
          or hedge bet calculator, shows you how to hedge a bet and lock in a
          return. You can read more about arbitrage betting in the linked blog
          post above, which contains an example of a profitable hedge bet.
          Arbitrage exists because bookmakers set their odds independently; in
          other words, the odds on Fanduel are not the same as the odds on
          DraftKings. When these odds get "out of sync," arbitrage can exist,
          which allows you to hedge your bets.
        </p>
        <p className="mb-3">
          If you enter the odds for any two-way market in the hedge bet
          calculator to the left, it will work out if there is an arbitrage
          opportunity and tell you how much you need to stake. You input how
          much money you are willing to wager on one side, and we show you
          exactly how much you need to bet on the other. It doesn't matter
          whether you are betting $50 or $500, our arbitrage calculator will
          show you exactly how much money you will earn with each arbitrage
          opportunity.
        </p>
        <p className="mb-3">
          If you have any questions about our arbitrage calculator, please don't
          hesitate to reach out to us at{" "}
          <a
            href="mailto:notifications@bookiebrawler.com"
            className="text-blue-400 underline"
          >
            notifications@bookiebrawler.com
          </a>
          . While our full Arbitrage Betting Tool is coming soon, the calculator
          is fully available for you to use. We're here to help you make the
          most of it and answer any inquires you might have.
        </p>
        {createATag("betting-education/arbitrage","What's Arbitrage?")}
        {createATag("/aribtrage","Arbitrage Betting Tool")}
      </>
    ),
    shortTitle: "Arbitrage Calculator",
    faqs: arbitrageBettingFAQs,
    calculatorNode: <BettingCalculator />,
  },
  {
    slug: "no-vig-fair-odds",
    title: "No-Vig Fair Odds Calculator & Sports Betting",
    description:
      "No-vig calculators are essential in sports betting, as they help you determine the true odds by removing the bookmaker's margin, ensuring fair and profitable bets.",
    content: (
      <div>
        <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
          How to Use a No-Vig Calculator
        </h2>
        <p className="mb-3">
          A no-vig fair odds calculator helps determine the "fair" odds in a
          market by removing the vig, or juice. This tool is essential for
          comparing markets and odds and finding the implied win percentages for
          a team. By learning to calculate fair odds without vig, you can
          identify mathematically profitable bets, or positive expected value,
          at sharp sportsbooks like Circa.
        </p>

        <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
          Understanding Vig
        </h3>
        <p className="mb-3">
          Sportsbooks make money by charging a "spread" on all wagers. The
          prices they offer are typically not "fair" and include a vig, juice,
          or house edge. For example, consider a game between the Phoenix Suns
          and Boston Celtics with moneyline odds of -110 for both teams. If one
          bettor wagers $110 on the Celtics and another places $110 on the Suns,
          one bettor will win, and one will lose. The sportsbook pays $100
          profit ($110 bet at -110 odds) to the winner and collects $110 from
          the loser, earning a $10 profit. This business model allows bookmakers
          to profit with no risk by encouraging equal and opposite wagers.
        </p>

        <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
          Calculating Fair Odds
        </h3>
        <p className="mb-3">
          Odds are associated with win probabilities, but including the vig
          means these probabilities are not "fair." For example, if Ohio State
          is a -180 favorite against Utah, a +155 underdog, an odds converter
          shows Ohio State should win 64.3% of the time, and Utah 39.2% of the
          time. These probabilities add up to 103.5% because of the 3.5% juice.
          The no-vig calculator removes this juice to find the fair odds.
        </p>

        <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
          Example
        </h3>
        <p className="mb-3">
          For Ohio State vs. Utah, the fair win probability is recalculated:
        </p>
        <ul>
          <li>Ohio State: 62.1% (down from 64.3%)</li>
          <li>Utah: 37.9% (down from 39.2%)</li>
        </ul>
        <p className="mb-3">
          These probabilities now add up to 100%. The fair odds for this market
          would be -163.93 for Ohio State and +163.93 for Utah.
        </p>
      </div>
    ),
    shortTitle: "No-Vig Fair Odds Calculator",
    faqs: noVigCalculatorFAQs,
    calculatorNode: <NoVigCalculator />,
  },
  {
    slug: "expected-value",
    title: "Odds Expected Value Calculator",
    description:
      "The Expected Value Calculator measures the profit margin over the sportsbook for a given sports wager.",
    content: (
      <div>
        <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
          How to Use an Expected Value (EV) Calculator
        </h2>
        <p className="mb-3">
          An expected value (EV) calculator helps determine the potential
          profitability of a bet by calculating the average amount you can
          expect to win or lose per bet. This tool is essential for evaluating
          betting strategies and finding bets that offer positive expected
          value, which can lead to long-term profitability.
        </p>

        <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
          Understanding Expected Value
        </h3>
        <p className="mb-3">
          Expected value is a mathematical concept that calculates the expected
          outcome of a bet. It considers the probability of each possible
          outcome and the payout associated with each outcome. A positive
          expected value indicates a potentially profitable bet, while a
          negative expected value suggests a likely loss over time.
        </p>

        <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
          Calculating Expected Value
        </h3>
        <p className="mb-3">
          To calculate the expected value of a bet, you need to know the odds,
          the stake, and the win probability. The formula for expected value is:
        </p>
        <p className="mb-3 font-code-next">
          EV = (Win Probability * Payout) - (Loss Probability * Stake)
        </p>
        <p className="mb-3">
          Where:
          <ul>
            <li>
              <strong>Win Probability</strong> is the likelihood of winning the
              bet.
            </li>
            <li>
              <strong>Payout</strong> is the total amount received if the bet
              wins (including the stake).
            </li>
            <li>
              <strong>Stake</strong> is the amount wagered.
            </li>
            <li>
              <strong>Loss Probability</strong> is the likelihood of losing the
              bet (1 - Win Probability).
            </li>
          </ul>
        </p>

        <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
          Example
        </h3>
        <p className="mb-3">Consider a bet with the following details:</p>
        <ul>
          <li>
            <strong>Wager:</strong> $100
          </li>
          <li>
            <strong>Odds:</strong> +200
          </li>
          <li>
            <strong>Win Probability:</strong> 40%
          </li>
        </ul>
        <p className="mb-3">First, calculate the payout:</p>
        <p className="mb-3 font-code-next">
          Payout = $100 * (200 / 100) + $100 = $300
        </p>
        <p className="mb-3">Then, calculate the expected value:</p>
        <p className="mb-3 font-code-next">
          EV = (0.40 * $300) - (0.60 * $100) = $120 - $60 = $60
        </p>
        <p className="mb-3">
          The expected value of this bet is $60, indicating a potentially
          profitable bet in the long run.
        </p>
        {createATag("betting-education/positive-expected-value-sports-betting","What's EV?")}
        {createATag("positive-ev","Postive EV Betting Tool")}
      </div>
    ),
    shortTitle: "Expected Value Calculator",
    faqs: evCalculatorFAQs,
    calculatorNode: <ExpectedValueCalculator />,
  },
  {
    slug: "parlay-calculator",
    title: "Parlay Calculator",
    description:
      "The Parlay Calculator determines the potential payout for parlay bets, considering the odds of all individual bets.",
    content: <ParlayCalculatorWriteup />,
    shortTitle: "Parlay Calculator",
    faqs: parlayCalculatorFAQs,
    calculatorNode: <ParlayCalculator />,
  },
  {
    slug: "vig",
    title: "Vig Calculator",
    description:
      "The Vig Calculator determines the bookmaker's margin or 'vig' to help you find fair betting odds.",
    content: <VigCalculatorWriteup />,
    shortTitle: "Vig Calculator",
    faqs: vigCalculatorFAQs,
    calculatorNode: <VigCalculator />,
  },
  {
    slug: "free-bet-conversion",
    title: "Bonus Bet Conversion Calculator",
    description:
      "The Bonus Bet Converter calculates the cash value of bonus bets, helping you turn promotional offers into real money",
    content: <BonusBetConverterWriteup />,
    shortTitle: "Bonus Bet Conversion Calculator",
    faqs: bonusBetCalculatorFAQs,
    calculatorNode: <BonusBetConverter />,
  },
  {
    slug: "implied-probability",
    title: "Implied Probability Calculator",
    description:
      "The Implied Odds Calculator compares the implied probability of a betting market against the actual fair probability.",
    content: <ImpliedOddsWriteUp/>,
    shortTitle: "Implied Probability Calculator",
    faqs: impliedProbabilityFAQs,
    calculatorNode: <ImpliedOddsCalculator/>,
  },
  {
    slug: "kelly-criterion",
    title: "Kelly Criterion Calculator & Betting Tools",
    description:
      "The Kelly Criterion Calculator helps you manage your bankroll and optimize bet sizing for maximum growth.",
    content: <KellyCriterionWriteUp/>,
    shortTitle: "Kelly Calculator",
    faqs: kellyCriterionFAQs,
    calculatorNode: <KellyCriterionCalculator/>,
  },
  {
    slug: "hold",
    title: "Hold Calculator for Sports Betting",
    description:
      "The Hold Calculator shows the spread between both sides of a bet using the best available sportsbook odds.",
    content: <HoldCalculatorWriteUp/>,
    shortTitle: "Hold Calculator",
    faqs: holdCalculatorFAQs,
    calculatorNode: <VigCalculator isHold/>,
  },
  {
    slug: "odds-converter",
    title: "Odds Converter for Sports Betting",
    description:
      "The Odds Converter helps you switch between decimal, fractional, and American odds to better understand sports betting formats.",
    content: <OddsConverterWriteUp/>,
    shortTitle: "Odds Converter",
    faqs: oddsConverterFAQs,
    calculatorNode: <ImpliedOddsCalculator/>, 
  },
  {
    slug: "poisson",
    title: "Poisson Distribution Calculator for Sports Betting",
    description:
      "The Poisson Calculator estimates the probability of a specific event happening a certain number of times based on an average rate of occurrence.",
    content: <PoissonCalculatorWriteUp/>,
    shortTitle: "Poisson Calculator",
    faqs: poissonCriterionFAQs,
    calculatorNode: <PoissonCalculator/>, 
  }
  // Add other calculators here
];
