import { useState } from "react";
import BankrollForm from "./bankroll-form";

const BankrollContainer: React.FC = () => {
    const [showForm, setShowForm] = useState<boolean>(false);
  
    return (
      <div>
        <a href="#!" onClick={() => setShowForm(!showForm)}>
          Enter your bankroll here
        </a>
        {showForm && <BankrollForm show={showForm} />}
      </div>
    );
  };
  
  export default BankrollContainer;