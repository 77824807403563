import React from "react";

const PoissonCalculatorWriteUp: React.FC = () => {
  return (
    <div className="p-4 text-white mt-4">
      <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
        How to Use a Poisson Calculator
      </h2>
      <p className="mb-3">
        A Poisson calculator is a tool utilized by savvy bettors to determine
        the likelihood of a specific prop bet hitting. For example, if Stephen
        Curry averages 5.3 three-pointers per game, you can employ the Poisson
        calculator to estimate the probability and implied "fair odds" of
        Stephen Curry making 7+ three-pointers in a game. The Poisson calculator
        is highly beneficial for this task - it assists you in calculating win
        probabilities for certain bets based on historical averages.
      </p>
      <p className="mb-3">
        Imagine a sportsbook is offering a prop bet on Stephen Curry making over
        6.5 three-pointers at +300 odds. Based on historical performance, you
        know Curry averages 5.3 three-pointers per game. Is this bet
        statistically favorable?
      </p>
      <p className="mb-3">
        Given that three-pointers follow a Poisson distribution, which is
        mathematically valid, the probability of Curry making over 6.5
        three-pointers is around 18.2%, implying "fair" odds of approximately
        +450. Thus, betting on Curry to make over 6.5 three-pointers at +300
        odds would not be a favorable bet. You would need to find odds higher
        than the fair price implied by the Poisson distribution for it to be
        profitable. This showcases why a Poisson calculator is so essential for
        astute sports bettors.
      </p>
    </div>
  );
};

export default PoissonCalculatorWriteUp;
