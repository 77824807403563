import React from 'react';

const VigCalculatorWriteup: React.FC = () => {
  return (
    <div>
      <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">How to Use a Vig Calculator</h2>
      <p className="mb-3">
        A vig calculator helps you determine the vigorish (vig), or juice, that a sportsbook includes in its odds. By inputting the odds for two bets, the calculator computes the vig percentage. This tool is essential for understanding how much extra the bookmaker is charging and finding the true probability of outcomes.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Understanding Vig</h3>
      <p className="mb-3">
        The vig is the fee that a bookmaker charges for taking a bet. It ensures that the bookmaker makes a profit regardless of the outcome of the event. The vig is included in the odds, making them less favorable than the true probability of the outcomes.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Calculating Vig</h3>
      <p className="mb-3">
        The odds for each individual bet are converted to implied probabilities. These probabilities are then summed to get the total implied probability, which typically exceeds 100% because of the vig. The vig is calculated as the excess over 100%.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Example</h3>
      <p className="mb-3">
        Let's say you have the following odds for two bets:
      </p>
      <ul className="mb-3">
        <li>Bet #1: +100</li>
        <li>Bet #2: +110</li>
      </ul>
      <p className="mb-3">
        The vig calculator would convert these odds to implied probabilities, sum them, and calculate the vig percentage.
      </p>
      <p className="mb-3">
        The expected results might be:
      </p>
      <ul className="mb-3">
        <li>Implied Probability of Bet #1: 50%</li>
        <li>Implied Probability of Bet #2: 47.62%</li>
        <li>Total Implied Probability: 97.62%</li>
        <li>Vig %: -2.38%</li>
      </ul>
      <p className="mb-3">
        This means that the bookmaker is charging a vig of -2.38% on these bets.
      </p>
    </div>
  );
};

export default VigCalculatorWriteup;
