import React, { useState } from 'react';
import { createAnchorTag } from '../../../services/common-util-service';
import { calculators } from './betting-calculators-data';

const CalculatorCard: React.FC<{ calculator: any }> = ({ calculator }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="p-0 w-full sm:w-1/1 lg:w-1/2 relative"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <a className="no-underline" href={`/betting-calculators/${calculator.slug}`}>
        <div className={`bg-gray-800 text-white p-6 h-full flex flex-col justify-between rounded-xl`}>
          <div>
            <h3 className="font-bold text-powder-bloo text-lg mb-2">{calculator.shortTitle}</h3>
            <p className="text-sm text-gray-200">{calculator.description}</p>
          </div>
          {createAnchorTag(`/betting-calculators/${calculator.slug}`, hover)}
        </div>
      </a>
    </div>
  );
};

const CalculatorGrid: React.FC = () => {
  return (
    <div className="flex flex-wrap rounded-xl border-[1px] bg-gray-800">
      {calculators.map((calculator) => (
        <CalculatorCard key={calculator.slug} calculator={calculator} />
      ))}
    </div>
  );
};

export default CalculatorGrid;
