import React from "react";

interface ImageLinkProps {
  navigateUrl: string; // URL to navigate to when the image is clicked
}

const ESPNGameLink: React.FC<ImageLinkProps> = ({ navigateUrl }) => {
  // Placeholder for the constant image URL
  const imageUrl: string = navigateUrl.includes('espn') ? "https://espnpressroom.com/us/photos/galleries/2018/04/02/espn-and-espn-app-logos/app-icon-ios-mic-flag-cut-to-shape/": "https://storage.googleapis.com/assestsbookiebrawler/icons/brawler-search-icon-2.png";

  return (
    <a href={navigateUrl} target="_blank" rel="noopener noreferrer">
      <img
        src={imageUrl}
        alt="Stats"
        className="transition duration-300 ease-in-out transform hover:scale-110 hover:shadow-lg rounded-md h-7 w-7 max-w-14 max-h-14 mr-2"
        style={{
          borderRadius: "0.6rem",
          height: "27px",
          width: "27px",
          maxWidth: "57px",
          maxHeight: "57px",
          marginRight: "8px", 
        }}
      />
    </a>
  );
};

export default ESPNGameLink;
