import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  getCurrentUser,
  getRequest,
  putRequest,
} from "../../services/apiService";
import { getImagePathByMarketKey } from "../../services/bet-finder-service";
import { User } from "../types/User";

interface BookmakerLite {
  key: string;
  title: string;
}

const width = "55px";
const height = width;

const isBlackListed = (
  blackList: string[],
  bookmakerLite: BookmakerLite
): boolean => {
  return blackList.includes(bookmakerLite.key);
};

const BlackList: React.FC = () => {
  const [bookmakers, setBookmakers] = useState<BookmakerLite[]>([]);
  const [blackList, setBlackList] = useState<string[]>([]);
  const [savedBlackList, setSavedBlackList] = useState<string[]>([]);
  const [saveStatus, setSaveStatus] = useState<string | null>(null);

  const toggleOn = (bookmakerKey: string) => {
    if (!blackList.includes(bookmakerKey)) {
      setBlackList([...blackList, bookmakerKey]);
    }
  };

  // Function to remove a bookmaker key from the blacklist
  const toggleOff = (bookmakerKey: string) => {
    if (blackList.includes(bookmakerKey)) {
      setBlackList(blackList.filter((key) => key !== bookmakerKey));
    }
  };

  // Function to handle the toggle change
  const handleToggleChange = (bookmakerKey: string) => {
    if (blackList.includes(bookmakerKey)) {
      toggleOff(bookmakerKey);
    } else {
      toggleOn(bookmakerKey);
    }
  };

  const saveBlackList = async () => {
    try {
      const response = await putRequest("api/v1/users/blacklist", blackList);
      const updatedUser = response.data;
      setSavedBlackList(blackList);
      setBlackList(blackList);

      // Update save status on successful save
      setSaveStatus("Blacklist successfully saved!");
    } catch (error) {
      console.error("An error occurred while saving the blacklist:", error);

      // Update save status on error
      setSaveStatus("An error occurred while saving. Please try again.");
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await getRequest("/v1/bookmakers/bookmakerLite");
  //       setBookmakers(response.data);
  //     } catch (error) {
  //       console.error("An error occurred while fetching data:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    const getUserBlackList = async () => {
      try {
        const response = await getCurrentUser();
        const user = response.data;
        if (user && user.blacklist && user.blacklist.bookmaker_keys) {
          setBlackList(user.blacklist.bookmaker_keys);
          setSavedBlackList(user.blacklist.bookmaker_keys);
        } else {
          // handle cases where user or user.blacklist or user.blacklist.bookmaker_keys is undefined
          console.log("User, blacklist or bookmaker_keys is not defined");
        }
      } catch (error) {
        console.error("An error occurred while fetching user data:", error);
      }
    };

    getUserBlackList();
  }, []);

  return (
    <>
      <div className="d-flex flex-wrap justify-content-start m-4 p-4">
        {bookmakers.map((bookmaker, index) => (
          <div key={index} className="text-center p-3 m-3">
            <img
              src={getImagePathByMarketKey(bookmaker.key)}
              //alt={bookmaker.title}
              style={{ width, height }}
            />
            <p>{bookmaker.title}</p>
            {/* Simple Checkbox for Toggle */}
            <input
              type="checkbox"
              checked={!isBlackListed(blackList, bookmaker)}
              onChange={() => handleToggleChange(bookmaker.key)}
            />
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-end m-4 p-4">
        <Button
          variant="primary"
          disabled={
            JSON.stringify(savedBlackList.sort()) ===
            JSON.stringify(blackList.sort())
          }
          onClick={saveBlackList}
        >
          Save
        </Button>
      </div>
      <div className="d-flex justify-content-center m-4 p-4"></div>
      {/* <div className="d-flex justify-content-center">
        {saveStatus && <p>{saveStatus}</p>}
      </div> */}
    </>
  );
};

export default BlackList;
