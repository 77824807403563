import React from 'react';
import './UnauthModal.scss';

interface UnauthModalProps {
  message?: string;
  isVisible: boolean;
}

const UnauthModal: React.FC<UnauthModalProps> = ({ message = "Please sign in to use this component.", isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="unauth-modal-overlay">
      <div className="unauth-modal">
        <h5 className='unauth-modal-textbox'>{message}</h5>
      </div>
    </div>
  );
}

export default UnauthModal;
