import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculators, ICalculator } from './betting-calculators-data'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tailwindcss/tailwind.css';

interface PillGroupProps {
    activeSlug?: string;
}

const PillGroup: React.FC<PillGroupProps> = ({ activeSlug}) => {
    const navigate = useNavigate();
    const [selectedSlug, setSelectedSlug] = useState<string>(activeSlug??""); //default to none

    const handlePillClick = (slug: string) => {
        setSelectedSlug(slug);
        navigate(`/betting-calculators/${slug}`);
    };

    return (
        <div className="container">
            <div className="d-flex flex-wrap mb-3">
                {calculators.map((calculator) => (
                    <button
                        key={calculator.slug}
                        className={`btn btn-outline-primary border-powder-bloo m-1 text-powder-bloo rounded-full whitespace-nowrap ${selectedSlug === calculator.slug ? 'active bg-powder-bloo text-black ' : 'hover-bg-slate-dark'}`}
                        onClick={() => handlePillClick(calculator.slug)}
                    >
                        {calculator.shortTitle}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default PillGroup;
