import { bookieSignUpLinks } from "../../../components/products/common/bookie-image";
import React from "react";
import { ContentImage, ContentPost } from "../content-post-model"; // Adjust the import path as needed

export const fliffReviewImage: ContentImage = {
  id: 6,
  title: "Fliff Social Sportsbook Review",
  source: "Fliff",
  imageUrl: "https://oddsjam.com/_next/image?url=https%3A%2F%2Fapi-2.oddsjam.com%2Fwp%2Fwp-content%2Fuploads%2F2023%2F03%2Ffliff-social-sportsbook-branding-logo-on-navy-brandpurist-bence-bilekov-700.jpeg&w=3840&q=75",
};

export const betRiversReviewImage: ContentImage = {
  id: 3,
  title: "BetRivers Sportsbook Review",
  source: "BetRivers",
  imageUrl: "https://oddsjam.com/_next/image?url=https%3A%2F%2Fapi-2.oddsjam.com%2Fwp%2Fwp-content%2Fuploads%2F2022%2F04%2Fbaner2betrivers.jpg&w=3840&q=75",
};

export const espnBetReviewImage: ContentImage = {
  id: 4,
  title: "ESPN Bet Sportsbook Review",
  source: "EPSN Bet",
  imageUrl: "https://images.ctfassets.net/pnq4hpds29uh/1FjXyIO4BFlJhHQsY52Lh9/0e10dc1e328fedc97dc482e5229e4f00/NLAZXQRMUBHC5KDBROB76O52NE.jpg?fit=fill&w=1056&h=355&fm=webp&q=100",
};
export const sportsbookPosts: ContentPost[] = [
  {
    id: 6,
    title: "Fliff Social Sportsbook Review – Fliff Promo Codes",
    slug: "fliff",
    image: fliffReviewImage,
    summary:
      "Explore the latest Fliff promo codes, learn about their legitimacy, and find out how to maximize your betting experience with Fliff.",
    content: (
      <div>
        <div className="">
          {/* <div className="flex justify-center">
            <img
              className="w-full h-auto max-w-[1000px] min-w-[285px] light-brder rounded-xl m-5"
              src="https://oddsjam.com/_next/image?url=https%3A%2F%2Fapi-2.oddsjam.com%2Fwp%2Fwp-content%2Fuploads%2F2023%2F03%2Ffliff-social-sportsbook-branding-logo-on-navy-brandpurist-bence-bilekov-700.jpeg&w=3840&q=75"
              alt="Example of run pool tracking"
            />
          </div> */}
          {/* <a href={bookieSignUpLinks["fliff"]}>
            <img
              className="w-full h-auto max-w-[1000px] min-w-[285px] rounded-xl m-5"
              src="https://oddsjam.com/_next/image?url=https%3A%2F%2Fapi-2.oddsjam.com%2Fwp%2Fwp-content%2Fuploads%2F2023%2F03%2Ffliff-social-sportsbook-branding-logo-on-navy-brandpurist-bence-bilekov-700.jpeg&w=3840&q=75"
              alt="Fliff banner"
            />
          </a> */}
        </div>
        <h2 className="text-powder-bloo">Fliff Overview</h2>
        <p>
          Fliff launched in 2019 for casual sports bettors seeking low financial
          risk. It's available in most states as it falls under daily fantasy or
          sweepstakes rather than traditional sports betting.
        </p>
        <p>
          Fliff users earn and spend virtual currency redeemable for prizes. It
          encourages free play to compare picks with peers.
        </p>

        <h2 className="text-powder-bloo">
          Fliff Social Sportsbook Sign-Up Bonus
        </h2>
        <p>
          Fliff offers a unique sign-up bonus: a 100% Fliff Coin and Fliff Cash
          Bonus on your first purchase. Terms apply, and only new users over 18
          are eligible.
        </p>

        <h2 className="text-powder-bloo">Legal Status</h2>
        <p>
          Fliff operates in most US states but isn't legal in Canada. Some
          states have varying participation rules for Fliff Cash.
        </p>

        <h2 className="text-powder-bloo">Sports Available on Fliff</h2>
        <p>
          Fliff offers betting on major leagues (NFL, MLB, NBA, NHL) and more,
          including spreads, totals, player props, and live picks.
        </p>

        <h2 className="text-powder-bloo">How to Sign Up for Fliff</h2>
        <div className="">
          {/* <a href= {bookieSignUpLinks["fliff"]} className="no-underline">
            <button className="ctaButton mt-0">Try it out</button>
          </a */}
          <div>
            <div className="flex justify-center">
              <a href={bookieSignUpLinks["fliff"]}>
                <img
                  className="w-full h-auto max-w-[1000px] min-w-[200px] rounded-xl m-3"
                  src="https://oddsjam.com/_next/image?url=https%3A%2F%2Fapi-2.oddsjam.com%2Fwp%2Fwp-content%2Fuploads%2F2023%2F03%2FScreen-Shot-2023-03-27-at-11.05.14-AM-1024x401.png&w=2048&q=100"
                  alt="Fliff Sign Up"
                />
              </a>
            </div>
          </div>
        </div>
        <p>
          Signing up is easy via Apple, Twitter, Google, or email. Verify your
          phone number and claim your bonuses to start making picks.
        </p>

        <h2 className="text-powder-bloo">
          Fliff Purchase & Redemption Methods
        </h2>
        <p>
          Purchase Fliff Coins through the app store. Redeem Fliff Cash directly
          to your bank account.
        </p>

        <h2 className="text-powder-bloo">Fliff Rewards Program</h2>
        <p>
          Fliff doesn't have a traditional rewards program but offers prizes
          through XP gained by attaching virtual currency to picks.
        </p>

        <div className="flex justify-center">
          <a href={bookieSignUpLinks["fliff"]}>
            <img
              className="w-full h-auto max-w-[1000px] min-w-[285px] rounded-xl m-3"
              src="https://oddsjam.com/_next/image?url=https%3A%2F%2Fapi-2.oddsjam.com%2Fwp%2Fwp-content%2Fuploads%2F2023%2F03%2Ffliff-rewards-1024x364.jpg&w=2048&q=100"
              alt="Fliff app banner"
            />
          </a>
        </div>

        <h2 className="text-powder-bloo">Fliff Website & Mobile App</h2>
        <p>
          Fliff is only available as a mobile app. The desktop site directs
          users to the mobile app.
        </p>

        <h2 className="text-powder-bloo">Promotions on Fliff</h2>
        <p>Fliff offers daily odds boosts and challenges to earn more XP.</p>

        <h2 className="text-powder-bloo">Same-Game Parlays</h2>
        <p>
          Yes, Fliff offers same-game parlays, allowing users to combine picks
          from a single game into one wager.
        </p>

        <h2 className="text-powder-bloo">Is Fliff Legit?</h2>
        <p>
          Fliff is a legitimate and safe platform, legal in all states except
          Washington. It's a sweepstakes app rather than a traditional
          sportsbook.
        </p>

        <h2 className="text-powder-bloo">Should You Get Fliff?</h2>
        <p>
          Fliff is ideal for beginners and social bettors, offering real lines
          and odds without real-money risks.
        </p>
      </div>
    ),
    author: "BookieBrawler Team",
    publishedDate: new Date("2024-06-29"),
    coverPhoto: "https://via.placeholder.com/300x200",
  },
  {
    id: 3,
    title: "BetRivers Sportsbook Review – BetRivers Sportsbook Promo Code",
    slug: "betrivers",
    image: betRiversReviewImage,
    summary:
      "Discover how to unlock the BetRivers Sportsbook Promo Code and get a 100% deposit match bonus up to $500. Start betting with extra funds today!",
    content: (
      <>
        
          {/* <div className="flex justify-center">
            <a href={bookieSignUpLinks["betrivers"]}>
              <img
                className="w-full h-auto max-w-[1000px] rounded-xl m-2"
                src="https://oddsjam.com/_next/image?url=https%3A%2F%2Fapi-2.oddsjam.com%2Fwp%2Fwp-content%2Fuploads%2F2022%2F04%2Fbaner2betrivers.jpg&w=3840&q=75"
                alt="Betrivers logo large"
              />
            </a>
          </div>
     */}
        <p className="py-4 text-powder-bloo">Hey there, betting enthusiasts!</p>
        <p className="py-4 text-white text-opacity-75">
          <strong className="text-powder-bloo">BookieBrawler</strong> is excited
          to announce an exclusive partnership with{" "}
          <strong className="text-powder-bloo">BetRivers</strong>, offering all
          new users a 100% deposit match bonus up to $500 using the promo code{" "}
          <strong className="text-powder-bloo">CASINO500</strong>.
        </p>
        <p className="py-4 text-white text-opacity-75">
          This is one of the best sports betting sign-up bonuses on the market,
          providing a fantastic opportunity to grow your bankroll. With this
          promo, you’ll be up $500 after your first deposit!
        </p>
        <div className="">
        <div className="flex justify-center">
          <a href={bookieSignUpLinks["betrivers"]}>
            <img
              className="w-full h-auto max-w-[1000px] rounded-xl m-2"
              src="https://oddsjam.com/_next/image?url=https%3A%2F%2Fapi-2.oddsjam.com%2Fwp%2Fwp-content%2Fuploads%2F2023%2F04%2FDuelSportsand500Desktop2-1024x564.jpeg&w=2048&q=100"
              alt="Betrivers logo large"
            />
          </a>
        </div>
        </div>

        <p className="py-4 text-white text-opacity-75">
          Taking advantage of this sign-up bonus is simple: deposit $500 into
          BetRivers using the bonus code{" "}
          <strong className="text-powder-bloo">CASINO500</strong>, and you’ll
          automatically receive an additional $500 in site credit. In other
          words, if you deposit the full $500, then you’ll have $1,000 in your
          account thanks to this amazing promo.
        </p>
        <p className="py-4 text-white text-opacity-75">
          This deposit bonus has a 1x play-through requirement, meaning the site
          credit is converted directly into cash after being wagered once.
        </p>
        <div className="py-4 text-white text-opacity-75">
          <h3 className="text-powder-bloo">
            Where is BetRivers Sportsbook Legal?
          </h3>
          <p>
            BetRivers Sportsbook is currently legal in the following states:
            Arizona, Colorado, Connecticut, Illinois, Indiana, Iowa, Louisiana,
            Maryland, Michigan, New Jersey, New York, Ohio, Pennsylvania,
            Virginia, and West Virginia. It is also legal in Ontario, Canada!
          </p>
        </div>
        <div className="py-4 text-white text-opacity-75">
          <h3 className="text-powder-bloo">
            What Sports Can I Bet on with BetRivers?
          </h3>
          <p>
            BetRivers offers betting markets on all major sports, including NFL,
            NBA, NCAA Football, NCAA Basketball, NHL, MLB, ATP Tennis, WTA
            Tennis, Golf, and more. You can bet on mainline markets (e.g.
            moneylines), alternate markets, player props, game props, and
            futures.
          </p>
          <p>
            What sets BetRivers apart is the number of leagues offered.
            BetRivers includes odds from a vast selection of niche leagues, such
            as the Philippines Basketball Association (PBA). It’s incredible how
            many sports and leagues are available, putting customers first by
            offering so many different betting options.
          </p>
        </div>
        <p className="py-4 text-white">
          Best regards,
          <br />
          Marshall
          <br />
          Founder, BookieBrawler
        </p>
      </>
    ),
    author: "BookieBrawler Team",
    publishedDate: new Date("2024-07-04"),
    coverPhoto:
      "https://storage.googleapis.com/assestsbookiebrawler/gifs/loading-screen.gif",
  },
  {
    id: 4,
    title: "ESPN Bet Sportsbook Review – ESPN Bet Sportsbook Promo Code",
    slug: "espnbet",
    image: espnBetReviewImage,
    summary:
      "Discover the benefits of ESPN Bet, from exclusive bonuses and promotions to a wide range of sports and expert insights. Start your ultimate betting experience today!",
    content: (
      <>
        {/* <div className="">
          <div className="flex justify-center">
            <a href={bookieSignUpLinks["espnbet"]}>
              <img
                className="w-full h-auto max-w-[1000px] min-w-[285px] rounded-xl m-4"
                src="https://images.ctfassets.net/pnq4hpds29uh/1FjXyIO4BFlJhHQsY52Lh9/0e10dc1e328fedc97dc482e5229e4f00/NLAZXQRMUBHC5KDBROB76O52NE.jpg?fit=fill&w=1056&h=355&fm=webp&q=100"
                alt="ESPN Bet logo large"
              />
            </a>
          </div>
        </div> */}

        <p className="py-4 text-powder-bloo">
          Hello sports fans and betting enthusiasts!
        </p>
        <p className="py-4 text-white text-opacity-75">
          We’re thrilled to introduce you to{" "}
          <strong className="text-powder-bloo">ESPN Bet</strong>, the premier
          destination for sports betting that combines the trusted brand of ESPN
          with top-notch betting opportunities. With ESPN Bet, you’re not just
          placing wagers; you’re engaging with an intuitive platform that offers
          incredible bonuses, a wide range of sports, and expert insights to
          elevate your betting game. Let’s dive into what makes ESPN Bet the
          ultimate choice for sports bettors.
        </p>
        <div className="py-4 text-white text-opacity-75">
          <h3 className="text-powder-bloo">Why Choose ESPN Bet?</h3>
          <p>
            At <strong className="text-powder-bloo">ESPN Bet</strong>, we
            prioritize your betting experience by providing:
            <ul className="list-disc list-inside">
              <li>
                <strong>Exclusive Bonuses and Promotions</strong>: New users get
                free bets, providing a substantial boost to your profitabiltiy
                giving you a head start at compund interest. Regular promotions
                and offers ensure that you always have the chance to maximize
                your earnings.
              </li>
              <li>
                <strong>Wide Range of Sports</strong>: Bet on your favorite
                sports, including NFL, NBA, MLB, NHL, NCAA Football, NCAA
                Basketball, Tennis, Golf, and more. ESPN Bet covers major
                leagues and events, ensuring you never miss out on the action.
              </li>
              <li>
                <strong>User-Friendly Interface</strong>: The platform is
                designed for ease of use, with a clean and intuitive interface
                that makes placing bets and navigating through different options
                a breeze.
              </li>
              <li>
                <strong>Expert Insights and Analysis</strong>: Leverage ESPN’s
                deep reservoir of sports knowledge with expert analysis and
                insights, helping you make informed betting decisions.
              </li>
            </ul>
          </p>
        </div>
        <div className="py-4 text-white text-opacity-75">
          <h3 className="text-powder-bloo">How to Get Started with ESPN Bet</h3>
          <p>
            Getting started with{" "}
            <strong className="text-powder-bloo">ESPN Bet</strong> is quick and
            easy. Follow these steps to begin your ultimate betting experience:
            <ol className="list-decimal list-inside">
              <li>
                <strong>Sign Up</strong>: Create an account on the ESPN Bet
                website or app. The registration process is straightforward and
                secure.
              </li>
              <li>
                <strong>Deposit Funds</strong>: Fund your account using our safe
                and reliable payment options. Take advantage of our exclusive
                welcome bonus by using the promo code provided during
                registration.
              </li>
              <li>
                <strong>Place Your Bets</strong>: Explore the vast range of
                betting options and place your bets on your favorite sports and
                events.
              </li>
            </ol>
          </p>
        </div>
        <div className="py-4 text-white text-opacity-75">
          <h3 className="text-powder-bloo">Benefits of Using ESPN Bet</h3>
          <p>
            The benefits of betting with{" "}
            <strong className="text-powder-bloo">ESPN Bet</strong> go beyond
            just placing wagers. Here’s what you can look forward to:
            <ul className="list-disc list-inside">
              <li>
                <strong>Live Betting</strong>: Experience the thrill of live
                betting with real-time odds and updates. Bet on events as they
                unfold and make quick decisions to capitalize on the action.
              </li>
              <li>
                <strong>Enhanced Odds</strong>: Enjoy boosted odds on selected
                events, giving you better returns on your winning bets.
              </li>
              <li>
                <strong>Mobile Betting</strong>: Bet on the go with our
                responsive mobile app. Whether you’re at home or out and about,
                you can access ESPN Bet from your smartphone or tablet.
              </li>
              <li>
                <strong>Secure Transactions</strong>: Your security is our
                priority. All transactions on ESPN Bet are encrypted and
                processed through secure channels to protect your personal and
                financial information.
              </li>
              <li>
                <strong>24/7 Customer Support</strong>: Our dedicated customer
                support team is available around the clock to assist you with
                any questions or issues you may have.
              </li>
            </ul>
          </p>
        </div>
        <div className="py-4 text-white text-opacity-75">
          <h3 className="text-powder-bloo">Conclusion</h3>
          <p>
            Don’t miss out on the unparalleled betting experience that{" "}
            <strong className="text-powder-bloo">ESPN Bet</strong> offers. Sign
            up today and unlock exclusive bonuses and promotions that will
            enhance your sports betting journey. With a wide range of sports,
            expert insights, and a user-friendly platform, ESPN Bet is your
            ultimate destination for all things sports betting.
          </p>
        </div>
        <p className="py-4 text-white">
          Best regards,
          <br />
          Marshall
          <br />
          Founder, BookieBrawler
        </p>
      </>
    ),
    author: "BookieBrawler Team",
    publishedDate: new Date("2024-07-04"),
    coverPhoto:
      "https://storage.googleapis.com/assestsbookiebrawler/gifs/loading-screen.gif",
  },
];
