import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Import any additional components or types as necessary

const EVTip: React.FC = ({}) => {
  // Include any handlers or additional logic here

  const navigate = useNavigate();

  const navBackToEv = () => {
    navigate("/positive-ev");
  };

  return (
    <div className="min-h-[45vh]">
      <h3> Positive EV (Hoops Moneyline Machine)</h3>
      <p className="text-sm font-sans leading-relaxed ">
        Welcome to a smarter way of betting! Our platform makes it
        straightforward: copy, bet, and win.
        <ul className="list-disc pl-4">
          <li>Bet Straight!! Best way to get consistent results</li>
          <li>
            Customize Your Bankroll to suit your financial comfort level by
            clicking the help icon under rec bet sizes. Your bet sizes will be
            calculated based off them.
          </li>
          <li>
            Sign up with multiple betting platforms like ESPN Bet, DraftKings,
            FanDuel, BetMGM, Caesar’s, Pointsbet, and SuperBook to increase
            profitabiltiy.
          </li>
          <li>
            Increase your bet frequency to maximize opportunities, aiming for
            around 30 bets per day.
          </li>
          <li>
            Use a desktop for the best user experience, offering easier
            navigation and comprehensive view.
          </li>
          <li>
            Utilize the odds calculator when odds change for the new optimal bet
            size.
          </li>
          <span className="block mt-2 text-powder-bloo mb-2">
            <strong>RECOMMENDED FILTER:</strong> Odds Min: N/A Odds Max: 300
            Percent Min: 1 Percent Max: N/A Bookies: N/A Leagues: N/A
            <div>Note: The recommended filter is applied by default</div>
          </span>
        </ul>
        <button className="ctaButton" onClick={navBackToEv}>
          Back
        </button>
      </p>
    </div>
  );
};

export default EVTip;
