import { getCurrentUser, getIsAuthenticated } from "../../../../../services/apiService";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OnboardModal from "./onboard-modal";

interface NewSubscriberProps {
    authed: boolean; // Adding the boolean parameter here
  }
  
  const NewSubscriber: React.FC<NewSubscriberProps> = ({ authed }) => {
    const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleGoToPositiveEV = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNavigate = () => {
    window.location.href = "/positive-ev";
  };

//   useEffect(() => {
//     console.log(authed,"authed");
//     if (!authed) {
//         navigate('/login');
//     }
//   }, [authed]);


  useEffect(() => {
    const setUserState = async () => {
      const response = await getCurrentUser();
      const currentUser = response.data;
     // const authed = await getIsAuthenticated()
      if (!currentUser) {
        navigate('/login');
      }
    };

    setUserState();
  }, []);


  return (
    <div className="payment-success-container px-5 pt-5 flex flex-col justify-between" style={{ minHeight: '60vh' }}>
      <div>
      <h2 className="text-green-500 my-2">Success!</h2>
      
        <p className="mb-4">
          Congratulations on taking your sports betting strategy to the next
          level.
        </p>
        <p className="mb-4">
          We’re thrilled to have you on board as one of the pioneering members
          of our community.
        </p>
      </div>

      <div className="self-end mt-5">
        <button
          onClick={handleGoToPositiveEV}
          className="text-white font-bold py-2 px-4"
        >
          Get Started
        </button>

        {/* Optional: Keep this if you want to provide a direct navigation option */}
        {/* <button
          onClick={handleNavigate}
          className="text-white bg-green-500 font-bold py-2 px-4 rounded mt-2"
        >
          Explore the Positive EV Tool
        </button> */}
      </div>

      <OnboardModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default NewSubscriber;
