import {Odds} from "../../types/Odds";
import {BestForMarket} from "../../types/BestForMarket";
import Market from "../../types/Market";
import { Bookmaker } from "../../types/Bookmaker";
import { BestOdds } from "../../types/BestOdds";
import { Outcome } from "../../types/Outcome";
import { Leagues } from "../../types/Leagues";
import{leagueList } from "../constants"




const bookMakersToSkip: string[]=["betfair","matchbook"];

export function jsonArrToOddsArr(jsonList: any[]) {
  if (!Array.isArray(jsonList)) {
    console.error('jsonList is not an array:', jsonList);
    return [];
  }

  let jsonArr: Array<Odds> = [];
  jsonList.forEach(function (json){
    jsonArr.push(jsonToOdds(json));
  });
  
  return jsonArr;
}


export function bookmakersToKeyList(bookmakers: Bookmaker[]): string[] {
  const keyList: string[] = [];
  for (const bookmaker of bookmakers) {
    keyList.push(bookmaker.key);
  }
  return keyList;
}

export function jsonToOdds(json: any): Odds {
  return {
    id: json.id,
    sport_key: json.sport_key,
    sport_title: json.sport_title,
    commence_time: json.commence_time,
    home_team: json.home_team,
    away_team: json.away_team,
    start_time: json.start_time,
    bookmakers: json.bookmakers.map((bookmaker: any) => {
      return {
        key: bookmaker.key,
        title: bookmaker.title,
        last_update: bookmaker.last_update,
        juice: 0,
        markets: bookmaker.markets.map((market: any) => {
          return {
            key: market.key,
            juice: calculateJuice(market.outcomes),
            outcomes: market.outcomes.map((outcome: any) => {
              return {
                name: outcome.name,
                price: outcome.price,
                usaFormat: decimalToAmerican(outcome.price),
                point : outcome.point,
              }
            })
          }
        })
      }
    })
  }
}

export function calculateJuice(outcomes: Outcome[]){
   return (calculateSumOfProbabilties(outcomes)- 1)*100;
}



export function calculateSumOfProbabilties(outcomes: Outcome[]){
    let totalProbabilty : number = 0;
    outcomes.forEach(function (outcome) {  
        totalProbabilty += outcomeToImpliedProbability(outcome);
    });;
    return totalProbabilty;   
}

 export function outcomeToImpliedProbability(outcome: Outcome){ 
    return calculateImpliedProbabilityFromAmericanOdds(outcome.price as number);
}

export function formatOdds(
  price: number | undefined,
  isTotal: boolean
): string | undefined {
  if (price === Number.MIN_SAFE_INTEGER) return "--";
  if (price === undefined) return price;
  return price && price > 0 && !isTotal ? "+" + price : price.toString();
}



export function decimalToAmerican(decimalOdds: number): number {
  return decimalOdds < 2 ? -Math.round(100 / (decimalOdds - 1)) : Math.round((decimalOdds - 1) * 100);
}

function calculateImpliedProbabilityFromAmericanOdds(odds: number): number {
  let p : number = 0;
  return odds>0 ? 100 / (odds + 100) :(-odds) / (-odds + 100);
}

function removeLaySuffix(word: string): string {
  return word.endsWith("_lay") ? word.slice(0, -4) : word;
}

function shouldExclude(key:string): boolean {
  return bookMakersToSkip.includes(key);
}

export function getBestByMarketKey(bests: BestForMarket[], key: string): BestForMarket {
  return bests.filter(best => best.market.key=== key)[0];
}

export function getImagePathByMarketKey(key: string): string {
  const bookmakerKeyPathMap = new Map<string, string >(); ;


 // "https://play-lh.googleusercontent.com/BctQd9Bq2q6GJYUL80jwTaGcl05RFeany_m_IU5_wOa2QpEInGkPKPXg6TWIhup-KHE"
  bookmakerKeyPathMap.set("pointsbetus","https://pbs.twimg.com/profile_images/1433898552451686402/cD2kPDuX_400x400.png");
  bookmakerKeyPathMap.set("draftkings","https://brandstruck.co/wp-content/uploads/2021/03/DraftKings-brand-strategy-positioning.jpeg");
  bookmakerKeyPathMap.set("fanduel","https://images.actionnetwork.com/1200x675/blog/2022/08/FanDuel.webp");
  bookmakerKeyPathMap.set("betmgm","https://www.sportsvideo.org/wp-content/uploads/2020/03/BetMGM-Logo-%E2%80%93-HiRes.png");
  bookmakerKeyPathMap.set("betfair","https://www.esports.net/br/wp-content/uploads/sites/3/2022/08/betfair-logo.jpg");
  bookmakerKeyPathMap.set("barstool","https://logos-world.net/wp-content/uploads/2022/01/Barstool-Sports-Symbol.png");
  bookmakerKeyPathMap.set("betonlineag","https://static.casinousa.com/images//casino-rooms/betonline-logo.jpg");
  bookmakerKeyPathMap.set("foxbet","https://www.betphilly.com/wp-content/uploads/sites/18/2022/02/fox-bet.jpg");
  bookmakerKeyPathMap.set("mybookieag","https://cdn.mybookie.ag/wp-content/uploads/mybookie-sportsbook-01.jpg");
  bookmakerKeyPathMap.set("circasports","https://www.circasports.com/wp-content/uploads/2020/06/circa-sports-logo-vertical-white.png");
  bookmakerKeyPathMap.set("lowvig","https://ui.lowvig.ag/images/promotions/mit-240-poster-home-video.jpg");
  bookmakerKeyPathMap.set("unibet_us","  https://i0.wp.com/thelibertyline.com/wp-content/uploads/2022/03/THE-WOLVES-OF-BROAD-STREET-40.png?resize=1200%2C675&ssl=1");

 // bookmakerKeyPathMap.set("williamhill_us","https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.williamhill.us%2Fwilliam-hill-to-purchase-cg-technology-sports-book-assets%2F&psig=AOvVaw0agjzaEtSE_3kwlwGHH-jQ&ust=1675865263019000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCKC7uN_Kg_0CFQAAAAAdAAAAABAE");
  bookmakerKeyPathMap.set("gtbets","http://sportsanalyticssimulator.com/wp-content/uploads/2018/03/gt-bets-logo.png");
  bookmakerKeyPathMap.set("betus","https://pbs.twimg.com/profile_images/1425932574027878402/rbzKOGDN_400x400.jpg");
  bookmakerKeyPathMap.set("sugarhouse","https://img.sportsbookreview.com/images/sportsbooks/logos/sugarhouse-logo.png?fm=webp&auto=compress&auto=format");
  bookmakerKeyPathMap.set("williamhill_us","https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/caesars-logo.webp");
  bookmakerKeyPathMap.set("twinspires","https://www.usbets.com/wp-content/uploads/2022/05/TwinSpires-Logo.jpeg");
  bookmakerKeyPathMap.set("wynnbet","https://www.letsgambleusa.com/wp-content/uploads/2021/08/wynnbet-logo.jpeg");
  bookmakerKeyPathMap.set("betrivers","https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/betrivers.png");
  bookmakerKeyPathMap.set("bovada","https://sport.bovada.lv/wp-content/uploads/2020/08/bovada-og-1024x538.jpg");
  bookmakerKeyPathMap.set("superbook","https://www.usbets.com/wp-content/uploads/2022/06/superbook-sports-logo.jpg");
  bookmakerKeyPathMap.set("pikkit","https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/pikkit-logo.webp");  
  bookmakerKeyPathMap.set("brawler", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/brawler-icon.png");
  bookmakerKeyPathMap.set("espnbet", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/espnbet.png");
  bookmakerKeyPathMap.set("ballybet", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/ballybet.png");
  bookmakerKeyPathMap.set("betparx", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/betparx.png");
  bookmakerKeyPathMap.set("fliff", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/fliff.png");
  bookmakerKeyPathMap.set("hardrockbet", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/hardrockbet.jpeg");
  bookmakerKeyPathMap.set("sisportsbook", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/sisportsbook.jpeg");
  bookmakerKeyPathMap.set("tipico_us", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/tipico.png");
  bookmakerKeyPathMap.set("windcreek", "https://storage.googleapis.com/assestsbookiebrawler/bookmakerImages/windcreek.png");
  



 

  
  return bookmakerKeyPathMap.get(key)||"";
}

export function getIconPathBySportKey(key: string): string {
  const sportKeyToIconPathMap = new Map<string, string >();
  sportKeyToIconPathMap.set("basketball_nba", "/icons/sports/basketball.png");
  return sportKeyToIconPathMap.get(key)||"";
}

export function getImagePathByTeamTitle(key: string): string {
  const bookmakerKeyPathMap = new Map<string, string >();

  bookmakerKeyPathMap.set("Boston Celtics", "");
  bookmakerKeyPathMap.set("Brooklyn Nets", "");
  bookmakerKeyPathMap.set("New York Knicks", "");
  bookmakerKeyPathMap.set("Philadelphia 76ers", "");
  bookmakerKeyPathMap.set("Toronto Raptors", "");
  bookmakerKeyPathMap.set("Chicago Bulls", "");
  bookmakerKeyPathMap.set("Cleveland Cavaliers", "");
  bookmakerKeyPathMap.set("Detroit Pistons", "");
  bookmakerKeyPathMap.set("Indiana Pacers", "");
  bookmakerKeyPathMap.set("Milwaukee Bucks", "");
  bookmakerKeyPathMap.set("Atlanta Hawks", "https://upload.wikimedia.org/wikipedia/en/thumb/2/24/Atlanta_Hawks_logo.svg/1200px-Atlanta_Hawks_logo.svg.png");
  bookmakerKeyPathMap.set("Charlotte Hornets", "");
  bookmakerKeyPathMap.set("Miami Heat", "");
  bookmakerKeyPathMap.set("Orlando Magic", "https://upload.wikimedia.org/wikipedia/en/thumb/1/10/Orlando_Magic_logo.svg/1200px-Orlando_Magic_logo.svg.png");
  bookmakerKeyPathMap.set("Washington Wizards", "");
  bookmakerKeyPathMap.set("Dallas Mavericks", "");
  bookmakerKeyPathMap.set("Houston Rockets", "");
  bookmakerKeyPathMap.set("Memphis Grizzlies", "");
  bookmakerKeyPathMap.set("New Orleans Pelicans", "");
  bookmakerKeyPathMap.set("San Antonio Spurs", "");
  bookmakerKeyPathMap.set("Denver Nuggets", "");
  bookmakerKeyPathMap.set("Minnesota Timberwolves", "");
  bookmakerKeyPathMap.set("Oklahoma City Thunder", "");
  bookmakerKeyPathMap.set("Portland Trail Blazers", "");
  bookmakerKeyPathMap.set("Utah Jazz", "");
  bookmakerKeyPathMap.set("Golden State Warriors", "");
  bookmakerKeyPathMap.set("Los Angeles Clippers", "");
  bookmakerKeyPathMap.set("Los Angeles Lakers", "");
  bookmakerKeyPathMap.set("Phoenix Suns", "");
  bookmakerKeyPathMap.set("Sacramento Kings", "");
return bookmakerKeyPathMap.get(key)||"";
}

export function isGuaranteedProfit(prices: number[]): boolean {

  const numOfOutcomes =  prices.length;
  const minOddLimit = (numOfOutcomes -1)*(100);

  let isGuaranteedProfit = true;
  prices.forEach((price) => {
    if(price<minOddLimit) isGuaranteedProfit = false;
  })
  return isGuaranteedProfit;
}

// export function formatDate(dateString: string): string {
//   // Parse the date string into a Date object
//   const date = new Date(dateString);

//   // Extract the individual date and time components
//   const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" });
//   const month = date.toLocaleDateString("en-US", { month: "short" });
//   const dayOfMonth = date.getDate();

//   const hours = date.getHours();
//   const minutes = date.getMinutes();
//   const ampm = hours >= 12 ? "PM" : "AM";

//   let regularHours = hours;
//   let regularMinutes = minutes < 10 ? "0" + minutes : minutes;

//   if (hours === 0) {
//     regularHours = 12;
//   } else if (hours > 12) {
//     regularHours = hours - 12;
//   }

//   const time = `${regularHours}:${regularMinutes}`;

//   // Assemble the formatted date string
//   return `${dayOfWeek}, ${month} ${dayOfMonth}, ${time} ${ampm}`.toUpperCase();
// }

export function formatDate(dateString: string): string {
  // Append 'Z' to indicate that dateString is in UTC
  const date = new Date(dateString + "Z");

  // Format the date and time components
  const dateOptions: Intl.DateTimeFormatOptions = { weekday: "short", month: "short", day: "numeric" };
  const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  // Assemble the formatted date string
  return `${formattedDate}, ${formattedTime}`.toUpperCase();
}





export function getSportByKey (key: string) : Leagues{
  
  let league = leagueList.find(s => s.key === key) as Leagues;
  return league;
}
 

export function getBestOddsPerMarketPerOutcomeFromEvent(odd: Odds, marketKey : string):BestOdds {
  
  let bestOdds: BestOdds = {
    home: {
      name: '',
      price: Number.MIN_SAFE_INTEGER,
      point: Number.MIN_SAFE_INTEGER,
    },
    away: {
      name: '',
      price: Number.MIN_SAFE_INTEGER,
      point: Number.MIN_SAFE_INTEGER,
    },
    homeBookmaker: "",
    awayBookmaker: "",
    homeTeamName: "",
    awayTeamName: ""
  };

  const bookmakers =odd.bookmakers;
  for (const bookmaker of bookmakers) {
    if(shouldExclude(bookmaker.key)) continue;
    //const marketName =  removeLaySuffix(marketKey);
    const market = getMarketByKey(marketKey, bookmaker.markets)?
      getMarketByKey(marketKey, bookmaker.markets) :  getMarketByKey(marketKey+'_lay', bookmaker.markets) ;
    
    if (!market) continue;

    
    const awayOdds: Outcome = market.outcomes[0];
    const homeOdds: Outcome = market.outcomes[1];

    if(awayOdds.price > bestOdds.away.price){
      bestOdds.away= awayOdds;
      bestOdds.awayBookmaker = bookmaker.key;
    }

    if(homeOdds.price > bestOdds.home.price){
      bestOdds.home= homeOdds;
      bestOdds.homeBookmaker = bookmaker.key;
    }

    
    
    
  
  }

  return bestOdds;
}



// function getBetterOutcome (current: Outcome, best: Outcome) : Outcome{
//   if(current.price > best.price)
  
//   return current.price > best.price? current: best;
// }

export function getMarketByKey(key: string, markets: Market[]): Market| null {
  // Iterate over the markets array
  for (const market of markets) {
    // Check if the key of the current market matches the given key
    if (market.key === key) {
      // If it does, return the market
      return market;
    }
  }
  return null;
}


export function getBestJuicePerMarketFromEvent(json: Odds):BestForMarket [] {
  
  const lowestJuices = new Map<string, BestForMarket >(); ;
  
  let bestForMarkets: Array<BestForMarket> = [];

  const bestBetForLines = {
    market: { juice :Number.MAX_SAFE_INTEGER,
  }} as BestForMarket;
  const bestBetForTotals ={
    market: { juice :Number.MAX_SAFE_INTEGER,
  }} as BestForMarket;
  const bestBetForSpreads = {
    market: { juice :Number.MAX_SAFE_INTEGER,
  }} as BestForMarket;
  
  lowestJuices.set("totals", bestBetForTotals);
  lowestJuices.set("spreads", bestBetForSpreads);
  lowestJuices.set("h2h", bestBetForLines);

  for (const bookmaker of json.bookmakers) {
    if(shouldExclude(bookmaker.key)) continue;
    for (const market of bookmaker.markets) {  
      const marketName =  removeLaySuffix(market.key);
      let bestForMarket = lowestJuices.get(marketName)
      if(!bestForMarket) continue;
      if (lowestJuices.has(marketName) && market.juice < bestForMarket.market.juice) {
        bestForMarket.market = market;
        bestForMarket.bookmaker = bookmaker;
        lowestJuices.set(marketName, bestForMarket);
      }
    }
  }
 
  bestForMarkets =  Array.from(lowestJuices.values());
  return bestForMarkets;
}


