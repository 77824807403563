import React, { useState, useEffect } from 'react';

const BettingCalculator: React.FC = () => {
  const [odds1, setOdds1] = useState<number | string>(110);
  const [odds2, setOdds2] = useState<number | string>(110);
  const [stake1, setStake1] = useState<number | string>(100);
  const [stake2, setStake2] = useState<number | string>(100);
  const [lastUpdated, setLastUpdated] = useState<string>('stake1');
  const [payout1, setPayout1] = useState<number>(0);
  const [payout2, setPayout2] = useState<number>(0);
  const [totalStake, setTotalStake] = useState<number>(0);
  const [totalPayout, setTotalPayout] = useState<number>(0);
  const [profit, setProfit] = useState<number>(0);
  const [profitPct, setProfitPct] = useState<number>(0);

  const calculatePayout = (odds: number, stake: number) => {
    return odds > 0 ? stake * (odds / 100) + stake : stake * (100 / Math.abs(odds)) + stake;
  };

  const calculateStakeForEqualPayout = (payout: number, odds: number) => {
    return odds > 0 ? payout / ((odds / 100) + 1) : payout / ((100 / Math.abs(odds)) + 1);
  };

  useEffect(() => {
    const o1 = Number(odds1);
    const o2 = Number(odds2);
    const s1 = Number(stake1);
    const s2 = Number(stake2);

    let p1, p2, newS2, newS1;

    if (lastUpdated === 'stake1') {
      p1 = calculatePayout(o1, s1);
      newS2 = calculateStakeForEqualPayout(p1, o2);
      p2 = calculatePayout(o2, newS2);
      setStake2(newS2.toFixed(2));
    } else {
      p2 = calculatePayout(o2, s2);
      newS1 = calculateStakeForEqualPayout(p2, o1);
      p1 = calculatePayout(o1, newS1);
      setStake1(newS1.toFixed(2));
    }

    const totalS = Number(stake1) + Number(stake2);
    const totalP = Math.min(p1, p2);
    const prof = totalP - totalS;
    const profPct = (prof / totalS) * 100;

    setPayout1(p1);
    setPayout2(p2);
    setTotalStake(totalS);
    setTotalPayout(totalP);
    setProfit(prof);
    setProfitPct(profPct);
  }, [odds1, odds2, stake1, stake2, lastUpdated]);

  return (
    <div className="container max-w-[400px] w-auto p-4 bg-gray-800 rounded-lg text-white border-[0.1px] border-blue-200">
      <div className="grid grid-cols-3 gap-4 items-center">
        <div className="col-span-1">
          <span className='block mb-2 h-12 flex items-center font-bold text-sm'>Odds</span>
          <input
            type="number"
            id="odds1"
            value={odds1}
            onChange={(e) => setOdds1(e.target.value)}
            className="w-full p-2 bg-gray-900 rounded h-12"
          />
          <input
            type="number"
            id="odds2"
            value={odds2}
            onChange={(e) => setOdds2(e.target.value)}
            className="w-full p-2 bg-gray-900 rounded mt-2 h-12"
          />
        </div>
        <div className="col-span-1">
          <span className='block mb-2 h-12 flex items-center font-bold text-sm'>Stake</span>
          <input
            type="number"
            id="stake1"
            value={stake1}
            onChange={(e) => {
              setStake1(e.target.value);
              setLastUpdated('stake1');
            }}
            className="w-full p-2 bg-gray-900 rounded h-12"
          />
          <input
            type="number"
            id="stake2"
            value={stake2}
            onChange={(e) => {
              setStake2(e.target.value);
              setLastUpdated('stake2');
            }}
            className="w-full p-2 bg-gray-900 rounded mt-2 h-12"
          />
        </div>
        <div className="col-span-1">
          <span className='block mb-2 h-12 flex items-center font-bold text-sm'>Payout</span>
          <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">${payout1.toFixed(2)}</div>
          <div className="p-2 mt-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">${payout2.toFixed(2)}</div>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-center">
          <span className="font-bold text-sm">Total Stake</span>
          <span className="font-medium text-sm">${totalStake.toFixed(2)}</span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span className="font-bold text-sm">Total Payout</span>
          <span className="font-medium text-sm">${totalPayout.toFixed(2)}</span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span className="font-bold text-sm">Profit ({profitPct.toFixed(2)}%)</span>
          <span className="font-medium text-sm">${profit.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

export default BettingCalculator;
