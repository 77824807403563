// FilterComponent.jsx
import React, { useEffect, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../../../../components/ui/popover";
import { TableFilterForm } from "../../common/filters/table-filter-form";
import { FilterDetails } from "../../common/filters/filter-details";
import { TableFilter } from "./table-filter.types";
import { currentFilterSignal } from "./table-filter-views";
import { deleteRequest, postRequest } from "../../../../services/apiService";
import ConfirmationModal from "../confirmation-modal";

interface FilterComponentProps {
  filter: TableFilter;
  onFilterUpdate: (updatedFilter: TableFilter, updateType: string) => void;
  onSelect: (updatedFilter: TableFilter) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  filter,
  onFilterUpdate,
  onSelect,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const componentClass = filter.isActive ? "evBorderGreen bg-evGreenBg" : "";

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const applyFilter = async () => {
    const url = `api/v1/table-filters/apply/${filter.id}`;
    try {
      const response = await postRequest(url, filter);
      if (response && response.id) {
        onFilterUpdate(response as TableFilter, "apply");
        //getEVRowData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleFilter = async () => {
    const url = `api/v1/table-filters/toggle/${filter.id}`;
    try {
      const response = await postRequest(url, filter);
      if (response && response.id) {
        onFilterUpdate(response as TableFilter, "toggle");
        //getEVRowData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFilter = async () => {
    try {
      const response = await deleteRequest(`api/v1/table-filters/${filter.id}`);

      onFilterUpdate(filter, "delete");
    } catch (error) {
      console.error("Error deleting filter", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const getPopoverContent = () => {
    if (!isDesktop) {
      return <div>Please use on desktop to edit filters.</div>;
    } else {
      return <><FilterDetails filter={currentFilterSignal.value} />
      <TableFilterForm
        filter={filter}
        currentFilter={currentFilterSignal.value}
        onFilterUpdate={onFilterUpdate}
      /></>;
    }
  };

  const handleDeleteClick = () => {
    setModalOpen(true);
    currentFilterSignal.value = filter;
  };

  const handleConfirmDelete = async () => {
    setModalOpen(false);
    await deleteFilter();
  };

  return (
    <span
      className={componentClass + " rounded-tl rounded-tr mb-2 mx-1 w-[100px]"}
      style={{
        border: "1.25px solid",
        borderColor: filter.isActive ? "#79c631" : "gray",
        borderRadius: "4px",
      }}
    >
      <Popover key={filter.id}>
        <PopoverTrigger asChild>
          <div className="flex justify-center">
            {" "}
            {/* This div centers its children horizontally */}
            <span
              className="text-sm text-white font-sans text-center p-1"
              onClick={() => onSelect(filter)}
            >
              {filter.name}
            </span>
          </div>
        </PopoverTrigger>
        <div className="flex justify-between items-center">
          {" "}
          {/* Flex container to align items and space between */}
          <input
            className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onClick={toggleFilter}
            checked={filter.isActive}
          />
          {/* This will be positioned on the far right */}
         { filter.id!==-1 && <img
            src="https://storage.googleapis.com/assestsbookiebrawler/icons/delete-icon.svg"
            className="p-2 delete-icon"
            alt="Delete Suggestion"
            onClick={handleDeleteClick}
            style={{ cursor: "pointer", width: "32px", height: "32px" }}
          />}
        </div>

        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={handleConfirmDelete}
          message={
            "Are you sure you want to delete " +
            currentFilterSignal.value?.name +
            "?"
          }
        />
        <PopoverContent>
          {getPopoverContent()}
        </PopoverContent>
      </Popover>
    </span>
  );
};

export default FilterComponent;
