import React from 'react';

const BonusBetConverterWriteup: React.FC = () => {
  return (
    <div>
      <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">How to Use a Bonus Bet Converter</h2>
      <p className="mb-3">
        A bonus bet calculator or bonus bet conversion calculator determines the rate at which you can turn a bonus bet into cash. As part of many promotions and sign-up bonuses, US and Canadian sportsbooks offer "bonus bets". Bonus bets are different from cash because, if your wager wins, you do not get back your initial stake. Learning how to use a bonus bet calculator will enable you to convert your bonus bets into cash at the highest possible rate.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Understanding Bonus Bets</h3>
      <p className="mb-3">
        Bonus bets are promotions offered by sportsbooks to attract new users. Unlike cash, when you win a bonus bet, you only receive the profit from the bet, not the original stake. This makes it essential to maximize the value you get from these bets.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Calculating the Hedge Bet</h3>
      <p className="mb-3">
        To maximize the value of a bonus bet, you can hedge your bet at a different sportsbook. The bonus bet calculator helps you determine the optimal hedge amount to ensure the highest possible profit regardless of the bet's outcome. The calculator converts the bonus bet line and the hedge line into implied probabilities to find the ideal hedge bet.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Example</h3>
      <p className="mb-3">
        Let's say you have a $100 bonus bet on BetMGM. You see an opportunity on the Over/Under 50.5 in the New England Patriots vs Jacksonville Jaguars game. You place the $100 bonus bet on the Over 50.5 at +300 odds. To hedge, you place a $220 bet on the Under 50.5 at -275 odds at a different sportsbook.
      </p>
      <p className="mb-3">
        The expected results might be:
      </p>
      <ul className="mb-3">
        <li>Bonus bet wins: $300 (bonus bet) - $220 (hedge bet) = $80 profit</li>
        <li>Hedge bet wins: $220 (hedge bet) - $100 (bonus bet) = $80 profit</li>
      </ul>
      <p className="mb-3">
        No matter the outcome, you would net $80 profit. This makes your effective profit percentage 80%.
      </p>
    </div>
  );
};

export default BonusBetConverterWriteup;
