import React from 'react';

const HoldCalculatorWriteUp: React.FC = () => {
  return (
    <div className="p-4 text-white mt-4">
      <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">How to Use the Hold Calculator</h2>
      <p className="mb-3">
        The BookieBrawler hold calculator helps you identify the margin between both sides of a bet by using the top sportsbook odds available. Bettors should always aim to place wagers on markets with a minimal hold. Ideally, you want to find a market with a 0% hold, commonly known as a "pick 'em" market. For instance, a 0% hold market could have +150 moneyline odds on the Broncos at DraftKings and -150 moneyline odds on the Kansas Chiefs at FanDuel.
      </p>
      <p className="mb-3">
        The hold percentage varies depending on factors such as the betting market. Niche markets, like KBO (Korean baseball), often have higher holds compared to popular markets like the NBA. This is one reason why profiting from bets in less popular leagues can be challenging.
      </p>
      <p className="mb-3">
        To utilize the BookieBrawler hold calculator, enter the best odds available for each side of the market. For example, if DraftKings offers the best price on the Boston Celtics moneyline at +120 and FanDuel has the best price on the Golden State Warriors at -125, input these figures into the calculator. It will show you that this market currently has a hold of 1%!
      </p>
    </div>
  );
};

export default HoldCalculatorWriteUp;
