import { useUser } from "./UserContext";
import { defaultUser } from "./defaultUser";

export const useLogout = () => {
  const { user, setUser, setToken, setRefreshToken } = useUser();

  const handleLogoutContext = () => {
   // setIsUserAuthenticated(false);
    setUser(defaultUser);
    setToken(null);
    setRefreshToken(null);
  };

  return handleLogoutContext;
};
