import React, { useState } from 'react';

interface AddSuggestionProps {
  onAdd: (content: string) => Promise<void>;
}

export const AddSuggestion: React.FC<AddSuggestionProps> = ({ onAdd }) => {
  const [newSuggestion, setNewSuggestion] = useState<string>("");
  const [isAddingSuggestion, setIsAddingSuggestion] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const validateSuggestion = (suggestion: string): boolean => {
    if (!suggestion) {
      setError("Suggestion cannot be empty");
      return false;
    }
    const letterMatches = suggestion.match(/[a-zA-Z]/g);
    if (!letterMatches || letterMatches.length < 3) {
      setError("Suggestion must contain at least three letters");
      return false;
    }

    if (suggestion.length < 3 || suggestion.length > 255) {
      setError("Suggestion must be between 3 and 255 characters");
      return false;
    }
    setError(null);
    return true;
  };

  const handleAddSuggestion = async () => {
    if (!validateSuggestion(newSuggestion)) {
      return;
    }

    try {
      await onAdd(newSuggestion);
      setNewSuggestion("");
      setIsAddingSuggestion(false);
    } catch (error) {
      console.error("Error when adding new suggestion", error);
    }
  };

  return (
    <div className="mb-1 pb-1">
      {!isAddingSuggestion && (
        <img
          src="https://storage.googleapis.com/assestsbookiebrawler/icons/add-post-icon.svg"
          alt="Add Suggestion"
          className="d-block mx-auto mt-2 hover-effect"
          onClick={() => setIsAddingSuggestion(true)}
        />
      )}

      {isAddingSuggestion && (
        <>
          <textarea
            className="text-light-bloo"
            value={newSuggestion}
            onChange={(e) => setNewSuggestion(e.target.value)}
            style={{
              width: "100%",
              backgroundColor: "transparent",
              color: "#ADD8E6",
            }}
          />
          <div className="d-flex mt-2">
            <button
              onClick={() => setIsAddingSuggestion(false)}
              className="m-1 ctaButton"
            >
              Close
            </button>
            <button onClick={handleAddSuggestion} className="m-1 ctaButton">
              Post
            </button>
          </div>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </>
      )}
    </div>
  );
};
