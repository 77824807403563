// AccessControl.tsx
import React from 'react';
import { withAccessCheck } from './withAccessCheck';

type AccessControlProps = {
    component: React.ComponentType<any>;
    name: string;
    [key: string]: any; // this line will accept any additional props
  };
  
  const AccessControl: React.FC<AccessControlProps> = ({ component: Component, name, ...props }) => {
    const AccessCheckedComponent = withAccessCheck(Component, name);
    
    return <AccessCheckedComponent {...props} />;
  }
  
  export default AccessControl;
  