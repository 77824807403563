// iconConstants.ts

export interface Icon {
    imageUrl: string;
    redirectUrl: string;
}

export const SOCIAL_ICONS: Icon[] = [
    {
        imageUrl: "https://storage.googleapis.com/assestsbookiebrawler/icons/social-media-icons/tiktok.png",
        redirectUrl: "https://www.tiktok.com/@bookiebrawler"
    },
    {
        imageUrl: "https://storage.googleapis.com/assestsbookiebrawler/icons/social-media-icons/x-logo-high-quality.png",
        redirectUrl: "https://twitter.com/bookiebrawlr"
    },
    {
        imageUrl: "https://storage.googleapis.com/assestsbookiebrawler/icons/social-media-icons/instagram.png",
        redirectUrl: "https://www.instagram.com/bookiebrawler/"
    },
    {
        imageUrl: "https://storage.googleapis.com/assestsbookiebrawler/icons/social-media-icons/facebook.png",
        redirectUrl: "https://www.facebook.com/profile.php?id=100094743287729&mibextid=LQQJ4d"
    },
    {
        imageUrl: "https://storage.googleapis.com/assestsbookiebrawler/icons/social-media-icons/youtube-logo-high-quality.png",
        redirectUrl: "https://www.youtube.com/@BookieBrawler"
    }
];

