import React from 'react';
import { SOCIAL_ICONS } from './social-icon-constants';
import SocialIcon from './SocialIcon';

interface SocialIconListProps {
  color?: string; 
}

const SocialIconList: React.FC<SocialIconListProps> = ({ color }) => {
  return (
    <div className="social-icon-list">
      {SOCIAL_ICONS.map((icon, index) => (
        <SocialIcon key={index} icon={icon} color={color} />
      ))}
    </div>
  );
}

export default SocialIconList;
