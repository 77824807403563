import React from "react";
import EVTip from "../positive-ev-tips";
// Import any additional components or types as necessary

export interface EVTipModalProps {
  show: boolean;
  toggleModal: () => void;
  // Include any other props you might need
}

const EVTipModal: React.FC<EVTipModalProps> = ({
  show,
  toggleModal,
  // Include any other props you might need
}) => {
  if (!show) {
    return null;
  }

  // Include any handlers or additional logic here

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 bg-gray-700">
      <div className="p-3 min-w-[20vw]  max-h-full rounded-lg shadow-xl dark-bg max-w-[90vw]">
        <div className="flex justify-between items-center mb-4">
          <h3> Positive EV</h3>
          <button
            onClick={toggleModal}
            className="text-gray-500 hover:text-gray-700 rounded p-2"
          >
            <span className="text-white">✕</span>
          </button>
        </div>
        <p className="text-med font-sans leading-relaxed">
          Welcome to a smarter way of betting! Our platform makes it straightforward:
          copy, bet, and win.
          {/* <span className="block mt-2 text-powder-bloo mb-2">
            
          </span> */}
          <ul className="list-disc pl-4 text-sm">
            <li>Bet Straight!! Best way to get consistent results</li>
            <li>
              Customize Your Bankroll to suit your financial comfort level by
              clicking the help icon under rec bet sizes. Your bet sizes will be
              calculated based off them.
            </li>
            <li>
              Sign up with multiple betting platforms like ESPN Bet, DraftKings,
              FanDuel, BetMGM, Caesar’s, Pointsbet, and SuperBook to increase
              profitabiltiy.
            </li>
            <li>
              Increase your bet frequency to maximize opportunities, aiming for
              around 30 bets per day.
            </li>
            <li>
              Use a desktop for the best user experience, offering easier
              navigation and comprehensive view.
            </li>
            <li>
              Utilize the odds calculator when odds change for the new optimal
              bet size.
            </li>
            <span className="block mt-2 text-powder-bloo mb-2">
              <strong>RECOMMENDED FILTER:</strong> Odds Min: N/A Odds Max: 300
              Percent Min: 1 Percent Max: N/A Bookies: N/A Leagues: N/A
              <div>Note: The recommended filter is applied by default</div>
            </span>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default EVTipModal;
