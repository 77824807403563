import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import { ContentPost } from "../content-post-model";
import { educationPosts } from "./education-data";


// Example blog posts data


const BettingEducation: React.FC = () => {
  return (
    <HelmetProvider>
      <div className="m-4 px-3 ">
        <Helmet>
          <title>Sports Betting 101 | How to Bet on Sports</title>
          <meta name="description" content="Learn how to bet on sports and get educated with our betting guides." />
        </Helmet>
        <h1 className="tracking-wide font-extrabold text-left lg:text-4xl md:text-2xl text-xl font-code-next text-brand-gray-9 pb-4">
          Sports Betting 101: How to Bet on Sports & Betting Education
        </h1>
        <div className="flex flex-wrap -mx-3">
          {educationPosts.map((post: ContentPost) => (
            <div className="w-full md:w-1/3 px-3 mb-6" key={post.id}>
              <div className="slate-dark-transparent light-brder rounded-lg overflow-hidden shadow-lg h-full">
                <Link to={`/betting-education/${post.slug}`} className="no-underline">
                  <img
                    src={post.coverPhoto}
                    alt={post.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h2 className="text-xl font-bold text-powder-bloo">{post.title}</h2>
                    <p className="text-white mt-2">{post.summary}</p>
                    <small className="text-gray-500 ">{`Published on: ${post.publishedDate.toLocaleDateString()}`}</small>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default BettingEducation;
