// withAccessCheck.tsx
import React from 'react';
import { useUser } from './UserContext';
import { hasAccess } from './hasAccess';
import { bgImage } from '../suggestion-wall/suggestion-service';

type Props = {
  componentName: string;
}

export const withAccessCheck = <P extends object>(Component: React.ComponentType<P>, componentName: string): React.FC<P & Props> => {
  return (props: P) => {
    const { user } = useUser();

    if (!hasAccess(user, componentName)) {
      return <div><img src={bgImage}></img></div>
    }

    // Render the component as normal
    return <Component {...props} />;
  };
};
