import React, { useState } from "react";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import styles from "../../login/login.module.scss";
import "./video-player.scss";

const VideoPlayer: React.FC = () => {
  const videoIds = ["KIh69IspZyc"]; // Replace with your actual YouTube video IDs
  const [currentVideoId, setCurrentVideoId] = useState(videoIds[0]);

  const opts = {
    // height: "390",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="dark-bg p-2 m-5 light-top-brder">
      <h2 className={styles.shine + " p-4 m-0 text-center"}>Learn More </h2>
      <div className="d-flex justify-content-center align-items-center">
        <div className="video-player col-10 py-4 mb-5 pb-5">
          <YouTube videoId={currentVideoId} opts={opts} />
        </div>
      </div>

      {/* <Link
        to={"/betting-education"}
        className="no-underline d-flex justify-content-center mt-0"
      >
        <button className="ctaButton">Learn More</button>
      </Link> */}
      <a
        href={"/betting-education"}
        className="no-underline d-flex justify-content-center mt-0"
      >
        <button className="ctaButton mt-0">Learn More</button>
      </a>
    </div>
  );
};

export default VideoPlayer;
