import React from 'react';
import { Image } from 'react-bootstrap';

interface ProfileImageProps {
    imageUrl: string;    
    size: string | number;
}

const ProfileImage: React.FC<ProfileImageProps> = ({ imageUrl, size }) => {

    return (
        <Image
            src={imageUrl? imageUrl: "https://storage.googleapis.com/assestsbookiebrawler/icons/profile-default.png"}
            referrerPolicy="no-referrer"
            roundedCircle
            className="profile-icon "
            style={{
                width: size,
                height: size,
                objectFit: 'cover',
                border: `${2}px solid #a6cee5`,
                boxShadow: '0px 6px 10px rgba(0,0,0,0.15)',
                transition: 'box-shadow 0.3s ease-in-out'
            }}
        />
    );
};

export default ProfileImage;
