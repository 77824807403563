import React from "react";
import "./Footer.scss";
import styles from "../../login/login.module.scss";
import SocialIconList from "../../commons/social-icons/SocialIconList";


const DisclaimerComponent: React.FC = () => {
  return (
    <footer className="footer-container p-2 m-4 light-top-brder">
      <div className="left-content">
        <div className={styles.shine + " copy-header"}>
          © Copyright BookieBrawler, Inc. 2024
          <div className="icons-social m-3">
            <SocialIconList/>
          </div>
        </div>

        <p className="py-3 pb-5">
          This site is 100% for entertainment purposes only and does not involve
          real money betting. If you or someone you know has a gambling problem
          and wants help, call 1-800 GAMBLER. This service is intended for adult
          users only.
        </p>
      </div>
    </footer>
  );
};

export default DisclaimerComponent;
