import React from 'react';

const ParlayCalculatorWriteup: React.FC = () => {
  return (
    <div>
      <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">How to Use a Parlay Calculator</h2>
      <p className="mb-3">
        A parlay calculator helps you determine the potential payout for a parlay bet. By inputting the odds for each bet and the total wager, the calculator computes the total odds, potential profit, and total payout. This tool is essential for evaluating the potential return of combining multiple bets into a single wager.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Understanding Parlays</h3>
      <p className="mb-3">
        A parlay bet combines multiple individual bets into one single bet. The key feature of a parlay is that all bets, or legs, must win for the parlay to pay out. This means the risk is higher, but so is the potential payout, as the odds are multiplied together.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Calculating Parlay Odds</h3>
      <p className="mb-3">
        The odds for each individual bet are converted to implied probabilities, which are then multiplied together to get the overall implied probability for the parlay. This probability is then converted back to American odds. The parlay calculator automates this process for you.
      </p>

      <h3 className="mb-3 lg:text-lg text-powder-bloo font-code-next">Example</h3>
      <p className="mb-3">
        Let's say you place a $100 parlay bet with the following odds:
      </p>
      <ul className="mb-3">
        <li>Bet #1: +100</li>
        <li>Bet #2: +200</li>
        <li>Bet #3: +100</li>
        <li>Bet #4: +110</li>
      </ul>
      <p className="mb-3">
        The parlay calculator would convert these odds to implied probabilities, multiply them together, and convert back to American odds to give you the overall parlay odds. It would then calculate the total profit and total payout based on your $100 wager.
      </p>
      <p className="mb-3">
        The expected results might be:
      </p>
      <ul className="mb-3">
        <li>Parlay Odds: +2420.00</li>
        <li>Total Profit: $2420.00</li>
        <li>Total Payout: $2520.00</li>
      </ul>
    </div>
  );
};

export default ParlayCalculatorWriteup;
