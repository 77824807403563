import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ImpliedOddsCalculator: React.FC = () => {
  const [fractional, setFractional] = useState<string>('1.10');
  const [decimal, setDecimal] = useState<string>('2.10');
  const [american, setAmerican] = useState<string>('110');
  const [probability, setProbability] = useState<string>('47.62');

  const updateFromFractional = (fractionalValue: string) => {
    const [numerator, denominator] = fractionalValue.split('/').map(Number);
    const decimalValue = (numerator / denominator) + 1;
    const probabilityValue = 1 / decimalValue * 100;
    const americanValue = decimalValue >= 2 ? (decimalValue - 1) * 100 : -100 / (decimalValue - 1);

    setFractional(fractionalValue);
    setDecimal(decimalValue.toFixed(2));
    setProbability(probabilityValue.toFixed(2));
    setAmerican(americanValue.toFixed(0));
  };

  const updateFromDecimal = (decimalValue: string) => {
    const decimal = parseFloat(decimalValue);
    const fractionalValue = (decimal - 1).toFixed(2) + '/1';
    const probabilityValue = 1 / decimal * 100;
    const americanValue = decimal >= 2 ? (decimal - 1) * 100 : -100 / (decimal - 1);

    setDecimal(decimalValue);
    setFractional(fractionalValue);
    setProbability(probabilityValue.toFixed(2));
    setAmerican(americanValue.toFixed(0));
  };

  const updateFromAmerican = (americanValue: string) => {
    const american = parseFloat(americanValue);
    const decimalValue = american > 0 ? (american / 100) + 1 : (100 / -american) + 1;
    const probabilityValue = 1 / decimalValue * 100;
    const fractionalValue = (decimalValue - 1).toFixed(2) + '/1';

    setAmerican(americanValue);
    setDecimal(decimalValue.toFixed(2));
    setProbability(probabilityValue.toFixed(2));
    setFractional(fractionalValue);
  };

  const updateFromProbability = (probabilityValue: string) => {
    const probability = parseFloat(probabilityValue) / 100;
    const decimalValue = 1 / probability;
    const americanValue = decimalValue >= 2 ? (decimalValue - 1) * 100 : -100 / (decimalValue - 1);
    const fractionalValue = (decimalValue - 1).toFixed(2) + '/1';

    setProbability(probabilityValue);
    setDecimal(decimalValue.toFixed(2));
    setAmerican(americanValue.toFixed(0));
    setFractional(fractionalValue);
  };

  return (
    <div className="container mx-auto max-w-[475px] p-4 bg-gray-800 rounded-lg text-white border border-blue-200">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 items-center">
        <div className="flex flex-col">
          <span className='font-bold text-sm whitespace-nowrap mb-3'>Fraction</span>
          <input
            type="text"
            value={fractional}
            onChange={(e) => updateFromFractional(e.target.value)}
            className="w-full p-2 text-white bg-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-powder-bloo-800 focus:bg-gray-900 h-10"
          />
        </div>
        <div className="flex flex-col">
          <span className='font-bold text-sm whitespace-nowrap mb-3'>Decimal</span>
          <input
            type="text"
            value={decimal}
            onChange={(e) => updateFromDecimal(e.target.value)}
            className="w-full p-2 text-white bg-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-powder-bloo-800 focus:bg-gray-900 h-10"
          />
        </div>
        <div className="flex flex-col">
          <span className='font-bold text-sm whitespace-nowrap mb-3'>American</span>
          <input
            type="text"
            value={american}
            onChange={(e) => updateFromAmerican(e.target.value)}
            className="w-full p-2 text-white bg-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-powder-bloo-800 focus:bg-gray-900 h-10"
          />
        </div>
        <div className="flex flex-col">
          <span className='font-bold text-sm whitespace-nowrap mb-3'>Probability %</span>
          <input
            type="text"
            value={probability}
            onChange={(e) => updateFromProbability(e.target.value)}
            className="w-full p-2 text-white bg-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-powder-bloo-800 focus:bg-gray-900 h-10"
          />
        </div>
      </div>
    </div>
  );
};

export default ImpliedOddsCalculator;
