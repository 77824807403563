import React, { useEffect, useState } from 'react';
import { getCurrentUser, getIsAuthenticated } from '../../services/apiService';
import { Button } from 'react-bootstrap';
import ProfileIcon from '../login/ProfileIcon';

interface AuthenticationButtonProps {
  onLogout: () => void;
  toggleModal: () => void;
}

const AuthenticationButton: React.FC<AuthenticationButtonProps> = ({
  onLogout,
  toggleModal,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const usrResp = await getCurrentUser();
      setIsAuthenticated(usrResp?.status===200);
    };

    checkAuthentication();
  }, []);

  return (
    <span className="mt-3">
      {isAuthenticated ? (
        <ProfileIcon onSignOut={onLogout} />
      ) : (
        <>
          {/* <GoogleButtonLogin onLogin={onLogin}></GoogleButtonLogin> */}
        </>
      )}
      {!isAuthenticated && (
        <div className=''>
          <button
            className="border border-white transparent-background py-1 px-3 ml-auto dark-transparent rounded"
            onClick={toggleModal}
          >
            Sign In
          </button>
        </div>
      )}
    </span>
  );
};

export default AuthenticationButton;
