import { getRequest } from "../../services/apiService";


export const checkUniqueUsername = async (handle: string): Promise<boolean> => {
    try {
      const response = await getRequest(`api/v1/users/check-unique-handle/${handle}`);
      return response ?? false;
    } catch (error) {
      console.error("Failed to check username uniqueness", error);
      return false;
    }
  };