import VideoPlayer from "../../../components/landing-page/video-player/VideoPlayer";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../login/login.module.scss";
import YouTube from "react-youtube";
import { postRequest } from "../../../services/apiService";
import { Helmet } from "react-helmet-async";

type PositiveEVUnAuthedProps = {
  authed: boolean;
};

const PositiveEVUnAuthed: React.FC<PositiveEVUnAuthedProps> = ({ authed }) => {
  const navigate = useNavigate(); // Hook for navigation

  const handleSubscribeClick = () => {
    if (authed) {
      initiateCheckout();
    } else {
      navigate("/login");
    }
  };

  const initiateCheckout = async () => {
    // Updated URL to match your server's endpoint
    const checkoutSessionResponse = await postRequest(
      "/api/v1/stripe/create-ev-checkout-session",
      {}
    );

    if (checkoutSessionResponse && checkoutSessionResponse.checkoutSessionUrl) {
      // Redirect the user to the Stripe Checkout page
      window.location.href = checkoutSessionResponse.checkoutSessionUrl;
    } else {
      // Handle the error case
      alert("Unable to initiate checkout. Please try again later.");
    }
  };
  return (
    <>
       <Helmet>
        <title>Positive EV (+EV) Bet Finder | BookieBrawler</title>
        <link rel="canonical" href={'https://bookiebrawler.com/positive-ev'} />
        <meta
          name="description"
          content="Positive EV (+EV) betting software designed to maximize sports betting profits. Comprehensive Positive EV Bets Finder, with bet size calculator based on Kelly Criterion"
        />
      </Helmet>
 
    <div className="container mx-auto">
      <div className="row g-0 lg:m-5 mb-0">
        {" "}
        {/* g-0 removes the gutter between columns */}
        <div
          className="col-md-6 d-flex flex-column justify-content-between"
          style={{ padding: "2rem" }}
        >
          {" "}
          {/* Adjust padding as needed */}
          <div>
            <h5 style={{ fontSize: "1em" }} className="font-normal mb-4">
              <span className={styles.shine + " font-lg-bloo"}>
                Our First Product{" "}
              </span>
              | Positive EV Bet Finder Tool
            </h5>
            <h1 className="tracking-wide font-extrabold text-left lg:text-5xl md:text-2xl text-xl font-code-next text-brand-gray-9 mb-4">
              Get real-time tips, place your bets. Just that easy.
            </h1>
            <h1 className="tracking-wide font-extrabold text-left lg:text-xl md:text-lg text-md font-code-next text-brand-gray-9 mb-5">
              Profitable bets that are weighted in your favor so you always win
              in the long run
            </h1>
          </div>
          <div>
            <button className="ctaButton " onClick={handleSubscribeClick}>
              Make more $$$ sports betting
            </button>
          </div>
        </div>
        <div className="col-md-6 my-auto  " style={{  }}>
          {" "}
          {/* Adjust padding as needed */}
          {/* <div className="aspect-w-16 aspect-h-9 bg-gray-300 flex justify-center items-center w-full">
            <VideoPlayer/>
          </div> */}
          <YouTube
            videoId={"KIh69IspZyc"}
            opts={{
              width: "100%",
              height: "300px",
              playerVars: {
                autoplay: 1,
              },
             
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="text-center p-4">
            <h5
              className={
                "text-sm font-normal uppercase tracking-wide font-small font-med-bloo " +
                styles.shine
              }
            >
              How does it work?
            </h5>
            <h3 className="lg:text-7xl md:text-3xl text-xl mb-2 font-extrabold italic">
              Positive EV Betting Tool
            </h3>
            <p className="text-md mx-auto" style={{ maxWidth: "675px" }}>
              Positive expected value (+EV) betting entails making wagers with a
              higher likelihood of winning than the sportsbook odds suggest. The
              BookieBrawler Positive EV (+EV) Bet Finder tool reveals profitable bets where you hold a
              mathematical advantage over a specific sportsbook.
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PositiveEVUnAuthed;
