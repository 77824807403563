import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface NavbarHandlerProps {
    setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavbarHandler: React.FC<NavbarHandlerProps> = ({ setIsScrolled }) => {
    const location = useLocation();

    useEffect(() => {
        // Set initial banner visibility based on the route
        if (location.pathname === "/") {
            setIsScrolled(window.pageYOffset > 200);
        } else {
            setIsScrolled(true);
        }

        const checkScroll = () => {
            if (location.pathname === "/") {
                if (window.pageYOffset > 200) {
                    setIsScrolled(true);
                } else {
                    setIsScrolled(false);
                }
            }
        };

        window.addEventListener("scroll", checkScroll);
        return () => {
            window.removeEventListener("scroll", checkScroll);
        };
    }, [setIsScrolled, location]);

    return null;
}

export default NavbarHandler;
