import React from 'react';
import { TableFilter } from './table-filter.types';

type FilterDetailsProps = {
  filter: TableFilter | null; // Expect a filter or null as a prop
};

export const FilterDetails: React.FC<FilterDetailsProps> = ({ filter }) => {
  if (!filter) {
    return <div>No filter selected</div>; // Fallback UI when no filter is selected
  }

  return (
    <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-6">
      <h3 className="text-lg md:text-xl font-semibold text-gray-700">Current Filter:</h3>
      <div className="text-sm font-light text-gray-600 grid grid-cols-1 md:grid-cols-2 gap-2">
        <div>Name: {filter.name}</div>
        <div>Odds Min: {filter.oddsMin ?? 'N/A'}</div>
        <div>Odds Max: {filter.oddsMax ?? 'N/A'}</div>
        <div>Percent Min: {filter.percentMin ?? 'N/A'}</div>
        <div>Percent Max: {filter.percentMax ?? 'N/A'}</div>
        <div>Active: {filter.isActive ? 'Yes' : 'No'}</div>
        <div>Bookies: {filter.bookies && filter.bookies.length > 0 ? filter.bookies.join(', ') : 'N/A'}</div>
        <div>Leagues: {filter.leagues && filter.leagues.length > 0 ? filter.leagues.join(', ') : 'N/A'}</div>
      </div>
    </div>
  );
};
