import { Bet } from "../../types/positive-ev.types";

//for bets with no opposing side to ensure ui look remains consistent
  export const placeHolderBet: Bet = {
      name: "N/A",
      bookie: "brawler",
      has_ev: false,
  }

  export const placeHolderBets: Bet[]= [placeHolderBet,placeHolderBet];

  export function getOppositeBets(originalBetName: string): Bet []{
    // Replace 'Over' with 'Under' and 'Yes' with 'No' in the bet name.
    const oppositeName = originalBetName.replace('Over', 'Under').replace('Yes', 'No');
  
    // Return a new Bet object with the modified name and some default values.
    const placeHolderBet: Bet = {
      name: oppositeName,
      bookie: "brawler",  // Assuming 'brawler' as a placeholder bookie.
      has_ev: false       // Defaulting to false for expected value (has_ev).
    };
  
    return [placeHolderBet,placeHolderBet];
  }