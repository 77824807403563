import React, { useState, useEffect } from 'react';

const ExpectedValueCalculator: React.FC = () => {
  const [wager, setWager] = useState<number | string>(100);
  const [odds, setOdds] = useState<number | string>(110);
  const [winProbability, setWinProbability] = useState<number | string>(50);
  const [expectedValue, setExpectedValue] = useState<number>(0);

  const calculatePayout = (odds: number, stake: number) => {
    return odds > 0 ? stake * (odds / 100) + stake : stake * (100 / Math.abs(odds)) + stake;
  };

  const calculateEV = (winProb: number, payout: number, stake: number) => {
    const profitIfWin = payout - stake;
    const lossProb = 1 - winProb;
    return (winProb * profitIfWin) - (lossProb * stake);
  };

  useEffect(() => {
    const o = Number(odds);
    const s = Number(wager);
    const wp = Number(winProbability) / 100;

    const payout = calculatePayout(o, s);
    const ev = calculateEV(wp, payout, s);

    setExpectedValue(ev);
  }, [wager, odds, winProbability]);

  return (
    <div className="container max-w-[350px] w-auto p-4 bg-gray-800 rounded-lg text-white border-[0.1px] border-blue-200">
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <span className='w-1/3 font-bold text-sm'>Wager</span>
          <div className="relative w-2/3">
            <input
              type="number"
              id="wager"
              value={wager}
              onChange={(e) => setWager(e.target.value)}
              className="w-full p-2 bg-gray-900 rounded pr-8"
            />
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2">$</span>
          </div>
        </div>
        <div className="flex items-center">
          <span className='w-1/3 font-bold text-sm'>Odds</span>
          <div className="w-2/3">
            <input
              type="number"
              id="odds"
              value={odds}
              onChange={(e) => setOdds(e.target.value)}
              className="w-full p-2 bg-gray-900 rounded"
            />
          </div>
        </div>
        <div className="flex items-center">
          <span className='w-1/3 font-bold text-sm'>Win Probability</span>
          <div className="relative w-2/3">
            <input
              type="number"
              id="winProbability"
              value={winProbability}
              onChange={(e) => setWinProbability(e.target.value)}
              className="w-full p-2 bg-gray-900 rounded pr-8"
            />
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2">%</span>
          </div>
        </div>
        <div className="flex items-center">
          <span className="w-1/3 font-bold text-sm">Expected Value</span>
          <div className="w-2/3 p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">
            ${expectedValue.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpectedValueCalculator;
