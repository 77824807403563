// BetSizesCell.tsx
import React from 'react';
import { formatBetSize } from '../../services/positive-ev-service';
import styles from "../../../../login/login.module.scss";

type BetSizesCellProps = {
  betSizes: number[];
};

const BetSizesCell: React.FC<BetSizesCellProps> = ({ betSizes }) => {

  if(!betSizes) return null;
  const awayBetSize = betSizes[0];
  const homeBetSize = betSizes[1];

  const betSizesFormatted: string[] = [];
  if (awayBetSize > 0) betSizesFormatted.push(formatBetSize(awayBetSize));
  if (homeBetSize > 0) betSizesFormatted.push(formatBetSize(homeBetSize));

  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontSize: ".70em",
  };

  return (
    <div className="font-small" style={style}>
      {betSizesFormatted.map((betSizeFormatted, index) => (
        <span key={index} className={styles.shine + " py-2 m-0"}>
          {betSizeFormatted}
        </span>
      ))}
    </div>
  );
};

export default BetSizesCell;

// In the main component where you define the column
// Make sure to import BetSizesCell and the formatBetSize function

