
import { getUserRole } from "../../services/apiService";
import { User, Role, stringToRole } from "../types/User";
import { RoleAccess } from "./RoleAccess";


export const hasAccess = (user: User | null, component: string): boolean => {
    if (!user) return false; // if no user is provided, deny access
    
    const roleHierarchy = [Role.USER, Role.BRONZE, Role.SILVER, Role.GOLD, Role.ADMIN];
    const userRoleIndex = roleHierarchy.indexOf(user.role);
    
    return roleHierarchy
      .slice(0, userRoleIndex + 1)
      .some(role => RoleAccess[role].includes(component));
  }
  

  export const hasPositiveEVAccess = (user: User | null): boolean =>{
   return hasAccess(user,"PositiveEV") 
  }



  export const roleHasAccess = (role: Role | null, component: string): boolean => {
    if(!role) return false;
    const roleHierarchy = [Role.USER, Role.BRONZE, Role.SILVER, Role.GOLD, Role.ADMIN];
    const userRoleIndex = roleHierarchy.indexOf(role);
    
    return roleHierarchy
      .slice(0, userRoleIndex + 1)
      .some(role => RoleAccess[role].includes(component));
  }


  export const roleHasPositiveEVAccess = (role: Role | null): boolean =>{
    return roleHasAccess(role,"PositiveEV") 
   }

   export const currentUserHasEVAccess = (): boolean =>{
    const userRoleString = getUserRole() ?? "";
    const userRole = stringToRole(userRoleString);
    const pop = roleHasAccess(userRole,"PositiveEV");
    return pop;

   }
 

