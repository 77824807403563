import React from 'react';

const KellyCriterionWriteUp: React.FC = () => {
  return (
    <div className="p-4 text-white mt-4">
      <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">How to use the Kelly Criterion Calculator</h2>
      <p className="mb-3">
        A Kelly Criterion calculator for sports betting helps you effectively manage your bankroll and decide the best bet sizes. If you have a bankroll of $5,000, it's unwise to risk it all on one bet, no matter how confident you are. Even the most promising bets can fail, and you need to avoid excessive risk. It's important not to jeopardize your entire bankroll on a single wager.
      </p>
      <p className="mb-3">
        So, how much should you bet? Is it better to wager 5% of your bankroll or perhaps 2.5%? The Kelly Criterion calculator answers this by using a mathematical formula to determine the optimal stake when there’s a difference between the given odds and the "fair" odds.
      </p>
      <p className="mb-3">
        Naturally, you should only bet when the available odds are better than the "fair" odds. Placing a bet at +100 odds when the fair odds are +103 is an example of a negative expected value (i.e., an unprofitable bet). The Kelly Criterion would recommend betting $0 in such cases.
      </p>
      <p className="mb-3">
        The essence of finding valuable betting opportunities lies in identifying an actual winning probability that meets or exceeds the implied winning probability as calculated by the sportsbook, based on the fair odds, excluding the vig.
      </p>
    </div>
  );
};

export default KellyCriterionWriteUp;
