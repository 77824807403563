// UserContext.tsx
import React, { createContext, useContext, useState } from "react";
import { User, Role } from "../types/User";

type UserContextType = {
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
    token: string | null;
    setToken: React.Dispatch<React.SetStateAction<string | null>>;
    refreshToken: string | null;
    setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
  };

type UserProviderProps = {
    children: React.ReactNode;
};


const defaultUser: User = {
    id: 0,
    firstname: "",
    handle: "",
    lastname: "",
    email: "",
    password: "",
    picture: "",
    role: Role.USER,
    customer_id: "",
    tokens: [],
    enabled: false,
    username: "",
    blacklist: {bookmaker_keys:[]},
    account_non_expired: false,
    account_non_locked: false,
    credentials_non_expired: false
};
  export const UserContext = createContext<UserContextType>({
    user: defaultUser,
    setUser: () => {},
    token: null,
    setToken: () => {},
    refreshToken: null,
    setRefreshToken: () => {},
  });


 export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User>(defaultUser);
  const [token, setToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  return (
    <UserContext.Provider
      value={{ user, setUser, token, setToken, refreshToken, setRefreshToken }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);