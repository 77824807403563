import { Suggestion } from "./SuggestionWall";

export const bgImage =
  "https://storage.googleapis.com/assestsbookiebrawler/background-images/loginbackgrounds/bloohunnuits_blue_office_aesthetic_photorealistic_1cae03bf-67f8-4157-a002-2af938701ff5.png";

export const getVoteStatus = (suggestion: Suggestion, userId: number): string => {
  const voteDetail = suggestion.voted_user_details?.[userId];
  if (voteDetail === true) {
    return "upvoted";
  }
  if (voteDetail === false) {
    return "downvoted";
  }
  return "none";
};

const getRandomBoolean = (): boolean => {
  return Math.random() < 0.5;
};

const generateRandomVoteDetails = (userId: number): {[key: number]: boolean} => {
  const voteDetails: {[key: number]: boolean} = {};
  
  voteDetails[userId] = getRandomBoolean();

  return voteDetails;
};

export const addVoteDetailsToSuggestions = (suggestions: Suggestion[], userId: number): Suggestion[] => {
  return suggestions.map(suggestion => {
    // If the suggestion already has some vote details, just append/overwrite for the given user
    if (suggestion.voted_user_details) {
      suggestion.voted_user_details[userId] = generateRandomVoteDetails(userId)[userId];
    } else {
      // If not, initialize the voted_user_details field for the suggestion
      suggestion.voted_user_details = generateRandomVoteDetails(userId);
    }
    
    return suggestion;
  });
};
