import React, { useEffect, useState } from "react";
import { BookieImage } from "./bookie-image";

interface BookmakerLite {
  key: string;
  title: string;
}

interface BookieSelectorProps {
  onChange: (selected: string[]) => void;
  initialBookmakers: string[]; // New prop for initially selected bookmakers
  bookmakers: BookmakerLite[];
}

const BookieSelector: React.FC<BookieSelectorProps> = ({
  onChange,
  initialBookmakers,
  bookmakers,
}) => {
  const [whitelist, setWhitelist] = useState<string[]>(initialBookmakers); // Initialize with initialBookmakers

  useEffect(() => {
    setWhitelist(initialBookmakers);
  }, [initialBookmakers]);

  const isWhitelisted = (bookmakerKey: string): boolean => {
    return whitelist.includes(bookmakerKey);
  };

  useEffect(() => {
    console.log("Bookmakers prop received:", bookmakers);
  }, [bookmakers]);

  useEffect(() => {
    console.log("Initial Bookmakers prop received:", initialBookmakers);
  }, [initialBookmakers]);

  const toggleOn = (bookmakerKey: string) => {
    const updatedList = [...whitelist, bookmakerKey];
    console.log("Toggling on:", bookmakerKey, updatedList);
    setWhitelist(updatedList);
    onChange(updatedList); // Notify parent component
  };

  const toggleOff = (bookmakerKey: string) => {
    const updatedList = whitelist.filter((key) => key !== bookmakerKey);
    console.log("Toggling off:", bookmakerKey, updatedList);
    setWhitelist(updatedList);
    onChange(updatedList); // Notify parent component
  };

  const handleToggleChange = (bookmakerKey: string) => {
    console.log("Toggle change for:", bookmakerKey);
    if (isWhitelisted(bookmakerKey)) {
      console.log("Currently whitelisted, toggling off");
      toggleOff(bookmakerKey);
    } else {
      console.log("Currently not whitelisted, toggling on");
      toggleOn(bookmakerKey);
    }
  };

  return (
    <div className="d-flex flex-wrap justify-content-start">
      {bookmakers.map((bookmaker, index) => (
        <div key={index} className="text-center">
          <BookieImage bookie={bookmaker.key} />
          <input
            type="checkbox"
            checked={isWhitelisted(bookmaker.key)}
            onChange={() => handleToggleChange(bookmaker.key)}
          />
        </div>
      ))}
    </div>
  );
};

export default BookieSelector;
