import FAQSComponent from "../../../components/landing-page/faqs/Faqs";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { calculators, ICalculator } from "./betting-calculators-data"; // Assume this is the path to the interface file
import PillGroup from "./calculator-pill-group";

const CalculatorContent: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  console.log(calculators, slug, "calcslug");
  const calculator = calculators.find((calc) => calc.slug === slug);

  if (!calculator) {
    return <div>Content not found</div>;
  }

  return (
    <HelmetProvider>
      <div className="my-4  ">
        <Helmet>
          <title>{calculator.title} | BookieBrawler</title>
          <meta
            name="description"
            content="Learn how to bet on sports and get educated with our betting guides."
          />
        </Helmet>
        <h1 className="tracking-wide font-bold text-left custom-text-xl font-code-next pb-4 px-3">
          {calculator.title}
        </h1>
        <div className="flex flex-wrap mx-3 w-auto">
            <div className="mb-5">
          <PillGroup activeSlug={slug}></PillGroup>
          </div>
          <div className="w-full">
          {calculator.calculatorNode}
          <div className="container p-4 rounded-lg slate-dark-transparent my-5">
            <h3 className="lg:text-2xl font-code-next mb-3">{calculator.title}</h3>
            {calculator.content}
          </div>
          <div className="w-full">  <FAQSComponent faqs={calculator.faqs??[]}/></div>
        
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default CalculatorContent;
