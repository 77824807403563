import { Bet, BetCalculatorValues, PositiveEVRow } from "../types/positive-ev.types";

export function sortRowsByMaxEV(rows: PositiveEVRow[]) {
  return rows.sort((a, b) => {
    // Find the higher EV percentage for each row
    const maxEvA = Math.max(a.home_evpercent, a.away_evpercent);
    const maxEvB = Math.max(b.home_evpercent, b.away_evpercent);

    // Sort by the higher EV percentage in descending order
    return maxEvB - maxEvA;
  });
}

export function formatOdd(odd: number): string {
  if (odd === 0) {
    return "--";
  }
  return odd > 0 ? `+${odd}` : `${odd}`;
}

export function formatOdds(odds: number[] | null): string[] {
  if (odds === null) {
    return [];
  }

  return odds.map((odd) => formatOdd(odd));
}

export function filterBetsWithDifferentPrice(
  bets: Bet[],
  firstBetPrice: number
): Bet[] {
  if (bets.length === 0) {
    return [];
  }

  return bets.filter((bet) => bet.price === firstBetPrice || !bet.price);
}

export function americanOddsToImpliedProbability(americanOdds: number | null): number | null {
  if (americanOdds === null || americanOdds === undefined) {
    return null;
  }

  if (americanOdds > 0) {
    // Positive odds
    return 100 / (americanOdds + 100);
  } else {
    // Negative odds
    return parseFloat((Math.abs(americanOdds) / (Math.abs(americanOdds) + 100)).toFixed(2));
  }
}

export function getBetCalcValues(row: PositiveEVRow): BetCalculatorValues[] {
  const marketCellValues: BetCalculatorValues[] = [];

  if (row.away_evpercent > 0 && row.away_bets && row.away_bets.length > 0) {
    marketCellValues.push({
      winPct: americanOddsToImpliedProbability(row.fair_odds_away) ?? 0,
      odds: row.away_bets[0].price??0,
      marketBet: row.market + ": " + row.away_bets[0].name,
      event: row.event,
    });
  }

  if (row.home_evpercent > 0 && row.home_bets && row.home_bets.length > 0) {
    marketCellValues.push({
      winPct: americanOddsToImpliedProbability(row.fair_odds_home) ?? 0,
      odds: row.home_bets[0].price??0,
      marketBet: row.market + ": " + row.home_bets[0].name,
      event: row.event,
    });
  }

  return marketCellValues;
}




export function convertUTCtoUserTimezone(
  utcDateString: string,
  timeZone: string = "America/New_York"
): string {
  const date = new Date(utcDateString);

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
}

export function isPreMatch(eventStartTime: string | null): boolean {
  // Check if the input is null or empty
  if (!eventStartTime) {
      return false; // Return false for null or invalid input
  }

  try {
      // Parse the event start time into a Date object
      const eventStart = new Date(eventStartTime);

      // Check if the date is valid
      if (isNaN(eventStart.getTime())) {
          return false; // Return false for invalid date
      }

      // Get the current time
      const currentTime = new Date();

      // Return true if the current time is before the event start time
      return currentTime < eventStart;
  } catch (error) {
      // Handle any unexpected errors
      console.error("Error parsing date:", error);
      return false;
  }
}

export const formatBetSize = (amount: number) => {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);

  // Use Bootstrap's py-2 class for vertical padding
  return formatted;
};
