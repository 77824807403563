import React, { useEffect, useState } from "react";
import Overview from "./Overview";
import CustomerPortal from "./CustomerPortal";
import ManageFilters from "./ManageFilters";
import { getCurrentUser, getRequest } from "../../services/apiService";
import { User } from "../types/User";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const AccountSettings = () => {
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedTab") || "overview"
  );
  const [currentUser, setCurrentUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const updateCurrentUser = async () => {
      const user = (await getCurrentUser()) as User;
      if (user) {
        setCurrentUser(user); // Save the user data to state
        setLoading(false); // Update the loading state
      } else {
        navigate("/login");
      }
    };

    updateCurrentUser();
  }, [selectedTab]);

  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  if (loading) {
    return <div>Loading...</div>; // Return a loading spinner or some placeholder content
  }

  const renderContent = () => {
    switch (selectedTab) {
      case "overview":
        return <Overview initialUser={currentUser} />;
      case "customer-portal":
        return <CustomerPortal />;
      case "manage-filters":
        return <ManageFilters />;
      default:
        return <Overview initialUser={currentUser} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
        <meta
          name="description"
          content="Edit your account information and settings"
        />
      </Helmet>
      <div className="guide-font">
        <div className="profile-component-header">
          <span className="profile-component-header-span">
            Account Settings
          </span>
        </div>
        <div style={{ display: "flex" }}>
          {/* <div className = "mt-4"style={{ marginRight: '20px' }}>
          <div 
            className={`account-settings-tabs ${selectedTab === 'overview' ? 'selected-tab' : ''}`} 
            onClick={() => setSelectedTab('overview')}
          >
            Overview
          </div>
          {/* <div 
            className={`account-settings-tabs ${selectedTab === 'customer-portal' ? 'selected-tab' : ''}`} 
            onClick={() => setSelectedTab('customer-portal')}
          >
            Customer Portal
          </div> */}
          {/* <div 
            className={`account-settings-tabs ${selectedTab === 'manage-filters' ? 'selected-tab' : ''}`} 
            onClick={() => setSelectedTab('manage-filters')}
          >
            Manage Filters
          </div> 
        </div> */}
          <div style={{ flex: 1 }}>{renderContent()}</div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
