// EditPhoto.tsx
import React from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";
import ProfileImage from "./ProfileImage";

interface EditPhotoProps {
  show: boolean;
  handleClose: () => void;
  fileInput: React.RefObject<HTMLInputElement>;
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageUpload: () => Promise<void>;
  handleImageSave: () => Promise<void>;
  setDefaultPhoto: () => void;
  handleRemoveImage: () => Promise<void>; // New function to remove image
  uploading: boolean;
  selectedImage: File | null;
  imageToDisplay: string;
  originalImgUrl: string;
  showSave: boolean;
}

const EditPhoto: React.FC<EditPhotoProps> = ({
  show,
  handleClose,
  handleImageChange,
  handleImageUpload,
  handleImageSave,
  setDefaultPhoto,
  handleRemoveImage, // New function to remove image
  uploading,
  selectedImage,
  imageToDisplay,
  fileInput,
  originalImgUrl,
  showSave
}) => {
  const triggerFileSelectPopup = () => fileInput.current?.click();

  const DEFAULT_IMAGE_URL =
    "https://storage.googleapis.com/assestsbookiebrawler/profile-images/default-pfp.png";

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile Picture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={8} >
            <ProfileImage
              imageUrl={imageToDisplay}
              size="175px"
            />
          </Col>
          <Col md={4}>
            <div className="d-flex flex-column mt-4">
              <Button className="mb-2" onClick={triggerFileSelectPopup}>
                Choose Image
              </Button>
              <>
                {(selectedImage || showSave) && (
                  <Button
                    className="mb-1"
                    onClick={handleImageSave}
                    disabled={uploading}
            
                  >
                    {uploading ? "Saving..." : "Save"}
                  </Button>
                )}
              </>
              {imageToDisplay && imageToDisplay !== DEFAULT_IMAGE_URL && (
                <Button onClick={setDefaultPhoto}>Remove Image</Button>
              )}
            </div>
          </Col>
        </Row>
        <input
          type="file"
          ref={fileInput}
          accept="image/*"
          onChange={handleImageChange}
          capture={"user"}
          style={{ display: "none" }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditPhoto;
