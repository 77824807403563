// ImageUpload.tsx
import React, { useState, useRef } from "react";
import { patchRequest, postRequest } from "../../services/apiService"; // Replace with actual path
import ProfileImage from "./ProfileImage";
import EditPhoto from "./EditPhoto";

interface ImageUploadProps {
  imageUrl: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ imageUrl }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [imageUrlState, setImageUrlState] = useState(imageUrl);
  const [show, setShow] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [showSave, setShowSave]= useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const DEFAULT_IMAGE_URL =
  "https://storage.googleapis.com/assestsbookiebrawler/profile-images/default-pfp.png";

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
    console.log(previewImage,imageUrlState);
  };

  const handleImageUpload = async () => {
    if (!selectedImage) return; //add alert?
  
    const formData = new FormData();
    // Generate a unique identifier and prepend it to the file name
    const uniqueFileName = `${Date.now()}-${selectedImage.name}`;

    // Append the file with the unique file name
    formData.append("file", selectedImage, "testuser" +selectedImage.name);// change to uniqueFileName
  
    try {
      setUploading(true);
      const response = await postRequest("/api/v1/image/user/upload-picture", formData, true);
      setImageUrlState(response);
      handleClose();
    } catch (error) {
      console.error("Failed to upload image", error);
    } finally {
      setSelectedImage(null);
      setPreviewImage(null);
      setUploading(false);
      if (fileInput.current) {
        fileInput.current.value = "";  // Reset the file input
      }
    }
  };



  const setDefaultPhoto = () =>{
    if (fileInput.current) {
        fileInput.current.value = "";  // Reset the file input
      }
    setPreviewImage(DEFAULT_IMAGE_URL);
    setShowSave(true);
  }


  const handleImageSave = async () => {
    if (previewImage === DEFAULT_IMAGE_URL) {
      await handleImageRemoval(); // assuming this returns a Promise
    } else {
      await handleImageUpload(); // assuming this returns a Promise
    }
    handleClose();
  };
  
  
  const handleImageRemoval = async () => {

    if(!imageUrlState){
        console.log("there is no image to remove dummy!");
        return
    }
    try {
      setUploading(true);
  
      const response = await patchRequest("/api/v1/image/user/remove-picture", {});
      console.log(response);
      setImageUrlState(response);
  
    } catch (error) {
      console.error("Failed to remove image", error);
    } finally {
      setPreviewImage(null);
      setUploading(false);
      setSelectedImage(null);
      if (fileInput.current) {
        fileInput.current.value = "";  // Reset the file input
      }
    }
  };
  
  

  const triggerFileSelectPopup = () => fileInput.current?.click();


  return (
    <div className="d-flex flex-column align-items-start">
        <div>
        <ProfileImage imageUrl={imageUrlState} size='175px' />
            {/* <div className="text-center">
                <p className="text-primary mt-2" onClick={handleShow} style={{ cursor: "pointer", textDecoration: "underline" }}>Edit Profile Picture</p>
            </div> */}
        </div>

        <EditPhoto
            show={show}
            handleClose={handleClose}
            handleImageChange={handleImageChange}
            handleRemoveImage={handleImageRemoval}
            handleImageUpload={handleImageUpload}
            handleImageSave={handleImageSave}
            setDefaultPhoto={setDefaultPhoto}
            uploading={uploading}
            fileInput={fileInput} 
            selectedImage={selectedImage}
            imageToDisplay={previewImage || imageUrlState}
            originalImgUrl = {imageUrl}
            showSave = {showSave}
        />
    </div>
  );
};

export default ImageUpload;
