import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Role, User } from "../types/User";
import {
  postRequest,
  putRequest,
  refreshAccessToken,
  setAccessTokenLocal,
  setAuthorizationHeader,
  setRefreshTokenLocal,
} from "../../services/apiService";
import { useUser } from "../login/UserContext";
import { checkUniqueUsername } from "./account-services";
import ImageUpload from "./ImageUpload";

interface OverviewProps {
  initialUser: any;
}

const Overview: React.FC<OverviewProps> = ({ initialUser }) => {
  const [user, setUser] = useState<User>({ ...initialUser.data });
  const [originalUser, setOriginalUser] = useState<User>({
    ...initialUser.data,
  });
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    handle: "",
  });

  const { token, setToken, refreshToken, setRefreshToken } = useUser();

  const resetForm = () => {
    setUser({ ...originalUser });
    setIsChanged(false);
  };

  useEffect(() => {
    setIsChanged(JSON.stringify(originalUser) !== JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    const validate = async () => {
      let errors = {
        firstname: "",
        lastname: "",
        handle: "",
      };
      errors.firstname = validateName("firstname", user.firstname);
      errors.lastname = validateName("lastname", user.lastname);
      errors.handle = await validateUsername(user.handle);
      setErrors(errors);
    };
    validate();
  }, [user]);

  const initiateCustomerPortal = async () => {
    try {
      const response = await postRequest(
        "/api/v1/stripe/create-customer-portal-session",
        {}
      );

      console.log("checkoutSessionUrl::",response);
      if (response && response.checkoutSessionUrl) {
        // Redirect the user to the Stripe Customer Portal page
        window.location.href = response.checkoutSessionUrl;
      } else {
        // Handle the case where no URL is returned
        alert("Unable to initiate customer portal. Please try again later.");
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error initiating customer portal: ", error);
      alert("An error occurred while trying to initiate the customer portal.");
    }
  };


  const initiateCustomerPortalNonCustom =  () => {
        return "https://checkout.bookiebrawler.com/p/login/6oEfZOcjx5o00h28ww";
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const validateName = (name: string, value: string) => {
    let errorMessage = "";
    if (value.trim() === "") {
      errorMessage = `${
        name.charAt(0).toUpperCase() + name.slice(1)
      } is required.`;
    } else if (!/^[a-z ,.'-]+$/i.test(value)) {
      errorMessage = `Invalid characters in ${
        name.charAt(0).toUpperCase() + name.slice(1)
      }.`;
    } else if (value.length > 50) {
      errorMessage = `${
        name.charAt(0).toUpperCase() + name.slice(1)
      } should not exceed 50 characters.`;
    }
    return errorMessage;
  };

  const validateUsername = async (username: string) => {
    let errorMessage = "";
    if (!username || username === initialUser.handle) return errorMessage;
    if (username.length < 3 || username.length > 20) {
      errorMessage = "Username should be between 3 and 20 characters.";
    } else if (!/^[a-zA-Z0-9._]+$/.test(username)) {
      errorMessage =
        "Username should only contain letters, numbers, dots, and underscores.";
    } else if (/\s/.test(username)) {
      errorMessage =
        "Username should not contain spaces or whitespace characters.";
    } else {
      const isUnique = await checkUniqueUsername(username);
      // console.log(`Username: ${username}, Is Unique: ${isUnique}`); // Add this line
      if (!isUnique) {
        errorMessage =
          "Username is already taken. Please choose a different one.";
      }
    }
    //console.log(`Username: ${username}, Error: ${errorMessage}`); // Add this line
    return errorMessage;
  };

  const saveChanges = async () => {
    try {
      const response = await putRequest(`/api/v1/users/${user.id}`, user);
      if (response) {
        setOriginalUser({ ...user });
        setIsChanged(false);
        const newAccessToken = response.headers.get("X-access-token");
        const newRefreshToken = response.headers.get("X-refresh-token");
        if (newAccessToken || newRefreshToken) {
          console.log("new tokens not found");
        }
        setAccessTokenLocal(newAccessToken);
        setRefreshTokenLocal(newRefreshToken);
        setToken(newAccessToken);
        setRefreshToken(newRefreshToken);

        // explicitly set the headers for the next the requests to come

        setAuthorizationHeader(newAccessToken);
      } else {
        // Handle error
        console.error("Failed to save changes.");
      }
    } catch (error) {
      // Handle error
      console.error("An error occurred while saving changes:", error);
    }
  };

  const isAutoGeneratedUsername = (username: string) => {
    // Matches 'user', followed by any sequence of characters (non-greedy),
    // followed by a UUID
    const autoGeneratedUsernamePattern =
      /^user.*?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return autoGeneratedUsernamePattern.test(username);
  };

  if (isAutoGeneratedUsername(user.handle)) user.handle = "";

  return (
    <Card
      className="p-4 mx-auto mb-4 mt-4 account-settings-card dark-transparent"
      style={{ maxWidth: "95%", maxHeight: "max-content" }}
    >
      <Card.Body>
        <Card.Title>
          <h4>Overview</h4>
          <h6 style={{ color: "rgb(123 135 148)" }}>
            View and update your personal information
          </h6>
        </Card.Title>
        <ImageUpload imageUrl={user.picture} />

        <Form className="m-0">
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={user.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="First Name"
                  className="system-default-font"
                  isInvalid={!!errors.firstname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstname}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastname">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  value={user.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur} // Add this line
                  className="system-default-font"
                  placeholder="Last Name"
                  isInvalid={!!errors.lastname}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.lastname}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="handle">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="handle"
                  value={user.handle ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="system-default-font"
                  placeholder="Choose a Username"
                  isInvalid={!!errors.handle}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.handle}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      To change your email reach out to us at
                      notifications@bookiebrawler.com
                    </Tooltip>
                  }
                >
                  <div className="d-block">
                    <Form.Control
                      style={{ color: "gray", backgroundColor: "#F0F0F0" }}
                      type="text"
                      name="email"
                      readOnly
                      value={user.email}
                      placeholder="Email"
                    />
                  </div>
                </OverlayTrigger>
              </Form.Group>
            </Col>
          </Row>
          <div className="pt-4 pb-4">
            Your Plan:{" "}
            {(user.role as string) === "BRONZE" ? "Positive EV" : "Free"}{" "}
          </div>
          <div>
            Click{" "}
            <a href={initiateCustomerPortalNonCustom()}>
              here
            </a>{" "}
            to manage your plan
          </div>

          <div className="d-flex justify-content-end mb-4">
            <Button
              variant="secondary"
              disabled={!isChanged}
              onClick={resetForm}
              className="me-2"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!isChanged}
              onClick={saveChanges}
            >
              Save changes
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Overview;
