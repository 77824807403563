import React, { useState, useEffect } from 'react';

type AudioHook = {
  url: string;
};

const useAudio = ({ url }: AudioHook) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    const onEnd = () => setPlaying(false);
    audio.addEventListener('ended', onEnd);
    return () => {
      audio.removeEventListener('ended', onEnd);
    };
  }, [audio]);

  return { playing, toggle };
};

export default useAudio;
