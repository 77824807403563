import React, { useState, useEffect } from 'react';

const KellyCriterionCalculator: React.FC = () => {
  const [kellyMultiplier, setKellyMultiplier] = useState<number | string>(1);
  const [odds, setOdds] = useState<number | string>(110);
  const [winProbability, setWinProbability] = useState<number | string>(50);
  const [expectedValue, setExpectedValue] = useState<number>(0);
  const [fractionOfBankroll, setFractionOfBankroll] = useState<number>(0);
  const [amountToWager, setAmountToWager] = useState<number>(0);

  useEffect(() => {
    const k = Number(kellyMultiplier);
    const o = Number(odds);
    const wp = Number(winProbability) / 100;

    const ev = (wp * (o / 100)) - (1 - wp);
    const f = ev > 0 ? (ev / (o / 100)) * k : 0;
    const wager = f * 5000;

    setExpectedValue(ev * 100);
    setFractionOfBankroll(f * 100);
    setAmountToWager(wager);
  }, [kellyMultiplier, odds, winProbability]);

  return (
    <div className="container mx-auto max-w-[475px] p-4 bg-gray-800 rounded-lg text-white border border-blue-200">
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <span className='w-1/2 font-bold text-sm'>Kelly Multiplier</span>
          <div className="relative w-1/2">
            <input
              type="number"
              value={kellyMultiplier}
              onChange={(e) => setKellyMultiplier(e.target.value)}
              className="w-full p-2 text-white bg-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-powder-bloo-800 focus:bg-gray-900"
            />
          </div>
        </div>
        <div className="flex items-center">
          <span className='w-1/2 font-bold text-sm'>Odds</span>
          <div className="relative w-1/2">
            <input
              type="number"
              value={odds}
              onChange={(e) => setOdds(e.target.value)}
              className="w-full p-2 text-white bg-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-powder-bloo-800 focus:bg-gray-900"
            />
          </div>
        </div>
        <div className="flex items-center">
          <span className='w-1/2 font-bold text-sm'>Win %</span>
          <div className="relative w-1/2">
            <input
              type="number"
              value={winProbability}
              onChange={(e) => setWinProbability(e.target.value)}
              className="w-full p-2 text-white bg-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-powder-bloo-800 focus:bg-gray-900"
            />
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2">%</span>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="flex flex-col items-center">
            <span className="text-gray-400 text-sm min-h-[100px]">Expected Value</span>
            <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium min-h-[50px]">
              {expectedValue.toFixed(2)}%
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-gray-400 text-sm min-h-[100px]">Fraction of Bankroll to Wager</span>
            <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium min-h-[50px]">
              {fractionOfBankroll.toFixed(2)}%
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-gray-400 text-sm min-h-[100px]">Amount to Wager with $5k bankroll</span>
            <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium min-h-[50px]">
              ${amountToWager.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KellyCriterionCalculator;
