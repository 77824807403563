import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { handleGoogleSignInAuthTokens } from "../../services/apiService";
import { useUser } from "./UserContext";

interface GoogleButtonLoginProps {
  onLogin: () => void;
  showOneTap: boolean;
}

const GoogleButtonLogin: React.FC<GoogleButtonLoginProps> = ({
  onLogin,
  showOneTap,
}) => {
  const { user, setUser, token, setToken, refreshToken, setRefreshToken } =
    useUser();

  const handleSuccess = async (credentialResponse: any) => {
    const result = await handleGoogleSignInAuthTokens(
      credentialResponse.credential
    );
    if (result) {
      setUser(result.user);
      setToken(result.tokens.access_token);
      setRefreshToken(result.tokens.refresh_token);
      onLogin();
    } else {
      console.error("Login failed");
    }
  };

  useGoogleOneTapLogin({
    onSuccess: handleSuccess,
    onError: () => console.error("Login failed"),
    promptMomentNotification: (notification: any) => {
      // console.log(notification); // You may want to handle prompt moment notifications here
    },
    disabled: !showOneTap,
    cancel_on_tap_outside: true,
    // prompt_parent_id: "google-one-tap-container", // DOM ID of the container element for the One Tap prompt
  });

  return (
    <>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </>
  );
};

export default GoogleButtonLogin;
