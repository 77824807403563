import React, { useEffect } from 'react';
import { columns } from "./components/columns";
import { DataTable } from "../../ui/data-table";
import { PositiveEVRow } from './types/positive-ev.types';
import './styles/positive-ev.scss'
import { getIsAuthenticated, getRequest } from '../../../services/apiService';

import { effect, signal } from '@preact/signals-react';
import { EVTableHeader } from './positive-ev-header';
import { isPreMatch } from './services/positive-ev-service';
import { currentUserHasEVAccess, hasPositiveEVAccess } from '../../../components/login/hasAccess';
import { defaultBookmakers } from '../../../constants';

export interface BookmakerLite {
  key: string;
  title: string;
}





export function filterOutSpecificTitle(bookmakers: BookmakerLite[], titleToRemove: string) {
  return bookmakers.filter(bookmaker => bookmaker.title !== titleToRemove);
}

const dataSignal = signal<PositiveEVRow[]>([]);
export const bookieLitesSignal = signal<BookmakerLite[]>([]);

const isLoadingSignal = signal(false);

export const getEVRowData = async (): Promise<void> => {
  isLoadingSignal.value = true;
  const url = 'api/v1/positive-ev/v2';
  try {
    const response = await getRequest(url);

    if (response && response.data) {

      
      dataSignal.value = response.data.filter((game: any) => game.home_evpercent <50 &&game.away_evpercent <50);
    } else {
      dataSignal.value = [];
    }
  } catch (error) {
    console.error(error);
    dataSignal.value = [];
  }
  isLoadingSignal.value = false;
};

// export const getBookieLiteData = async (): Promise<void> => {
 
//   const url = '/v1/bookmakers/bookmakerLite';
//   try {
//     const response =await getRequest(url); 
//     if (response && response.data) {
//       bookieLitesSignal.value = filterOutSpecificTitle(response.data as BookmakerLite[], "William Hill (US)"); //garbage code change on backend
//     } else {
//       bookieLitesSignal.value = [];
//     }
//   } catch (error) {
//     console.error(error);
//     bookieLitesSignal.value = [];
//   }
// };

bookieLitesSignal.value = defaultBookmakers;


// function showLoading() {
//   var element = document.querySelector('td[colspan="10"]');
//   if (element) { 
//       element.textContent = isLoadingSignal.value ? 'Loading...' : 'No results'; 
//   }
// }

function showLoading() {
  const element = document.querySelector('td[colspan="10"]');
  const isLoading = isLoadingSignal.value; // Assuming isLoadingSignal is defined elsewhere

  if (element) {
    const htmlElement = element as HTMLElement; // Type assertion for TypeScript

    if (isLoading) {
      // When loading, set the text and background GIF
      htmlElement.style.height = '40vh'; 
      htmlElement.textContent = 'Loading...';
      htmlElement.style.backgroundImage = 'url("https://storage.googleapis.com/assestsbookiebrawler/gifs/loading-screen.gif")'; // Replace with your GIF URL
      htmlElement.style.backgroundRepeat = 'no-repeat';
      htmlElement.style.backgroundPosition = 'center';
      htmlElement.style.backgroundSize = 'cover';
    } else {
      // When not loading, remove background and change text
      htmlElement.textContent = 'No results'; // Or set to an empty string
      htmlElement.style.backgroundImage = 'none';
    }
  }
}




export default function PositiveEV() {

const hasAccess = currentUserHasEVAccess();
 
useEffect(() => {
  
  const fetchData = async () => {
    try {
      await getEVRowData();
      const authed = await getIsAuthenticated();
      if (authed) {
        //await getBookieLiteData();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

effect(()=> {
  showLoading();
})

  return (
    <>
   
   <div className="container mx-auto py-10" style={{ minHeight: '100vh' }}>
    <EVTableHeader/>
      <DataTable columns={columns} data={dataSignal.value} />
    </div>
    </>
  );
}
