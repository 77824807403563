import React from "react";

const OddsConverterWriteUp: React.FC = () => {
  return (
    <div className="p-4 text-white mt-4">
      <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">
        How to Convert Sports Betting Odds
      </h2>
      <p className="mb-3">
        The BookieBrawler odds converter helps you easily switch between decimal
        odds, American odds, and fractional odds. Many bookmakers support all
        three formats. In the US, American odds are the most common.
      </p>
      <p className="mb-3">
        Decimal odds are standard in most countries. These odds indicate the
        potential payout if the bet wins. For example, a $100 wager on 2.0
        decimal odds results in a $200 total payout (2.0 x $100 = $200). This
        includes the initial stake, so the profit is $200 - $100 = $100. In the
        odds converter, 2.0 equals +100 American odds and 1/1 fractional odds.
      </p>
      <p className="mb-3">
        American odds are mainly used in the US. Positive figures (e.g., +200,
        +450) show total profit for a $100 bet. Betting $100 at +200 odds means
        $200 profit if the bet wins. Negative figures (e.g., -200, -450) show
        how much must be wagered to win $100 profit. For instance, a -200 bet
        requires $200 to win $100. In the odds converter, -200 equals 1.5
        decimal odds and 1/2 fractional odds.
      </p>
      <p className="mb-3">
        Fractional odds are common in the UK and Ireland. This format shows
        potential profit as a fraction of the stake. For example, a $100 bet on
        1/2 fractional odds yields $50 profit (1/2 x $100). In the odds
        converter, 1/2 equals -200 American odds and 1.5 decimal odds.
      </p>
      <p className="mb-3">
        The odds converter also calculates the breakeven win probability of
        given odds, useful for evaluating a bet. For instance, +100 odds have a
        50% breakeven probability. You should only place the wager if you
        believe the true win probability is over 50%. Conversely, +900 odds have
        a 10% breakeven probability. A +900 bet must win 1 out of 10 times to
        break even, so this bet is profitable if its win chance is over 12.5%.
      </p>
    </div>
  );
};

export default OddsConverterWriteUp;
