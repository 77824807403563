import React, { useState, useEffect } from 'react';

const PoissonCalculator: React.FC = () => {
  const [average, setAverage] = useState<number>(3.7);
  const [proposition, setProposition] = useState<number>(4);
  const [exactlyProbability, setExactlyProbability] = useState<number>(0);
  const [overProbability, setOverProbability] = useState<number>(0);
  const [underProbability, setUnderProbability] = useState<number>(0);
  const [overOdds, setOverOdds] = useState<number>(0);
  const [underOdds, setUnderOdds] = useState<number>(0);

  const factorial = (n: number): number => (n <= 1 ? 1 : n * factorial(n - 1));

  const poissonProbability = (lambda: number, k: number): number => {
    return (Math.exp(-lambda) * Math.pow(lambda, k)) / factorial(k);
  };

  useEffect(() => {
    const exactlyProb = poissonProbability(average, proposition) * 100;
    setExactlyProbability(exactlyProb);

    let overProb = 0;
    for (let i = proposition + 1; i <= 20; i++) {
      overProb += poissonProbability(average, i);
    }
    overProb *= 100;
    setOverProbability(overProb);

    let underProb = 0;
    for (let i = 0; i <= proposition; i++) {
      underProb += poissonProbability(average, i);
    }
    underProb *= 100;
    setUnderProbability(underProb);

    const calculateAmericanOdds = (prob: number) => {
      if (prob >= 50) {
        return (prob / (100 - prob)) * 100;
      } else {
        return -(100 - prob) / prob * 100;
      }
    };

    setOverOdds(calculateAmericanOdds(overProb));
    setUnderOdds(calculateAmericanOdds(underProb));
  }, [average, proposition]);

  const formatAmericanOdds = (odds: number) => {
    return odds > 0 ? `+${odds.toFixed(2)}` : `${odds.toFixed(2)}`;
  };

  return (
    <div className="container max-w-[320px] p-4 bg-gray-800 rounded-lg text-white border-[0.5px]">
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <span className="w-1/2 font-bold text-sm">Expected Average</span>
          <div className="relative w-1/2">
            <input
              type="number"
              value={average}
              onChange={(e) => setAverage(Number(e.target.value))}
              className="w-full p-2 bg-gray-900 rounded"
            />
          </div>
        </div>
        <div className="flex items-center">
          <span className="w-1/2 font-bold text-sm">Proposition</span>
          <div className="relative w-1/2">
            <input
              type="number"
              value={proposition}
              onChange={(e) => setProposition(Number(e.target.value))}
              className="w-full p-2 bg-gray-900 rounded"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-1">
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">Exactly {proposition}</span>
            <div className="rounded h-8 flex items-center justify-center text-sm font-medium">
              {exactlyProbability.toFixed(2)}%
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">Over {proposition - 0.5}</span>
            <div className="rounded h-8 flex items-center justify-center text-sm font-medium">
              {overProbability.toFixed(2)}%
            </div>
            <div className="rounded h-8 flex items-center justify-center text-sm font-medium">
              {formatAmericanOdds(overOdds)}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">Under {proposition + 0.5}</span>
            <div className="rounded h-8 flex items-center justify-center text-sm font-medium">
              {underProbability.toFixed(2)}%
            </div>
            <div className="rounded h-8 flex items-center justify-center text-sm font-medium">
              {formatAmericanOdds(underOdds)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoissonCalculator;
