import { ReactNode } from "react";
import { educationPosts } from "./betting-education/education-data";
import { blogPosts } from "./blog/blog-data";
import { mlbBlogPosts } from "./mlb/mlb-data";
import { sportsbookPosts } from "./online-sportsbooks/sportsbook-data";

export interface ContentPost {
    id: number;
    title: string;
    slug: string;
    summary: string;
    content: ReactNode;
    author: string;
    publishedDate: Date;
    coverPhoto?: string;
    image?: ContentImage;
    date?: Date
}

export interface ContentImage {
  id: number;
  title: string;
  source: string
  imageUrl?: string;
}


export const getRelevantPosts = (contentType?: string): ContentPost[] => {
    switch (contentType) {
      case "Blog":
        return blogPosts;
      case "Betting Education":
        return educationPosts;
      case "Online Sportsbooks":
            return sportsbookPosts;
      case "MLB Blog":
            return mlbBlogPosts;
      // Add more cases if you have other types of content
      default:
        return [];
    }
  };
