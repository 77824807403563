import React from 'react';
import BlackList from '../blacklist/Blacklist';

const ManageFilters: React.FC = () => {
  return (
    <>
    <h2>Manage Filters</h2>
      <BlackList/>
    </>
  )
}

export default ManageFilters;
