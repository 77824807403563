import React from "react";
import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../login/login.module.scss";

const getSelectionSundayDateUTC = () => {
  // Selection Sunday details
  const year = new Date().getFullYear(); // Assuming the current year
  const month = 2; // March (0-indexed in JavaScript)
  const day = 17;
  const hour = 18; // 6 p.m.
  const minute = 0;

  // Create a date object for Selection Sunday in ET (Eastern Time)
  // Note: ET is UTC-5, but during Daylight Saving Time it's UTC-4
  // Assuming Daylight Saving Time is in effect in March
  const selectionSundayET = new Date(
    Date.UTC(year, month, day, hour + 4, minute, 0)
  );

  return selectionSundayET;
};

const getNCAATourneyStartDate = () => {
  // Selection Sunday details
  const year = new Date().getFullYear(); // Assuming the current year
  const month = 2; // March (0-indexed in JavaScript)
  const day = 19;
  const hour = 0; // 6 p.m.
  const minute = 0;

  // Create a date object for Selection Sunday in ET (Eastern Time)
  // Note: ET is UTC-5, but during Daylight Saving Time it's UTC-4
  // Assuming Daylight Saving Time is in effect in March
  const selectionSundayET = new Date(
    Date.UTC(year, month, day, hour + 4, minute, 0)
  );

  return selectionSundayET;
};

const getNBAPlayInStartDate = () => {
  // Selection Sunday details
  const year = new Date().getFullYear(); // Assuming the current year
  const month = 3; // March (0-indexed in JavaScript)
  const day = 16;
  const hour = 0; // 6 p.m.
  const minute = 0;

  // Create a date object for Selection Sunday in ET (Eastern Time)
  // Note: ET is UTC-5, but during Daylight Saving Time it's UTC-4
  // Assuming Daylight Saving Time is in effect in March
  const selectionSundayET = new Date(
    Date.UTC(year, month, day, hour + 4, minute, 0)
  );

  return selectionSundayET;
};

const WhyUs = () => {
  const navigate = useNavigate();

  const linkTarget = "/positive-ev";
  // const handleLearnMoreClick = () => {
  //   navigate("/positive-ev");
  // };

  return (
    <div
      className="flex flex-col items-center justify-center px-4 why-us-m  text-center"
      style={{ marginBottom: "24px" }}
    >
      {/* <h3 className={"font-extrabold lg:text-3xl text-brand-gray-9 mt-4"}>
        Bet different for March Madness 2024 🍀
      </h3>
      <p className="text-lg text-powder-bloo w-full md:w-4/5 my-4">
        In a tournament known for its surprises, finding value in your March
        Madness picks is key. We specialize in identifying positive expected
        value bets in the College basketball market leveraging ESPN's Basketball
        Power Index (BPI), ensuring your March Madness sports bets stand the
        best chance of returning profit.
      </p> */}
      <h1 className="font-extrabold lg:text-5xl md:text-4xl text-2xl text-brand-gray-9 my-4">
        Why us?
      </h1>
      {/* <h2 className=""></h2> */}
      <p className="text-lg w-full md:w-4/5 text-powder-bloo">
        We understand the frustration of using platforms that just aren't quick
        enough, leaving you lagging behind and missing out on profitable
        opportunities. With our Positive EV tool, you get low latency odds in
        real-time, ensuring you're always ahead of the curve. Our platform is
        designed for beginners, featuring an intuitive, user-friendly
        interface that makes betting a breeze. Plus, we offer these premium
        features without the hefty price tag, freeing small bettors who now
        don’t have to spend their entire profit on high priced software.
        <br />
        <br />
        Whether you're a casual bettor or a seasoned pro, our platform ensures
        you get the best value for your investment, helping you profit from sports betting.
      </p>
      {/* <Link to={linkTarget} className="no-underline">
        <button className="ctaButton mt-0">Learn More</button>
      </Link> */}
      <a href={linkTarget} className="no-underline">
        <button className="ctaButton mt-3">Learn More</button>
      </a>
    </div>
  );
};

export default WhyUs;
