import React, { useState } from 'react';
import { FAQ } from './faqs.types';
import './Faqs.scss';

interface FAQProps {
  faq: FAQ;
}

const FAQComponent: React.FC<FAQProps> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-container dark-bg d-flex flex-column py-1">
      <div 
        className="mb-2" 
        onClick={() => setIsOpen(!isOpen)} 
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <span style={{ marginRight: '10px' }}>{isOpen ? '▲' : '▼'}</span>
        <div className="question"><strong>{faq.question}</strong></div>    
      </div>
      <div 
        style={{
          paddingLeft: '20px',
          transition: 'opacity 0.2s ease, max-height 0.2s ease', // Added max-height transition
          opacity: isOpen ? 1 : 0,
          maxHeight: isOpen ? '1000px' : '0', // Assuming a large max height for an open state; adjust if necessary
          overflow: 'hidden' // Prevent content from overflowing
        }} 
        className='text-white'
      >
        {faq.answer}
      </div>
    </div>
  );
};

export default FAQComponent;
