
import React from 'react';
import  './social-icon.scss'

interface Icon {
    imageUrl: string;
    redirectUrl: string;
}

type SocialIconProps = {
    icon: Icon
    color?: string
}

const SocialIcon: React.FC<SocialIconProps> = ({ icon,color }) => {
    return (
        <a href={icon.redirectUrl} target="_blank" rel="noopener noreferrer">
            <img 
                src={icon.imageUrl} 
                alt="Social Icon"
                className='p-1 m-1 social-icon'
                style={{
                    width: '28px',
                    height: '28px',
                    borderRadius: '60%',
                    backgroundColor: color? color :'#94c2dc',
                    objectFit: 'cover'
                }} 
            />
        </a>
    );
}

export default SocialIcon;
