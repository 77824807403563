import { Role, User } from "../types/User";

export  const defaultUser: User = {
    id: 0,
    firstname: "",
    handle: "",
    lastname: "",
    email: "",
    password: "",
    picture: "",
    role: Role.USER,
    customer_id: "",
    tokens: [],
    enabled: false,
    username: "",
    account_non_expired: false,
    account_non_locked: false,
    credentials_non_expired: false,
    blacklist: {bookmaker_keys:[]}
};
