import React, { useState, useEffect } from "react";
import { BetCalculatorValues } from "../../types/positive-ev.types";
import { getRequest } from "../../../../../services/apiService";
import { bankrollSignal } from "../bankroll/bankroll-form";

interface BetCalcFormProps {
  betCalc: BetCalculatorValues;
  onOddsChange: (odds: number) => void;
  onWinPercentageChange: (winPercentage: number) => void;
}

const convertAmericanToDecimalOdds = (americanOdds: number): number => {
  if (americanOdds > 0) {
    return americanOdds / 100 + 1;
  } else {
    return 100 / Math.abs(americanOdds) + 1;
  }
};

const calculateKellyCriterion = (
  winProbability: number,
  americanOdds: number,
  bankroll: number,
  kellyMultiplier: number = 1
): { fractionToWager: number; amountToWager: number } => {
  const decimalOdds = convertAmericanToDecimalOdds(americanOdds);
  const q = 1 - winProbability;
  const b = decimalOdds - 1; // Subtracting 1 to get the net odds

  // Calculating the fraction according to the Kelly formula
  let fractionToWager = (b * winProbability - q) / b;

  // Adjusting the fraction with the Kelly Multiplier
  fractionToWager *= kellyMultiplier;

  // Ensuring the fraction is between 0 and 1
  fractionToWager = Math.max(0, Math.min(fractionToWager, 1));

  // Calculating the amount to wager
  const amountToWager = bankroll * fractionToWager;

  return { fractionToWager, amountToWager };
};

const BetCalcForm: React.FC<BetCalcFormProps> = ({
  betCalc,
}) => {
  const [kellyMultiplier, setKellyMultiplier] = useState<number>(bankrollSignal.value.kelly_multiple);
  const [odds, setOdds] = useState<number>(betCalc.odds);
  const [winPercentage, setWinPercentage] = useState<number>(
    parseFloat((betCalc.winPct * 100).toFixed(3))
  );
  const [expectedValue, setExpectedValue] = useState<number>(0);
  const [fractionToWager, setFractionToWager] = useState<number>(0);
  const [amountToWager, setAmountToWager] = useState<number>(0);
  const [bankroll, setBankroll] = useState<number>(bankrollSignal.value.bankroll);

  useEffect(() => {
    const fetchKellyMultiplier = async () => {
      try {
        const response = await getRequest("/api/v1/users/bankroll");
        const data = response.data;
        setKellyMultiplier(data.kelly_multiple || 1);
        setBankroll(data.bankroll);
      } catch (error) {
        console.error("Failed to fetch Kelly Multiplier:", error);
      }
    };

    fetchKellyMultiplier();
  }, []);

  useEffect(() => {
    runCalculations();
  }, [odds, winPercentage,kellyMultiplier]);

  const handleOddsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOdds(parseFloat(e.target.value) || 0);
  };

  const handleWinPercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWinPercentage(parseFloat(e.target.value) || 0);
  };

  const runCalculations = () => {
   
    const winProbability = winPercentage / 100;
    const lossProbability = 1 - winProbability;
    const winAmount = convertAmericanToDecimalOdds(odds) - 1;
    const EV = (winProbability * winAmount - lossProbability * 1) * 100;

    const {fractionToWager, amountToWager} = calculateKellyCriterion(winPercentage/100,odds,bankroll,kellyMultiplier);

    // Replace the following with your actual calculation logic
    setExpectedValue(EV); // Mock calculation
    setAmountToWager(amountToWager);
    setFractionToWager(fractionToWager); 
  };



  return (
    <div>
      <h4 className="text-lg text-white font-medium mb-3">{betCalc.event}</h4>

      <label className="block">
        Kelly Multiplier
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          type="number"
          min="0.1"
          step="0.1"       
          placeholder="Kelly Multiplier"
          value={kellyMultiplier}
          onChange={(e) => setKellyMultiplier(parseFloat(e.target.value))}
          style={{ textAlign: "right" }}
        />
      </label>

      <label className="block">
        Odds
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          type="number"
          placeholder="Odds"
          value={odds}
          onChange={handleOddsChange}
          style={{ textAlign: "right" }}
        />
      </label>

      <label className="block">
        Win %
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          type="number"
          placeholder="Win %"
          min="0.1"
          max="100"
          value={winPercentage}
          onChange={handleWinPercentageChange}
          style={{ textAlign: "right" }}
        />
      </label>

      <div>
        <div>Expected Value: {expectedValue.toFixed(2)}</div>
        <div>Fraction of Bankroll to Wager: {fractionToWager.toFixed(2)}</div>
        <div>Amount to Wager: {amountToWager.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default BetCalcForm;