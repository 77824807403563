// FiltersList.tsx
import React, { useEffect, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../../../../components/ui/popover"; // Update the import path as needed
import { TableFilter } from "./table-filter.types"; // Update the import path as needed
import FilterComponent from "./table-filter-view"; // Update the import path as needed
import { TableFilterForm } from "./table-filter-form"; // Update the import path as needed
import { getRequest } from "../../../../services/apiService";
import { signal } from "@preact/signals-react";
import { getEVRowData } from "../../positive-ev/positive-ev";

export const filtersSignal = signal<TableFilter[]>([]);

export const getFilterData = async (): Promise<void> => {
  const url = "api/v1/table-filters/users";
  try {
    const response = await getRequest(url);
    if (response && response.data) {
      filtersSignal.value = response.data;
    } else {
      filtersSignal.value = [];
    }
  } catch (error) {
    console.error(error);
    filtersSignal.value = [];
  }
};

//getFilterData();

export const currentFilterSignal = signal<TableFilter | null>(null);

const FiltersList: React.FC = () => {
  useEffect(() => {
    getFilterData();
  }, []);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleFilterUpdate = (
    updatedFilter: TableFilter,
    updateType: string
  ) => {
    let updatedFilters = [...filtersSignal.value];
    const originalCount = updatedFilters.length;
    console.log(
      "Received update for filter:",
      updatedFilter,
      "with update type:",
      updateType
    );

    switch (updateType) {
      case "delete":
        // Check if the filter exists in the current list
        const filterExists = updatedFilters.some(
          (filter) => filter.id === updatedFilter.id
        );

        // Filter out the deleted filter
        updatedFilters = updatedFilters.filter(
          (filter) => filter.id !== updatedFilter.id
        );
        console.log(
          "Deleted filter, updated filters:",
          updatedFilters.map((f) => f.id)
        );

        // Update the currentFilterSignal if the deleted filter was the current filter
        if (currentFilterSignal.value?.id === updatedFilter.id) {
          currentFilterSignal.value = null;
        }

        // Mutate the filtersSignal to trigger reactivity
        filtersSignal.value = updatedFilters;

        break;

      case "save":
      case "apply":
      case "saveAndApply":
        const existingFilterIndex = updatedFilters.findIndex(
          (filter) => filter.id === updatedFilter.id
        );

        if (existingFilterIndex !== -1) {
          // For 'save', preserve the existing active status
          if (updateType === "save") {
            updatedFilter.isActive =
              updatedFilters[existingFilterIndex].isActive;
          }

          updatedFilters[existingFilterIndex] = updatedFilter;
          console.log("Updated existing filter");
        } else {
          updatedFilters.push(updatedFilter);
          console.log("Added new filter");
        }

        if (updateType === "apply" || updateType === "saveAndApply") {
          updatedFilters = updatedFilters.map((filter) => {
            return filter.id === updatedFilter.id
              ? { ...filter, isActive: true }
              : { ...filter, isActive: false };
          });
          console.log("Applied filter, updated filters:", updatedFilters);
        }
        break;

      case "toggle":
        const toggleIndex = updatedFilters.findIndex(
          (filter) => filter.id === updatedFilter.id
        );

        if (toggleIndex !== -1) {
          // Capture the old active status
          const oldActiveStatus = updatedFilters[toggleIndex].isActive;

          // Deactivate all filters
          updatedFilters = updatedFilters.map((filter) => ({
            ...filter,
            isActive: false,
          }));

          // Set the toggled filter to the opposite of its original active status
          updatedFilters[toggleIndex] = {
            ...updatedFilters[toggleIndex],
            isActive: !oldActiveStatus,
          };

          console.log(
            "Toggled filter active state:",
            updatedFilters[toggleIndex]
          );
        } else {
          console.error("Filter not found for toggling:", updatedFilter.id);
        }

        // Update the filtersSignal
        filtersSignal.value = updatedFilters;
        break;
      default:
        console.log("Unhandled update type:", updateType);
        break;
    }
    currentFilterSignal.value = updatedFilter ?? ({} as TableFilter);
    filtersSignal.value = updatedFilters;

    getEVRowData();
  };

  const handleNewFilter = (newFilter: TableFilter, shouldApply: string) => {
    if (shouldApply === "saveAndApply") {
      // Set the new filter as active
      newFilter.isActive = true;

      // Set all other filters to inactive
      filtersSignal.value = filtersSignal.value.map((filter) => ({
        ...filter,
        isActive: false,
      }));
    }

    // Add the new filter to the list
    filtersSignal.value = [...filtersSignal.value, newFilter];

    // Update currentFilterSignal if activating immediately
    if (shouldApply === "saveAndApply") {
      currentFilterSignal.value = newFilter;
    }
  };

  const onSelect = (selectedFilter: TableFilter) => {
    //setCurrentFilter(selectedFilter);
    currentFilterSignal.value = selectedFilter;
  };

  const getPopoverContent = () => {
    if (!isDesktop) {
      return <div>Please use on desktop to add a filter.</div>;
    } else if (filtersSignal.value.length >= 10) {
      return <>Max number of filters reached</>;
    } else {
      return <TableFilterForm onFilterUpdate={handleNewFilter} mode="create" />;
    }
  };

  return (
    <>
   <div className="flex flex-row">
        <div className="mr-4">
          <Popover>
            <div className="light-brder rounded p-1 mb-2">
              <PopoverTrigger>
                <img className=' h-4 w-4 min-h-[16px] min-w-[16px]' src="https://storage.googleapis.com/assestsbookiebrawler/icons/settings-icon-img.png"></img>
              </PopoverTrigger>
            </div>
            <PopoverContent>
              {getPopoverContent()}
            </PopoverContent>
          </Popover>
        </div>
 
        <div className="flex flex-row flex-wrap">
          {filtersSignal.value.map((filter) => (
            <FilterComponent
              key={filter.id}
              filter={filter}
              onFilterUpdate={handleFilterUpdate}
              onSelect={onSelect}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FiltersList;
