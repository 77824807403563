import React from 'react';
import { BetCalculatorValues } from '../../types/positive-ev.types';
import BetCalcForm from './bet-calc-form'; // Adjust the import path as necessary

// ...rest of your imports

export interface BetCalcModalProps {
    show: boolean;
    toggleModal: () => void;
    betCalcs: BetCalculatorValues[];
  }

const BetCalcModal: React.FC<BetCalcModalProps> = ({
  show,
  toggleModal,
  betCalcs,
}) => {
  if (!show) {
    return null;
  }

  const handleOddsChange = (index: number, odds: number) => {
    // Handle odds change for the betCalc at the given index
  };

  const handleWinPercentageChange = (index: number, winPercentage: number) => {
    // Handle win percentage change for the betCalc at the given index
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 bg-gray-700">
      <div className="p-3 min-w-[20vw] rounded-lg shadow-xl dark-bg">
        <div className="flex justify-between items-center mb-4">
          {/* <h3 className="text-xl text-white leading-6 font-medium text-gray-900">Bet Calculator</h3> */}
          <button onClick={toggleModal} className="text-gray-500 hover:text-gray-700 rounded p-2">
            <span className="text-white">✕</span>
          </button>
        </div>
        <div>
          {betCalcs.map((betCalc: BetCalculatorValues, index: number) => (
            <BetCalcForm
              key={index}
              betCalc={betCalc}
              onOddsChange={(odds) => handleOddsChange(index, odds)}
              onWinPercentageChange={(winPercentage) => handleWinPercentageChange(index, winPercentage)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BetCalcModal;
