import { signal } from "@preact/signals-react";
import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../../services/apiService";

interface BankrollDetailsRequest {
  bankroll: number;
  kelly_multiple: number;
}

interface BankrollFormProps {
  show: boolean;
}

export const bankrollSignal = signal<BankrollDetailsRequest>(
  {} as BankrollDetailsRequest
);

export const getBankRollData = async (): Promise<void> => {
  const url = "api/v1/users/bankroll";
  try {
    const response = await getRequest(url);
    if (response && response.data) {
      bankrollSignal.value = response.data;
    } else {
      bankrollSignal.value = {} as BankrollDetailsRequest;
    }
  } catch (error) {
    console.error(error);
    bankrollSignal.value = {} as BankrollDetailsRequest;
  }
};

 //getBankRollData();

const BankrollForm: React.FC<BankrollFormProps> = ({ show }) => {
  const [bankrollDetails, setBankrollDetails] =
    useState<BankrollDetailsRequest>({ bankroll: 0, kelly_multiple: 0 });

  const [error, setError] = useState<string>("");

  useEffect(() => {
    getBankRollData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value);

    // Using the signal to update the state
    bankrollSignal.value = {
      ...bankrollSignal.value,
      [name]: numericValue,
    };

    // Validate kelly_multiple
    if (
      name === "kelly_multiple" &&
      (numericValue < 0.1 || numericValue > 1.0)
    ) {
      setError("Kelly Multiplier must be between 0.1 and 1.0");
    } else {
      setError("");
    }
  };
  const postBankrollData = async () => {
    const url = "/api/v1/users/bankroll"; // Make sure this URL is correct
    try {
      const response = await postRequest(url, bankrollSignal.value);
      if (response.bankroll) {
        console.log("Bankroll details updated successfully", response.data);
        bankrollSignal.value = response;
        // setBankrollDetails({ bankroll: 0, kelly_multiple: 0 });
        setError(""); // Also clear any errors if the update is successful
        // Handle success response
      } else {
        console.error("Failed to update bankroll details");
        // Handle non-success response
      }
    } catch (error) {
      console.error("An error occurred while updating bankroll details", error);
      // Handle error case
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    postBankrollData();
    window.location.reload();
  };

  if (!show) return null;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}
    >
      <label htmlFor="bankroll">Bankroll</label>
      <input
        type="number"
        id="bankroll"
        name="bankroll"
        value={bankrollSignal.value?.bankroll?.toString() ?? ''}
        onChange={handleInputChange}
        placeholder="Enter your bankroll"
      />

      <label htmlFor="kelly_multiple">Kelly Multiplier</label>
      <input
        type="number"
        id="kelly_multiple"
        name="kelly_multiple"
        min="0.1"
        max="1.0"
        step="0.1"
        value={bankrollSignal.value?.kelly_multiple?.toString()?? ''}
        onChange={handleInputChange}
        placeholder="Enter your Kelly multiplier"
      />
      {error && <div style={{ color: "red" }}>{error}</div>}

      {!error && (
        <button
          onClick={handleSubmit}
          disabled={!!error}
          style={{ alignSelf: "flex-end", marginTop: "10px" }}
        >
          Update
        </button>
      )}
    </div>
  );
};

export default BankrollForm;
