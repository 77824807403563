import { signal } from '@preact/signals-react';
import React, { useState } from 'react';
import { BetCalculatorValues } from '../../types/positive-ev.types';
import BetCalcModal from './bet-calculator-modal'; // Adjust the import path as necessary

interface ModalToggleProps {
    betCalcValues: BetCalculatorValues[];
  }
  
  export const showSignal =  signal(false);

  const ModalToggle: React.FC<ModalToggleProps> = ({ betCalcValues }) => {
    const [showModal, setShowModal] = useState(false);


  
  // Placeholder image source, replace with your own image URL
  const imageUrl = 'https://storage.googleapis.com/assestsbookiebrawler/icons/calculator-icon.png';

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleModal2 = () => {
   showSignal.value= !showSignal.value;
  };

  return (
    <>
      <img
        src={imageUrl}
        alt="Bet Calculator"
        style={{ width: '30px',minWidth:'30px', height: 'auto', cursor: 'pointer' }}
        onClick={toggleModal}
      />
      <BetCalcModal
        show={showModal}
        //show2={}
        toggleModal={toggleModal}
        betCalcs={betCalcValues}
      />
    </>
  );
};

export default ModalToggle;


