import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import PositiveEV from "./positive-ev"; // Assuming this is your authorized component
import PositiveEVUnAuthed from "./positive-ev-unauthed"; // Assuming this is your unauthorized component
import { currentUserHasEVAccess } from "../../../components/login/hasAccess";

// Function to check if the current user has access to Positive EV features
// This is a placeholder; you need to implement this function based on your auth logic

// Define an interface for the component props
interface PositiveEVRouteProps {
  authed: boolean;
}

const PositiveEVRoute: React.FC<PositiveEVRouteProps> = ({ authed }) => {
  return (
    <>
    {
      <Helmet>
        <title>Positive Expected Value Betting Tool - Positive EV Bet Finder | BookieBrawler</title>
        <link rel="canonical" href="https://bookiebrawler.com/positive-ev" />
        <meta
          name="description"
          content="Explore BookieBrawler, the advanced Positive EV (+EV) Betting Software designed for savvy bettors. Our tool serves as a comprehensive Positive EV Bets Finder, enabling you to identify the most valuable betting opportunities. With features like the Positive EV Bet Finder and Positive EV Betting Tool, BookieBrawler simplifies the process of finding bets with the best expected value. Elevate your betting strategy with BookieBrawler, where smart betting meets Positive EV."
        />
        <meta name="robots" content="index,follow"></meta>
      </Helmet> && 
      authed && currentUserHasEVAccess() ? (
        <PositiveEV />
      ) : (
        <PositiveEVUnAuthed authed={authed} />
      )}
    </>
  );
};

export default PositiveEVRoute;
