import { useEffect, useState } from "react";

function getDarkModeSetting(): boolean {
  const darkModeSetting = localStorage.getItem("darkMode");
  if (darkModeSetting !== null) {
    return JSON.parse(darkModeSetting);
  }
  // Check if user's system preference is for dark mode
  const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return prefersDarkMode && darkModeSetting !== false;
}

export const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(getDarkModeSetting());

  const handleDarkMode = () => {
    if (isDarkMode) {
      document.querySelector("body")!.setAttribute("data-theme", "dark");
      localStorage.setItem("darkMode", JSON.stringify(true));
    } else {
      document.querySelector("body")!.setAttribute("data-theme", "light");
      localStorage.setItem("darkMode", JSON.stringify(false));
    }
  };

  useEffect(() => {
    handleDarkMode();
  }, [isDarkMode]);

  return { isDarkMode, setIsDarkMode };
};
