import React, { useState, useEffect } from 'react';

const ParlayCalculator: React.FC = () => {
  const [totalWager, setTotalWager] = useState<number | string>(100);
  const [odds, setOdds] = useState<Array<number | string>>(['', '']);
  const [parlayOdds, setParlayOdds] = useState<number>(0);
  const [totalProfit, setTotalProfit] = useState<number>(0);
  const [totalPayout, setTotalPayout] = useState<number>(0);

  const handleOddsChange = (index: number, value: number | string) => {
    const newOdds = odds.map((odd, i) => (i === index ? value : odd));
    setOdds(newOdds);

    if (value !== '' && index === odds.length - 1) {
      addBet();
    }

    if (value === '' && odds.length > 2) {
      removeBet(index);
    }
  };

  const addBet = () => {
    setOdds(prevOdds => {
      if (!prevOdds.includes('')) {
        return [...prevOdds, ''];
      }
      return prevOdds;
    });
  };

  const removeBet = (index: number) => {
    const newOdds = odds.filter((_, i) => i !== index);
    setOdds(newOdds);
  };

  const calculateImpliedProbability = (odd: number) => {
    return odd > 0 ? 100 / (odd + 100) : Math.abs(odd) / (Math.abs(odd) + 100);
  };

  const calculateAmericanOdds = (probability: number) => {
    return probability > 0.5 ? -100 * probability / (1 - probability) : 100 / probability - 100;
  };

  useEffect(() => {
    const validOdds = odds.filter(odd => odd !== '');
    if (validOdds.length > 0) {
      const impliedProbs = validOdds.map(odd => calculateImpliedProbability(Number(odd)));
      const parlayProb = impliedProbs.reduce((acc, prob) => acc * prob, 1);
      const parlayOdds = calculateAmericanOdds(parlayProb);
      const payout = (Number(totalWager) * parlayOdds) / 100 + Number(totalWager);
      const profit = payout - Number(totalWager);

      setParlayOdds(parlayOdds);
      setTotalPayout(payout);
      setTotalProfit(profit);
    } else {
      setParlayOdds(0);
      setTotalPayout(0);
      setTotalProfit(0);
    }
  }, [totalWager, odds]);

  return (
    <div className="container max-w-[500px] w-auto p-4 bg-gray-800 rounded-lg text-white border-[0.1px] border-blue-200">
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <span className='w-1/3 font-bold text-sm'>Parlay Total Wager</span>
          <div className="relative w-2/3">
            <input
              type="number"
              value={totalWager}
              onChange={(e) => setTotalWager(e.target.value)}
              className="w-full p-2 bg-gray-900 rounded pr-8"
            />
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2">$</span>
          </div>
        </div>
        {odds.map((odd, index) => (
          <div key={index} className="flex items-center">
            <span className='w-1/3 font-bold text-sm'>{`Bet #${index + 1} Odds`}</span>
            <div className="relative w-2/3">
              <input
                type="number"
                value={odd}
                onChange={(e) => handleOddsChange(index, e.target.value)}
                className={`w-full p-2 rounded ${odd === '' ? 'bg-gray-700 text-gray-400' : 'bg-gray-900 text-white'}`}
                placeholder={odd === '' ? 'Enter odds' : ''}
              />
            </div>
          </div>
        ))}
        <div className="flex flex-col items-start mt-4 w-full">
          <div className="flex justify-between w-full">
            <div className="flex flex-col items-center mb-2 w-1/3">
              <span className='font-bold text-sm'>Parlay Odds</span>
              <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">
                {parlayOdds > 0 ? `+${parlayOdds.toFixed(2)}` : parlayOdds.toFixed(2)}
              </div>
            </div>
            <div className="flex flex-col items-center mb-2 w-1/3">
              <span className='font-bold text-sm'>Total Profit</span>
              <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">
                ${totalProfit.toFixed(2)}
              </div>
            </div>
            <div className="flex flex-col items-center mb-2 w-1/3">
              <span className='font-bold text-sm'>Total Payout</span>
              <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">
                ${totalPayout.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParlayCalculator;
