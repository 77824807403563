// FairOddsCell.tsx
import React from 'react';
import { formatOdds } from '../../services/positive-ev-service';

// Define the props type for FairOddsCell
type FairOddsCellProps = {
  fairOdds: number[];
  //formatOdds: (odds: number[]) => string[]; // Assuming formatOdds is a function that formats the odds
};

const FairOddsCell: React.FC<FairOddsCellProps> = ({ fairOdds }) => {
  if(!fairOdds)return null;
  const fairOddsFormatted = formatOdds(fairOdds);

  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: fairOdds.length === 1 ? "center" : "space-around",
    height: "100%",
  };

  return (
    <div className="font-medium" style={style}>
      {fairOddsFormatted.map((odds, index) => (
        <div key={index} className="my-4">
          {odds}
        </div>
      ))}
    </div>
  );
};
export default FairOddsCell;