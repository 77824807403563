import EVTipModal from "./components/positive-ev-tip-modal";
import FiltersList from "../common/filters/table-filter-views";
import { currentUserHasEVAccess } from "../../../components/login/hasAccess";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const EVTableHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSmallMobile, setIsSmallMobile] = useState(
    window.innerWidth <= 400 && window.innerHeight <= 700
  );

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallMobile(window.innerWidth <= 400 && window.innerHeight <= 800);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const navtoTips = () => {
    navigate("/positive-ev/tips");
  };

  return (
    <>
      <Helmet>
        <title>Positive EV (+EV) | BookieBrawler</title>
        <link rel="canonical" href={'https://bookiebrawler.com/positive-ev'} />
        <meta
          name="description"
          content="Positive EV (+EV) Betting Software designed for savvy bettors. Comprehensive Positive EV Bets Finder, with bet size calculator based on Kelly Criterion"
        />
      </Helmet>
      <div className="text-white px-3 pt-2 mb-2 flex flex-col lg:flex-row justify-between items-center lg:items-start">
        <div className="flex items-center">
          <h1 className="tracking-wide font-extrabold text-left lg:text-7xl md:text-5xl text-3xl font-code-next text-brand-gray-9">
            Positive EV
          </h1>
          <span className="px-3">
            <img
              src="https://storage.googleapis.com/assestsbookiebrawler/icons/tool-tip-icon.svg" // Replace with the correct image URL or path
              alt="Info Icon"
              className="cursor-pointer"
              onClick={isSmallMobile ? navtoTips : toggleModal}
            />
            <EVTipModal show={isModalOpen} toggleModal={toggleModal} />
          </span>
        </div>
      </div>
      <div className="px-3">
        <p className="text-base py-2 text-white max-w-lg">
          Positive EV betting you know and love, reinvented with an innovative
          edge.
        </p>
        {/* {!currentUserHasEVAccess() && (<button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-4 mb-6 py-2 px-4 rounded self-start lg:self-center"
        >
          Subscribe now
        </button>)} */}

        <FiltersList />
      </div>
    </>
  );
};
