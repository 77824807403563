import React from "react";
import styles from "../../login/login.module.scss";

const BookieBrawlerInfo: React.FC = () => {
  return (
    <div className="dark-bg feature-list-m md:px-5 px-4 md:px-0">
      <div className="dark-bg text-lightbloo md:px-0 text-med feature-list-p">
        
        <div className="grid lg:grid-cols-2 lg:gap-4 items-center py-5 extra-light-btm-brder">
          <div className="order-1 text-center lg:text-left lg:order-1 min-h-[150px] flex items-center justify-center">
            <h1 className="font-extrabold lg:text-5xl md:text-4xl text-xl text-brand-gray-9 text-white max-w-[350px]">
              <span className="text-powder-bloo">Positive EV bet finder</span> compatible with major 
              <span className="text-powder-bloo"> US Sportsbooks</span>
            </h1>
          </div>
          <div className="order-2 flex justify-center lg:order-2 my-4">
            <img
              src="https://storage.googleapis.com/assestsbookiebrawler/gifs/ezgif.com-resize.gif"
              alt="bet finder animation"
              className="w-full h-auto lg:max-h-[40vh] lg:min-h-[150px] object-contain"
            />
          </div>
        </div>

        <div className="grid lg:grid-cols-2 lg:gap-4 items-center py-5 extra-light-btm-brder">
          <div className="order-1 lg:order-2 text-center lg:text-left min-h-[150px] flex items-center justify-center">
            <h1 className="font-extrabold lg:text-5xl md:text-4xl text-xl text-brand-gray-9 text-white max-w-[350px]">
              <span className="text-powder-bloo">Engage</span> with like-minded sports bettors and gain insights from
              <span className="text-powder-bloo"> community forums</span>
              <span className="text-base"> (coming soon)</span>
            </h1>
          </div>
          <div className="order-2 lg:order-1 flex justify-center my-4">
            <img
              src="https://storage.googleapis.com/assestsbookiebrawler/gifs/betting-community.gif"
              alt="bet finder animation"
              className="w-full h-auto lg:max-h-[40vh] lg:min-h-[150px] object-contain"
            />
          </div>
        </div>

        <div className="grid lg:grid-cols-2 lg:gap-4 items-center py-5">
          <div className="order-1 lg:order-1 min-h-[150px] text-center lg:text-left flex items-center justify-center">
            <h1 className="font-extrabold lg:text-5xl md:text-4xl text-xl text-brand-gray-9 text-white max-w-[350px]">
              Create private <span className="text-powder-bloo">betting groups</span> to directly reach your audience
              <span className="text-base"> (coming soon)</span>
            </h1>
          </div>
          <div className="order-2 flex justify-center lg:order-2 my-4">
            <img
              src="https://storage.googleapis.com/assestsbookiebrawler/gifs/private-group.gif"
              alt="bet finder animation"
              className="w-full h-auto lg:max-h-[40vh] lg:min-h-[150px] object-contain"
            />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default BookieBrawlerInfo;
