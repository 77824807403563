import * as z from "zod";

const isNumberString = (value: string) =>
  !isNaN(parseFloat(value)) && isFinite(parseFloat(value));

export const tableFilterFormSchema = z.object({
  name: z
    .string()
    .min(2, "Name must be at least 2 characters")
    .max(30, "Filter name can't be more than 30 characters")
    .optional(),
  oddsMin: z
    .string()
   // .refine(isNumberString, { message: "Odds Min must be a number" })
    .transform((value) => (value ? parseFloat(value) : undefined))
    .optional(),
  oddsMax: z
    .string()
   // .refine(isNumberString, { message: "Odds Max must be a number" })
    .transform((value) => (value ? parseFloat(value) : undefined))
    .optional(),
  percentMin: z
    .string()
    // .refine((value) => isNumberString(value) && parseFloat(value) > 0, {
    //   message: "Percent Min must be a number greater than 0",
    // })
    .transform((value) => (value ? parseFloat(value) : undefined))
    .optional(),
  percentMax: z
    .string()
    //.refine(isNumberString, { message: "Percent Max must be a number" })
    .transform((value) => (value ? parseFloat(value) : undefined))
    .optional(),
  bookies: z.array(z.string()).optional(),
  leagues: z.array(z.string()).optional(),
  dateMin: z.string().optional(),
  dateMax: z.string().optional(),
  isActive: z.boolean().default(false).optional(),
});
