import { BookieImage } from "../../../../products/common/bookie-image";
import React from "react";
import styles from "../../../../login/login.module.scss";
import { bookieLitesSignal, BookmakerLite } from "../../positive-ev";
import { defaultBookmakers } from "../../../../../constants";
import { getIsAuthenticated } from "../../../../../services/apiService";

interface OnboardModalProps {
  isOpen: boolean;
  onClose: () => void;
}


const fetchData = async () => {
  try {
    const authed = await getIsAuthenticated();
    if (authed) {
     // await getBookieLiteData();
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

fetchData();

const OnboardModal: React.FC<OnboardModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  
  const bookies :BookmakerLite[] = bookieLitesSignal.value && bookieLitesSignal.value.length >0 ?  bookieLitesSignal.value :defaultBookmakers;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-auto mt-5 py-auto ">
    <div className="dark-transparent p-6 rounded-lg shadow-lg text-white flex flex-col">
    
      <div className="flex-1">
      <h5 style={{ fontSize: "1em" }} className="font-normal mb-4">
          <span className={styles.shine + " font-lg-bloo"}>
            Our First Product{" "}
          </span>
          | Positive EV
        </h5>
        <div className="my-4">
          <h3 className="text-lg font-bold text-orange-600">
            1. Grab a Bunch of Bookies!
          </h3>
          <p className="text-sm">
            Choice is Power: The more books you have, the better odds you're
            getting over time. Don't leave money on the table by not signing up
            for as many books as possible. Browse our list of bookies below and
            click to sign up.
          </p>
          {bookies.map((bookmaker, index) => (
            <BookieImage key={index} bookie={bookmaker.key} />
          ))}
        </div>

        <div className="my-4">
          <h3 className="text-lg font-bold text-blue-600">
            2. Track Your Bets
          </h3>
          <p className="text-sm">
            Pikkit's Your Pal: Keep tabs on your bets and download Pikkit for
            smart betting. Click the button below to download Pikkit and start
            tracking your journey to big wins.
          </p>
          <BookieImage bookie={'pikkit'} />
        </div>

        <div className="my-4">
          <h3 className="text-lg font-bold text-green-600">
            3. Start Making Money!
          </h3>
          <p className="text-sm">
            Bet, Click, Win!: You're all set! Now, hit the bet button to begin
            getting data-driven bets. It's easy, fun and just that simple. Happy
            betting!
          </p>
        </div>

        </div>
   

        <div className="flex justify-between items-center mt-3">
          <button onClick={onClose} className="font-bold py-2 px-4">
            Close
          </button>
          <button onClick={() => window.location.href = "/positive-ev"} className="font-bold py-2 px-4">
            Bet
          </button>
        </div>
        {/* <button onClick={onClose} className="font-bold py-2 px-4 mt-3">
          Close
        </button>

        <button onClick={() =>  window.location.href = "/positive-ev"} className="font-bold py-2 px-4 mt-3">
          Bet
        </button> */}
      </div>
    </div>
  );
};

export default OnboardModal;
