import React from "react";
import { BookmakerLite } from "./components/products/positive-ev/positive-ev";
// import { Odds } from "./types/Odds";x


export function getImagePathByLeague(key: string): string {
    const bookmakerKeyPathMap = new Map<string, string >();
  
    bookmakerKeyPathMap.set("americanfootball_nfl", "");
    bookmakerKeyPathMap.set("americanfootball_ncaaf", "");
    bookmakerKeyPathMap.set("americanfootball_nfl_super_bowl_winner", "");
    bookmakerKeyPathMap.set("aussierules_afl", "");
    bookmakerKeyPathMap.set("baseball_mlb", "");
    bookmakerKeyPathMap.set("basketball_nba", "https://w7.pngwing.com/pngs/126/557/png-transparent-nba-logo-2005u201306-nba-season-orlando-magic-the-nba-finals-logo-nba-professional-basketball-logo-free-logo-design-template-text-wedding-logo-thumbnail.png");
    bookmakerKeyPathMap.set("cricket_test_match", "");
    bookmakerKeyPathMap.set("golf_masters_tournament_winner", "");
    bookmakerKeyPathMap.set("golf_the_open_championship_winner", "");
    bookmakerKeyPathMap.set("golf_us_open_winner", "");
    bookmakerKeyPathMap.set("icehockey_nhl", "");
    bookmakerKeyPathMap.set("mma_mixed_martial_arts", "");
    bookmakerKeyPathMap.set("rugbyleague_nrl", "");
    bookmakerKeyPathMap.set("soccer_australia_aleague", "");
    bookmakerKeyPathMap.set("soccer_brazil_campeonato", "");
    return bookmakerKeyPathMap.get(key)||"";
}


export function getImagePathByTeamTitle(key: string): string {
    const bookmakerKeyPathMap = new Map<string, string >();
  
    bookmakerKeyPathMap.set("Boston Celtics", "");
    bookmakerKeyPathMap.set("Brooklyn Nets", "");
    bookmakerKeyPathMap.set("New York Knicks", "");
    bookmakerKeyPathMap.set("Philadelphia 76ers", "");
    bookmakerKeyPathMap.set("Toronto Raptors", "");
    bookmakerKeyPathMap.set("Chicago Bulls", "");
    bookmakerKeyPathMap.set("Cleveland Cavaliers", "");
    bookmakerKeyPathMap.set("Detroit Pistons", "");
    bookmakerKeyPathMap.set("Indiana Pacers", "");
    bookmakerKeyPathMap.set("Milwaukee Bucks", "");
    bookmakerKeyPathMap.set("Atlanta Hawks", "https://upload.wikimedia.org/wikipedia/en/thumb/2/24/Atlanta_Hawks_logo.svg/1200px-Atlanta_Hawks_logo.svg.png");
    bookmakerKeyPathMap.set("Charlotte Hornets", "");
    bookmakerKeyPathMap.set("Miami Heat", "");
    bookmakerKeyPathMap.set("Orlando Magic", "https://upload.wikimedia.org/wikipedia/en/thumb/1/10/Orlando_Magic_logo.svg/1200px-Orlando_Magic_logo.svg.png");
    bookmakerKeyPathMap.set("Washington Wizards", "");
    bookmakerKeyPathMap.set("Dallas Mavericks", "");
    bookmakerKeyPathMap.set("Houston Rockets", "");
    bookmakerKeyPathMap.set("Memphis Grizzlies", "");
    bookmakerKeyPathMap.set("New Orleans Pelicans", "");
    bookmakerKeyPathMap.set("San Antonio Spurs", "");
    bookmakerKeyPathMap.set("Denver Nuggets", "");
    bookmakerKeyPathMap.set("Minnesota Timberwolves", "");
    bookmakerKeyPathMap.set("Oklahoma City Thunder", "");
    bookmakerKeyPathMap.set("Portland Trail Blazers", "");
    bookmakerKeyPathMap.set("Utah Jazz", "");
    bookmakerKeyPathMap.set("Golden State Warriors", "");
    bookmakerKeyPathMap.set("Los Angeles Clippers", "");
    bookmakerKeyPathMap.set("Los Angeles Lakers", "");
    bookmakerKeyPathMap.set("Phoenix Suns", "");
    bookmakerKeyPathMap.set("Sacramento Kings", "");
  return bookmakerKeyPathMap.get(key)||"";
  }

  export function getDescriptionByComponent(key: string): string {
    const descrKeyMap = new Map<string, string >();
  
    descrKeyMap.set("BetFinder", "Experience the best real time betting options on live and upcoming games for all major sports. Includes odds on the latest Moneylines, Totals, and Spreads from the top US bookmakers!");
   //descrKeyMap.set("Brooklyn Nets", "");
    return descrKeyMap.get(key)||"";
}

  export function getIconPathBySportKey(key: string): string {
    const sportKeyToIconPathMap = new Map<string, string >();
    sportKeyToIconPathMap.set("basketball_nba", "/icons/sports/basketball.png");
    sportKeyToIconPathMap.set("icehockey_nhl", "/icons/sports/hockey.png");

    return sportKeyToIconPathMap.get(key)||"";
  }

//   export const defaultBookmakers: BookmakerLite[] = [
//     { key: 'barstool', title: 'Barstool Sportsbook' },
//     { key: 'betmgm', title: 'BetMGM' },
//     { key: 'betonlineag', title: 'BetOnline.ag' },
//     { key: 'betrivers', title: 'BetRivers' },
//     { key: 'betus', title: 'BetUS' },
//     { key: 'bovada', title: 'Bovada' },
//     { key: 'circasports', title: 'Circa Sports' },
//     { key: 'draftkings', title: 'DraftKings' },
//     { key: 'fanduel', title: 'FanDuel' },
//     { key: 'foxbet', title: 'FOX Bet' },
//     { key: 'lowvig', title: 'LowVig.ag' },
//     { key: 'mybookieag', title: 'MyBookie.ag' },
//     { key: 'pointsbetus', title: 'PointsBet (US)' },
//     { key: 'superbook', title: 'SuperBook' },
//     { key: 'twinspires', title: 'TwinSpires' },
//     { key: 'unibet_us', title: 'Unibet' },
//     { key: 'williamhill_us', title: 'Caesars' },
//     { key: 'wynnbet', title: 'WynnBET' }
//   ];

  export const defaultBookmakers: BookmakerLite[] = [
    {
        key: "barstool",
        title: "Barstool Sportsbook"
    },
    {
        key: "betmgm",
        title: "BetMGM"
    },
    {
        key: "betonlineag",
        title: "BetOnline.ag"
    },
    {
        key: "betrivers",
        title: "BetRivers"
    },
    {
        key: "betus",
        title: "BetUS"
    },
    {
        key: "bovada",
        title: "Bovada"
    },
    {
        key: "circasports",
        title: "Circa Sports"
    },
    {
        key: "draftkings",
        title: "DraftKings"
    },
    {
        key: "fanduel",
        title: "FanDuel"
    },
    {
        key: "foxbet",
        title: "FOX Bet"
    },
    {
        key: "lowvig",
        title: "LowVig.ag"
    },
    {
        key: "mybookieag",
        title: "MyBookie.ag"
    },
    {
        key: "pointsbetus",
        title: "PointsBet (US)"
    },
    {
        key: "superbook",
        title: "SuperBook"
    },
    {
        key: "twinspires",
        title: "TwinSpires"
    },
    {
        key: "unibet_us",
        title: "Unibet"
    },
    {
        key: "williamhill_us",
        title: "Caesars"
    },
    {
        key: "wynnbet",
        title: "WynnBET"
    },
    {
        key: "espnbet",
        title: "ESPN Bet"
    },
    {
        key: "ballybet",
        title: "Bally Bet"
    },
    {
        key: "betparx",
        title: "BetParx"
    },
    {
        key: "fliff",
        title: "Fliff"
    },
    {
        key: "hardrockbet",
        title: "Hard Rock Bet"
    },
    {
        key: "sisportsbook",
        title: "SI Sportsbook"
    },
    {
        key: "tipico_us",
        title: "Tipico"
    },
    {
        key: "windcreek",
        title: "Wind Creek"
    }
  ];
  

export const leagueList = 
    [
        {
            "key": "americanfootball_ncaaf",
            "group": "American Football",
            "title": "NCAAF",
            "description": "US College Football",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "basketball_ncaab",
            "group": "Basketball",
            "title": "NCAAB",
            "description": "US College Basketball",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "americanfootball_nfl",
            "group": "American Football",
            "title": "NFL",
            "description": "US Football",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "americanfootball_nfl_super_bowl_winner",
            "group": "American Football",
            "title": "NFL Super Bowl Winner",
            "description": "Super Bowl Winner 2021/2022",
            "active": true,
            "hasOutrights": true
        },
        {
            "key": "aussierules_afl",
            "group": "Aussie Rules",
            "title": "AFL",
            "description": "Aussie Football",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "baseball_mlb",
            "group": "Baseball",
            "title": "MLB",
            "description": "Major League Baseball",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "basketball_nba",
            "group": "Basketball",
            "title": "NBA",
            "description": "US Basketball",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "cricket_test_match",
            "group": "Cricket",
            "title": "Test Matches",
            "description": "International Test Matches",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "golf_masters_tournament_winner",
            "group": "Golf",
            "title": "Masters Tournament Winner",
            "description": "2022 WInner",
            "active": true,
            "hasOutrights": true
        },
        {
            "key": "golf_the_open_championship_winner",
            "group": "Golf",
            "title": "The Open Winner",
            "description": "2021 WInner",
            "active": true,
            "hasOutrights": true
        },
        {
            "key": "golf_us_open_winner",
            "group": "Golf",
            "title": "US Open Winner",
            "description": "2021 WInner",
            "active": true,
            "hasOutrights": true
        },
        {
            "key": "icehockey_nhl",
            "group": "Ice Hockey",
            "title": "NHL",
            "description": "US Ice Hockey",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "mma_mixed_martial_arts",
            "group": "Mixed Martial Arts",
            "title": "MMA",
            "description": "Mixed Martial Arts",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "rugbyleague_nrl",
            "group": "Rugby League",
            "title": "NRL",
            "description": "Aussie Rugby League",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_australia_aleague",
            "group": "Soccer",
            "title": "A-League",
            "description": "Aussie Soccer",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_brazil_campeonato",
            "group": "Soccer",
            "title": "Brazil Série A",
            "description": "Brasileirão Série A",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_denmark_superliga",
            "group": "Soccer",
            "title": "Denmark Superliga",
            "description": "",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_finland_veikkausliiga",
            "group": "Soccer",
            "title": "Veikkausliiga - Finland",
            "description": "",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_japan_j_league",
            "group": "Soccer",
            "title": "J League",
            "description": "Japan Soccer League",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_league_of_ireland",
            "group": "Soccer",
            "title": "League of Ireland",
            "description": "Airtricity League Premier Division",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_norway_eliteserien",
            "group": "Soccer",
            "title": "Eliteserien - Norway",
            "description": "Norwegian Soccer",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_spain_segunda_division",
            "group": "Soccer",
            "title": "La Liga 2 - Spain",
            "description": "Spanish Soccer",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_sweden_allsvenskan",
            "group": "Soccer",
            "title": "Allsvenskan - Sweden",
            "description": "Swedish Soccer",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_sweden_superettan",
            "group": "Soccer",
            "title": "Superettan - Sweden",
            "description": "Swedish Soccer",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_uefa_european_championship",
            "group": "Soccer",
            "title": "UEFA Euro 2020",
            "description": "UEFA European Championship",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "soccer_usa_mls",
            "group": "Soccer",
            "title": "MLS",
            "description": "Major League Soccer",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "tennis_atp_french_open",
            "group": "Tennis",
            "title": "ATP French Open",
            "description": "Men's Singles",
            "active": true,
            "hasOutrights": false
        },
        {
            "key": "tennis_wta_french_open",
            "group": "Tennis",
            "title": "WTA French Open",
            "description": "Women's Singles",
            "active": true,
            "hasOutrights": false
        }
    ]

export const ODDS_LIST = [
    {
        "id": "aab1f9b4f2edf5d8e1af2e5b998160c0",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T00:10:23Z",
        "home_team": "Orlando Magic",
        "away_team": "Atlanta Hawks",
        "bookmakers": [
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 450
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -714
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -125,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -111,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -125,
                                "point": 253.5
                            },
                            {
                                "name": "Under",
                                "price": -111,
                                "point": 253.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:56:40Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 479
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -715
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -106,
                                "point": 9.0
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -115,
                                "point": -9.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 253.0
                            },
                            {
                                "name": "Under",
                                "price": -106,
                                "point": 253.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:55:36Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 520
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -775
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -115,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -115,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 252.5
                            },
                            {
                                "name": "Under",
                                "price": -120,
                                "point": 252.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 525
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -800
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -125,
                                "point": 253.5
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 253.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 470
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -550
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -110,
                                "point": 8.0
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -105,
                                "point": -8.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 253.5
                            },
                            {
                                "name": "Under",
                                "price": 100,
                                "point": 253.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 450
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -750
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -125,
                                "point": 9.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -105,
                                "point": -9.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -120,
                                "point": 252.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 252.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 568
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -1013
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -113,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -113,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 252.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 252.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 500
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -850
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -110,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -118,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 253.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 253.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 500
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -1111
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -118,
                                "point": 9.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -118,
                                "point": -9.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 252.5
                            },
                            {
                                "name": "Under",
                                "price": -125,
                                "point": 252.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 620
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -769
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 780
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -625
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 550
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -1000
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -115,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -115,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 252.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 252.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 650
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -1115
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -108,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -118,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -109,
                                "point": 253.5
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 253.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 650
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -1115
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -108,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -118,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -109,
                                "point": 253.5
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 253.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 650
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -1115
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -108,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -118,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 253.0
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 253.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": 650
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -1115
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Atlanta Hawks",
                                "price": -108,
                                "point": 8.5
                            },
                            {
                                "name": "Orlando Magic",
                                "price": -118,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -109,
                                "point": 253.5
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 253.5
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "b5483f8e5a95742e60271327301438a6",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T00:10:32Z",
        "home_team": "Indiana Pacers",
        "away_team": "Golden State Warriors",
        "bookmakers": [
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 180
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -235
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -128,
                                "point": 4.5
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -102,
                                "point": -4.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -122,
                                "point": 236.5
                            },
                            {
                                "name": "Under",
                                "price": -106,
                                "point": 236.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 190
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -230
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -105,
                                "point": 4.5
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -110,
                                "point": -4.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 237.0
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 237.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 365
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -555
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -117,
                                "point": 7.5
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -117,
                                "point": -7.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -117,
                                "point": 241.5
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 241.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 195
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -125,
                                "point": 5.5
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -105,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 237.5
                            },
                            {
                                "name": "Under",
                                "price": -120,
                                "point": 237.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 177
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -233
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -129,
                                "point": 4.5
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": 101,
                                "point": -4.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 238.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 238.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 180
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -220
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 236.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 236.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 205
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -303
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -105,
                                "point": 4.5
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -133,
                                "point": -4.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -133,
                                "point": 236.5
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 236.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 230
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -238
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 240
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -233
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 188
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -113,
                                "point": 4.0
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -113,
                                "point": -4.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 236.0
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 236.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 188
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -113,
                                "point": 4.0
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -113,
                                "point": -4.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -114,
                                "point": 236.0
                            },
                            {
                                "name": "Under",
                                "price": -112,
                                "point": 236.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 188
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -113,
                                "point": 4.0
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -113,
                                "point": -4.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -114,
                                "point": 236.0
                            },
                            {
                                "name": "Under",
                                "price": -112,
                                "point": 236.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 188
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -113,
                                "point": 4.0
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -113,
                                "point": -4.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -114,
                                "point": 236.0
                            },
                            {
                                "name": "Under",
                                "price": -112,
                                "point": 236.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 175
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -230
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 238.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 238.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 170
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -225
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -105,
                                "point": 3.5
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -125,
                                "point": -3.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -120,
                                "point": 237.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 237.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:56:40Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": 200
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -250
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Golden State Warriors",
                                "price": -113,
                                "point": 5.0
                            },
                            {
                                "name": "Indiana Pacers",
                                "price": -108,
                                "point": -5.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -104,
                                "point": 237.0
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 237.0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "7a71cbbf573f928f26f88398a1bfa59a",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T00:11:46Z",
        "home_team": "Charlotte Hornets",
        "away_team": "Detroit Pistons",
        "bookmakers": [
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:55:36Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 460
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -670
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -115,
                                "point": 8.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -115,
                                "point": -8.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -120,
                                "point": 258.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 258.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 310
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -440
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -125,
                                "point": 6.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -104,
                                "point": -6.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -114,
                                "point": 259.5
                            },
                            {
                                "name": "Under",
                                "price": -114,
                                "point": 259.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 500
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -600
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -110,
                                "point": 7.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -105,
                                "point": -7.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": 100,
                                "point": 258.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 258.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:56:40Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 330
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -435
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -108,
                                "point": 7.0
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -113,
                                "point": -7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 259.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 259.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 145
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -192
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -111,
                                "point": 3.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -125,
                                "point": -3.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -125,
                                "point": 255.5
                            },
                            {
                                "name": "Under",
                                "price": -111,
                                "point": 255.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 375
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -530
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -107,
                                "point": 6.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -120,
                                "point": -6.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -117,
                                "point": 259.0
                            },
                            {
                                "name": "Under",
                                "price": -109,
                                "point": 259.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 410
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -590
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -112,
                                "point": 7.0
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -114,
                                "point": -7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 259.0
                            },
                            {
                                "name": "Under",
                                "price": -118,
                                "point": 259.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 410
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -590
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -112,
                                "point": 7.0
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -114,
                                "point": -7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 259.0
                            },
                            {
                                "name": "Under",
                                "price": -118,
                                "point": 259.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 410
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -590
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -112,
                                "point": 7.0
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -114,
                                "point": -7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 259.0
                            },
                            {
                                "name": "Under",
                                "price": -118,
                                "point": 259.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 300
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -500
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -105,
                                "point": 7.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -133,
                                "point": -7.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -118,
                                "point": 257.5
                            },
                            {
                                "name": "Under",
                                "price": -118,
                                "point": 257.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 390
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -455
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 460
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -385
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 375
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -500
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 259.5
                            },
                            {
                                "name": "Under",
                                "price": -120,
                                "point": 259.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 340
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -525
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -120,
                                "point": 6.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -110,
                                "point": -6.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 356
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -528
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -118,
                                "point": 6.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -108,
                                "point": -6.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": 101,
                                "point": 260.5
                            },
                            {
                                "name": "Under",
                                "price": -129,
                                "point": 260.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": 400
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -650
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Charlotte Hornets",
                                "price": -125,
                                "point": 7.5
                            },
                            {
                                "name": "Detroit Pistons",
                                "price": -105,
                                "point": -7.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 256.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 256.5
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "3398b4703f71a89ab00a5ec2cb522fba",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T00:41:05Z",
        "home_team": "Toronto Raptors",
        "away_team": "Sacramento Kings",
        "bookmakers": [
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 180
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -238
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -125,
                                "point": 5.5
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -105,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -111,
                                "point": 233.5
                            },
                            {
                                "name": "Under",
                                "price": -125,
                                "point": 233.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 225
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -285
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -110,
                                "point": 5.5
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -120,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 233.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 233.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 200
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -265
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -128,
                                "point": 5.5
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -102,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -106,
                                "point": 232.5
                            },
                            {
                                "name": "Under",
                                "price": -122,
                                "point": 232.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 225
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -280
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 234.5
                            },
                            {
                                "name": "Under",
                                "price": -120,
                                "point": 234.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 239
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -325
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -108,
                                "point": 5.5
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -118,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 233.5
                            },
                            {
                                "name": "Under",
                                "price": -118,
                                "point": 233.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 200
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -245
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -105,
                                "point": 5.5
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -110,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -107,
                                "point": 235.0
                            },
                            {
                                "name": "Under",
                                "price": -107,
                                "point": 235.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 210
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -275
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -120,
                                "point": 5.5
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -110,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 234.5
                            },
                            {
                                "name": "Under",
                                "price": -120,
                                "point": 234.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 235
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -305
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -112,
                                "point": 6.0
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -114,
                                "point": -6.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 232.0
                            },
                            {
                                "name": "Under",
                                "price": -112,
                                "point": 232.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 235
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -305
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -112,
                                "point": 6.0
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -114,
                                "point": -6.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 232.0
                            },
                            {
                                "name": "Under",
                                "price": -112,
                                "point": 232.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 235
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -305
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -112,
                                "point": 6.0
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -114,
                                "point": -6.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 232.0
                            },
                            {
                                "name": "Under",
                                "price": -112,
                                "point": 232.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 235
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -305
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -112,
                                "point": 6.0
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -114,
                                "point": -6.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 232.0
                            },
                            {
                                "name": "Under",
                                "price": -112,
                                "point": 232.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 230
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -325
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 220
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -278
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 280
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -222
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 230
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -357
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -118,
                                "point": 6.5
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -118,
                                "point": -6.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -133,
                                "point": 232.5
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 232.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:49:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": 191
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -239
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Sacramento Kings",
                                "price": -108,
                                "point": 5.0
                            },
                            {
                                "name": "Toronto Raptors",
                                "price": -113,
                                "point": -5.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 233.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 233.5
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "b7c264c9ec63025b0f1e58dad846bcbf",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T00:45:21Z",
        "home_team": "Chicago Bulls",
        "away_team": "New York Knicks",
        "bookmakers": [
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 170
                            },
                            {
                                "name": "New York Knicks",
                                "price": -222
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -105,
                                "point": 4.5
                            },
                            {
                                "name": "New York Knicks",
                                "price": -125,
                                "point": -4.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -111,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:56:40Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 187
                            },
                            {
                                "name": "New York Knicks",
                                "price": -233
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -113,
                                "point": 5.5
                            },
                            {
                                "name": "New York Knicks",
                                "price": -108,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 228.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 228.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 210
                            },
                            {
                                "name": "New York Knicks",
                                "price": -280
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -120,
                                "point": 5.5
                            },
                            {
                                "name": "New York Knicks",
                                "price": -108,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -118,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 210
                            },
                            {
                                "name": "New York Knicks",
                                "price": -265
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -115,
                                "point": 5.5
                            },
                            {
                                "name": "New York Knicks",
                                "price": -115,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -120,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 160
                            },
                            {
                                "name": "New York Knicks",
                                "price": -210
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -110,
                                "point": 4.5
                            },
                            {
                                "name": "New York Knicks",
                                "price": -120,
                                "point": -4.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 180
                            },
                            {
                                "name": "New York Knicks",
                                "price": -220
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -110,
                                "point": 5.5
                            },
                            {
                                "name": "New York Knicks",
                                "price": -105,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -107,
                                "point": 228.0
                            },
                            {
                                "name": "Under",
                                "price": -107,
                                "point": 228.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 200
                            },
                            {
                                "name": "New York Knicks",
                                "price": -250
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 200
                            },
                            {
                                "name": "New York Knicks",
                                "price": -266
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -123,
                                "point": 5.5
                            },
                            {
                                "name": "New York Knicks",
                                "price": -103,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -103,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -123,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 185
                            },
                            {
                                "name": "New York Knicks",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -109,
                                "point": 5.0
                            },
                            {
                                "name": "New York Knicks",
                                "price": -117,
                                "point": -5.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -114,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 185
                            },
                            {
                                "name": "New York Knicks",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -109,
                                "point": 5.0
                            },
                            {
                                "name": "New York Knicks",
                                "price": -117,
                                "point": -5.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -114,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 185
                            },
                            {
                                "name": "New York Knicks",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -109,
                                "point": 5.0
                            },
                            {
                                "name": "New York Knicks",
                                "price": -117,
                                "point": -5.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -114,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 185
                            },
                            {
                                "name": "New York Knicks",
                                "price": -240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -109,
                                "point": 5.0
                            },
                            {
                                "name": "New York Knicks",
                                "price": -117,
                                "point": -5.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -114,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 205
                            },
                            {
                                "name": "New York Knicks",
                                "price": -217
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 215
                            },
                            {
                                "name": "New York Knicks",
                                "price": -208
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 188
                            },
                            {
                                "name": "New York Knicks",
                                "price": -278
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": -118,
                                "point": 5.5
                            },
                            {
                                "name": "New York Knicks",
                                "price": -118,
                                "point": -5.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -125,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Chicago Bulls",
                                "price": 190
                            },
                            {
                                "name": "New York Knicks",
                                "price": -260
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "d3824233d6de72ac591932ef2ed0567c",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T01:10:15Z",
        "home_team": "Oklahoma City Thunder",
        "away_team": "Miami Heat",
        "bookmakers": [
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -714
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 450
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -111,
                                "point": -11.5
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -125,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -125,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -111,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:49:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -834
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 555
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -115,
                                "point": -12.0
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -106,
                                "point": 12.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -2000
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 870
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -113,
                                "point": -15.5
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -115,
                                "point": 15.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -114,
                                "point": 225.5
                            },
                            {
                                "name": "Under",
                                "price": -114,
                                "point": 225.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -1799
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 1300
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -107,
                                "point": -16.5
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -107,
                                "point": 16.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -107,
                                "point": 226.5
                            },
                            {
                                "name": "Under",
                                "price": -107,
                                "point": 226.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -2000
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 850
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -117,
                                "point": -14.0
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -109,
                                "point": 14.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 227.0
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 227.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -2000
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 850
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -117,
                                "point": -14.0
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -109,
                                "point": 14.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 227.0
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 227.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -2000
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 850
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -117,
                                "point": -14.0
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -109,
                                "point": 14.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 227.0
                            },
                            {
                                "name": "Under",
                                "price": -117,
                                "point": 227.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -3335
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 1150
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -109,
                                "point": -16.0
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -117,
                                "point": 16.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -1300
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 750
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -120,
                                "point": -13.5
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -110,
                                "point": 13.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 226.5
                            },
                            {
                                "name": "Under",
                                "price": -120,
                                "point": 226.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -833
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 660
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -625
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 780
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -1000
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 475
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -110,
                                "point": -12.5
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -125,
                                "point": 12.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -125,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -2500
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 1000
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -145,
                                "point": -13.5
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 110,
                                "point": 13.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": 110,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -145,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -1397
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 689
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -140,
                                "point": -13.5
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 110,
                                "point": 13.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": 110,
                                "point": 228.5
                            },
                            {
                                "name": "Under",
                                "price": -140,
                                "point": 228.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -1000
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 625
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 227.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 227.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -1200
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": 625
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Miami Heat",
                                "price": -110,
                                "point": -14.5
                            },
                            {
                                "name": "Oklahoma City Thunder",
                                "price": -120,
                                "point": 14.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -120,
                                "point": 225.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 225.5
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "36057d36c86c0f7eab7411b7502cc9f9",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T01:10:18Z",
        "home_team": "San Antonio Spurs",
        "away_team": "Portland Trail Blazers",
        "bookmakers": [
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1156
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 616
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -123,
                                "point": -12.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -103,
                                "point": 12.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -103,
                                "point": 244.5
                            },
                            {
                                "name": "Under",
                                "price": -123,
                                "point": 244.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1666
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 750
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -117,
                                "point": -14.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -117,
                                "point": 14.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -125,
                                "point": 243.5
                            },
                            {
                                "name": "Under",
                                "price": -111,
                                "point": 243.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:55:54Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1429
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 751
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -113,
                                "point": -13.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -108,
                                "point": 13.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 242.5
                            },
                            {
                                "name": "Under",
                                "price": -108,
                                "point": 242.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1400
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 650
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -110,
                                "point": -13.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -120,
                                "point": 13.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": 100,
                                "point": 244.5
                            },
                            {
                                "name": "Under",
                                "price": -130,
                                "point": 244.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1200
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 700
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 241.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 241.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1800
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 800
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -106,
                                "point": -13.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -122,
                                "point": 13.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 242.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 242.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1350
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 675
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -110,
                                "point": -13.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -120,
                                "point": 13.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 243.5
                            },
                            {
                                "name": "Under",
                                "price": -120,
                                "point": 243.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1200
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 850
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -107,
                                "point": -14.0
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -107,
                                "point": 14.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -107,
                                "point": 241.0
                            },
                            {
                                "name": "Under",
                                "price": -107,
                                "point": 241.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1100
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 600
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -125,
                                "point": -12.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -105,
                                "point": 12.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -105,
                                "point": 244.5
                            },
                            {
                                "name": "Under",
                                "price": -125,
                                "point": 244.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1250
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 700
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -112,
                                "point": -12.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -114,
                                "point": 12.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 243.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 243.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1250
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 700
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -113,
                                "point": -12.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -114,
                                "point": 12.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -114,
                                "point": 241.5
                            },
                            {
                                "name": "Under",
                                "price": -112,
                                "point": 241.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1250
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 700
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -112,
                                "point": -12.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -114,
                                "point": 12.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 243.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 243.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1250
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 700
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -112,
                                "point": -12.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -114,
                                "point": 12.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 243.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 243.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -909
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 780
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -769
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 1000
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -1429
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": 600
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Portland Trail Blazers",
                                "price": -118,
                                "point": -13.5
                            },
                            {
                                "name": "San Antonio Spurs",
                                "price": -118,
                                "point": 13.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -118,
                                "point": 243.5
                            },
                            {
                                "name": "Under",
                                "price": -118,
                                "point": 243.5
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "5c07dcaeb08cc2e15bc3c12d368d6f3d",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T02:10:00Z",
        "home_team": "Dallas Mavericks",
        "away_team": "Cleveland Cavaliers",
        "bookmakers": [
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 120
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -140
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -109,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -109,
                                "point": -2.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 115
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -135
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 217.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 217.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 120
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -140
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -105,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -115,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "wynnbet",
                "title": "WynnBET",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 118
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -141
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "superbook",
                "title": "SuperBook",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 110
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -130
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.0
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 216.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 216.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 118
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -138
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -108,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -112,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betus",
                "title": "BetUS",
                "last_update": "2022-12-15T01:56:23Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 120
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -140
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 217.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 217.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 115
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -140
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 216.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 216.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betonlineag",
                "title": "BetOnline.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 123
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -145
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -106,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -115,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -111,
                                "point": 217.5
                            },
                            {
                                "name": "Under",
                                "price": -111,
                                "point": 217.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 120
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -140
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -105,
                                "point": 2.0
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -115,
                                "point": -2.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "lowvig",
                "title": "LowVig.ag",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 123
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -143
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -101,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -109,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -105,
                                "point": 217.5
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 217.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:56:40Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 118
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -143
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -113,
                                "point": 3.0
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -108,
                                "point": -3.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 216.5
                            },
                            {
                                "name": "Under",
                                "price": -108,
                                "point": 216.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 110
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -140
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -107,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -107,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -107,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -107,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 115
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -140
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -115,
                                "point": 216.5
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 216.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 118
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -139
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -108,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 118
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -139
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -108,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 118
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -139
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -108,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 118
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -139
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -108,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betrivers",
                "title": "BetRivers",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 118
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -139
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -113,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -108,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 132
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -135
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 136
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -132
                            }
                        ]
                    }
                ]
            },
            {
                "key": "intertops",
                "title": "Intertops",
                "last_update": "2022-12-15T01:56:30Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 120
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -140
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -110,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -110,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 217.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 217.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": 110
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -149
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Cleveland Cavaliers",
                                "price": -105,
                                "point": 2.5
                            },
                            {
                                "name": "Dallas Mavericks",
                                "price": -118,
                                "point": -2.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -118,
                                "point": 216.5
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 216.5
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "ab1d5dacdf22f684bf27b0e18f39da6a",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T02:10:00Z",
        "home_team": "Denver Nuggets",
        "away_team": "Washington Wizards",
        "bookmakers": [
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -590
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 440
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -625
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 470
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -105,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -115,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:56:40Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -715
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 479
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -106,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -115,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -600
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 500
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -107,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -107,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -107,
                                "point": 224.0
                            },
                            {
                                "name": "Under",
                                "price": -107,
                                "point": 224.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -670
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 480
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -112,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -109,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betrivers",
                "title": "BetRivers",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -670
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 480
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -112,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -109,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -670
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 480
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -112,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -109,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -670
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 480
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -112,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -109,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -670
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 480
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -112,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -109,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -108,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -113,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -833
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 536
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -526
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 520
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -500
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 540
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -909
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 450
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -600
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 425
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betus",
                "title": "BetUS",
                "last_update": "2022-12-15T01:56:23Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -625
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 475
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -115,
                                "point": -11.0
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -105,
                                "point": 11.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -650
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 460
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -650
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 450
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 225.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 225.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -650
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 450
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -109,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -109,
                                "point": 11.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "intertops",
                "title": "Intertops",
                "last_update": "2022-12-15T01:56:30Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -600
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 440
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "wynnbet",
                "title": "WynnBET",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -667
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 475
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 225.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 225.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betonlineag",
                "title": "BetOnline.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -667
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 460
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -115,
                                "point": -11.0
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -105,
                                "point": 11.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -111,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -111,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "lowvig",
                "title": "LowVig.ag",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -599
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 460
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.0
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 100,
                                "point": 11.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -105,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "superbook",
                "title": "SuperBook",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -650
                            },
                            {
                                "name": "Washington Wizards",
                                "price": 475
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Denver Nuggets",
                                "price": -110,
                                "point": -11.5
                            },
                            {
                                "name": "Washington Wizards",
                                "price": -110,
                                "point": 11.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 224.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 224.5
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "1946cb371d80d786def92fe565bb60ae",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-15T03:10:00Z",
        "home_team": "Los Angeles Clippers",
        "away_team": "Minnesota Timberwolves",
        "bookmakers": [
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -280
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 235
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -109,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -109,
                                "point": 7.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -278
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 222
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -275
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 230
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "wynnbet",
                "title": "WynnBET",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -270
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 220
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -295
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 240
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -112,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -108,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betus",
                "title": "BetUS",
                "last_update": "2022-12-15T01:56:23Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -270
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 220
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -125,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": 105,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betonlineag",
                "title": "BetOnline.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -286
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 235
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -115,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -105,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -105,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -115,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "lowvig",
                "title": "LowVig.ag",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -280
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 235
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 100,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": 100,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -300
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 230
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.5
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 7.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 222.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 222.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "pointsbetus",
                "title": "PointsBet (US)",
                "last_update": "2022-12-15T01:56:31Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -265
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 230
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -107,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -107,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -107,
                                "point": 222.0
                            },
                            {
                                "name": "Under",
                                "price": -107,
                                "point": 222.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:45:30Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -270
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 212
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -6.5
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 6.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 223.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 223.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "circasports",
                "title": "Circa Sports",
                "last_update": "2022-12-15T01:56:40Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -271
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 214
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -6.5
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 6.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -278
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 225
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -112,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -109,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -278
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 225
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -112,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -109,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -278
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 225
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -112,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -109,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -278
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 225
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -112,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -109,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betrivers",
                "title": "BetRivers",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -278
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 225
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -112,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -109,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betfair",
                "title": "Betfair",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -250
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 240
                            }
                        ]
                    },
                    {
                        "key": "h2h_lay",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -238
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 250
                            }
                        ]
                    }
                ]
            },
            {
                "key": "intertops",
                "title": "Intertops",
                "last_update": "2022-12-15T01:56:30Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -270
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 230
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -275
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 225
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 221.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 221.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "foxbet",
                "title": "FOX Bet",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -286
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 200
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -6.5
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 6.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 222.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 222.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "superbook",
                "title": "SuperBook",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -260
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": 220
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Los Angeles Clippers",
                                "price": -110,
                                "point": -7.0
                            },
                            {
                                "name": "Minnesota Timberwolves",
                                "price": -110,
                                "point": 7.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 222.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 222.0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "9c5326d9497a026d7564696f11164949",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-16T01:10:00Z",
        "home_team": "Houston Rockets",
        "away_team": "Miami Heat",
        "bookmakers": [
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Houston Rockets",
                                "price": 176
                            },
                            {
                                "name": "Miami Heat",
                                "price": -210
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Houston Rockets",
                                "price": -110,
                                "point": 5.0
                            },
                            {
                                "name": "Miami Heat",
                                "price": -110,
                                "point": -5.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betus",
                "title": "BetUS",
                "last_update": "2022-12-15T01:56:23Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Houston Rockets",
                                "price": 172
                            },
                            {
                                "name": "Miami Heat",
                                "price": -205
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "5beef06da41f050826bc783f48f51857",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-16T01:10:00Z",
        "home_team": "Memphis Grizzlies",
        "away_team": "Milwaukee Bucks",
        "bookmakers": [
            {
                "key": "fanduel",
                "title": "FanDuel",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -120
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": 102
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "mybookieag",
                "title": "MyBookie.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -121
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": 100
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -122
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": 102
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betrivers",
                "title": "BetRivers",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -122
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": 102
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -122
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": 102
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -122
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": 102
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -122
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": 102
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "Memphis Grizzlies",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Milwaukee Bucks",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "0c71261cc5577646cfe21ee3e6ee6d8b",
        "sport_key": "basketball_nba",
        "sport_title": "NBA",
        "commence_time": "2022-12-16T02:00:00Z",
        "home_team": "Utah Jazz",
        "away_team": "New Orleans Pelicans",
        "bookmakers": [
            {
                "key": "draftkings",
                "title": "DraftKings",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -125
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 105
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -105,
                                "point": -2.0
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -115,
                                "point": 2.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 231.0
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 231.0
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betonlineag",
                "title": "BetOnline.ag",
                "last_update": "2022-12-15T01:56:57Z",
                "markets": [
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -111,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -111,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -111,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -111,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "superbook",
                "title": "SuperBook",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -120
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 100
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "lowvig",
                "title": "LowVig.ag",
                "last_update": "2022-12-15T01:56:59Z",
                "markets": [
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -105,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -105,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -105,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -105,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "barstool",
                "title": "Barstool Sportsbook",
                "last_update": "2022-12-15T01:56:48Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -121
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 100
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -109,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -112,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -109,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "gtbets",
                "title": "GTbets",
                "last_update": "2022-12-15T01:56:25Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -125
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 105
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -109,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -109,
                                "point": 1.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "bovada",
                "title": "Bovada",
                "last_update": "2022-12-15T01:57:01Z",
                "markets": [
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betrivers",
                "title": "BetRivers",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -121
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 100
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -109,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -112,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -109,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "sugarhouse",
                "title": "SugarHouse",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -121
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 100
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -109,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -112,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -109,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "twinspires",
                "title": "TwinSpires",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -121
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 100
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -109,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -112,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -109,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "wynnbet",
                "title": "WynnBET",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -110,
                                "point": -2.0
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -110,
                                "point": 2.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betus",
                "title": "BetUS",
                "last_update": "2022-12-15T01:56:23Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -125
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 105
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "unibet_us",
                "title": "Unibet",
                "last_update": "2022-12-15T01:56:24Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -121
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 100
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -109,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -112,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -112,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -109,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "betmgm",
                "title": "BetMGM",
                "last_update": "2022-12-15T01:56:50Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -120
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 100
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -110,
                                "point": -1.5
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -110,
                                "point": 1.5
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            },
            {
                "key": "williamhill_us",
                "title": "William Hill (US)",
                "last_update": "2022-12-15T01:56:39Z",
                "markets": [
                    {
                        "key": "h2h",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -130
                            },
                            {
                                "name": "Utah Jazz",
                                "price": 110
                            }
                        ]
                    },
                    {
                        "key": "spreads",
                        "outcomes": [
                            {
                                "name": "New Orleans Pelicans",
                                "price": -110,
                                "point": -2.0
                            },
                            {
                                "name": "Utah Jazz",
                                "price": -110,
                                "point": 2.0
                            }
                        ]
                    },
                    {
                        "key": "totals",
                        "outcomes": [
                            {
                                "name": "Over",
                                "price": -110,
                                "point": 231.5
                            },
                            {
                                "name": "Under",
                                "price": -110,
                                "point": 231.5
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export default {ODDS_LIST};
