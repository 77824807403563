import { BookieImage } from '../../../../products/common/bookie-image';
import React from 'react';
import { filterBetsWithDifferentPrice, formatOdd } from '../../services/positive-ev-service';
import { Bet, PositiveEVRow } from '../../types/positive-ev.types';
import { placeHolderBet, placeHolderBets } from './bet-cell.constants';

interface BookiesCellProps {
  positiveEVRow: PositiveEVRow;
}

const BookiesCell: React.FC<BookiesCellProps> = ({
  positiveEVRow,
}) => {


  const betsList= positiveEVRow.bets
  let awayBets = positiveEVRow.away_bets;
  let homeBets = positiveEVRow.home_bets;
  if (!awayBets && !homeBets) return null;

  if (!awayBets || awayBets.length===0) {   
    awayBets = [placeHolderBet, placeHolderBet];
  }
  if (!homeBets || homeBets.length===0) {
    homeBets = [placeHolderBet, placeHolderBet];
  }

  const bestAwayBet = awayBets?.[0];
  const bestHomeBet = homeBets?.[0];


  const hasAwayEV = positiveEVRow.away_evpercent > 0;

  const hasHomeEV = positiveEVRow.home_evpercent > 0;
  const homePrice = bestHomeBet?.price;
  const awayPrice = bestAwayBet?.price;

  const awayBetMaxes = awayPrice ? filterBetsWithDifferentPrice(awayBets, awayPrice) :placeHolderBets;
  const homeBetMaxes = homePrice ? filterBetsWithDifferentPrice(homeBets, homePrice) :placeHolderBets;

  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
  };

  console.log(homeBetMaxes,"homeBetMaxes")

  return (
    <div style={style}>
      {bestAwayBet && (
        <div className={`my-2 d-flex align-items-center ${hasAwayEV ? "positive-ev" : "positive-ev-other"}`}>
          <div className="d-flex flex-row align-items-center">
            <div className="odds mr-2" style={{ minWidth: "35px" }}>
              {formatOdd(awayPrice??0)}
            </div>
            {awayBetMaxes.map((bet, index) => (
              <BookieImage key={index} bookie={bet.bookie} />
            ))}
          </div>
        </div>
      )}
      {bestHomeBet && (
        <div className={`my-2 d-flex align-items-center ${hasHomeEV ? "positive-ev" : "positive-ev-other"}`}>
          <div className="d-flex flex-row align-items-center">
            <div className="odds mr-2" style={{ minWidth: "35px" }}>
              {formatOdd(homePrice??0)}
            </div>
            {homeBetMaxes.map((bet, index) => (
              <BookieImage key={index} bookie={bet.bookie} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookiesCell;
