// RoleAccess.ts
import { Role } from "../types/User";


export const RoleAccess: Record<Role, string[]> = {
  [Role.USER]: ['BetFinder', 'BetJuicer', 'CommunityBoard', 'AccountSettings', 'PricingPage','Util','SureBets','SureBetSection','SuggestionWall','SuggestionComp' ],
  [Role.BRONZE]: ['CreateBettingGroup', 'AccountSettings','PositiveEV' ],
  [Role.SILVER]: ['LiveMode', 'ParlayBuilder','PositiveEV'],
  [Role.GOLD]: ['PositiveEV'],
  [Role.ADMIN]: ['Util','PositiveEV'],
}
