import React from 'react';

type EvPercentsCellProps = {
  evPercents: number[] | null;  // Allow for the possibility that evPercents might be null
};

const EvPercentsCell: React.FC<EvPercentsCellProps> = ({ evPercents }) => {
  // Initialize empty array for positive EV percents
  const evPercentsPositive = [];

  // Check if evPercents is not null or undefined before accessing its elements
  if (evPercents) {
    const awayEvPercent = evPercents[0];
    const homeEvPercent = evPercents[1];

    if (awayEvPercent > 0) evPercentsPositive.push(awayEvPercent);
    if (homeEvPercent > 0) evPercentsPositive.push(homeEvPercent);
 
  }

  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column", 
    justifyContent: "center",
    alignItems: "center",
    height: "100%", 
  };

  return (
    <div className="font-medium" style={style}>
      {evPercentsPositive.map((evPercent, index) => (
        <div key={index} className="py-2">
          {evPercent}%
        </div>
      ))}
    </div>
  );
};

export default EvPercentsCell;
