import { METHODS } from "http";
import React from "react";
import { Placeholder } from "react-bootstrap";
import { Bet, PositiveEVRow } from "../../types/positive-ev.types";
import { getOppositeBets, placeHolderBet, placeHolderBets } from "./bet-cell.constants";

type BestBetsCellProps = {
  positiveEVRow: PositiveEVRow;
};

const BestBetsCell: React.FC<BestBetsCellProps> = ({ positiveEVRow }) => {
  let awayBets = positiveEVRow.away_bets;
  let homeBets = positiveEVRow.home_bets;
  if ((!awayBets && !homeBets) || (awayBets.length===0 && homeBets.length===0)) return null;

  if (!awayBets || awayBets.length===0) { 
    const homeBetName = homeBets?.[0].name  
    awayBets = homeBetName? getOppositeBets(homeBetName): placeHolderBets;
  }
  if (!homeBets || homeBets.length===0) {
    const awayBetName = awayBets?.[0].name  
    homeBets = awayBetName? getOppositeBets(awayBetName): placeHolderBets;
  }

  const bestAwayBet = awayBets?.[0];
  const bestHomeBet = homeBets?.[0];

  const hasAwayEV = positiveEVRow.away_evpercent > 0;
  const hasHomeEV = positiveEVRow.home_evpercent > 0;

  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
  };

  return (
    <div style={style}>
      {bestAwayBet && (
        <div
          className={`mx-0 ${hasAwayEV ? "positive-ev" : "positive-ev-other"}`}
        >
          {bestAwayBet.name}
        </div>
      )}
      {bestHomeBet && (
        <div
          className={`mt-3 mx-0 ${
            hasHomeEV ? "positive-ev" : "positive-ev-other"
          }`}
        >
          {bestHomeBet.name}
        </div>
      )}
    </div>
  );
};

export default BestBetsCell;
