import React from 'react';

const ImpliedOddsWriteUp: React.FC = () => {
  return (
    <div>
      <h2 className="mb-3 lg:text-lg text-powder-bloo font-code-next">How to Compare Implied Probability to Fair Odds</h2>
      <p className="mb-3">
        The implied probability calculator helps bettors compare the implied probability of a betting market against the actual “fair” probability of that market.
      </p>
      <p className="mb-3">
        Implied Probability is a percentage that is derived by converting the given betting odds that represent the likelihood of that outcome occurring. This percentage factors in the bookmaker’s profit margin as well. One way to use implied probability with betting odds is to compare the implied probability of a betting market against the actual “fair” probability of that market – without the juice or vig baked in.
      </p>
      <p className="mb-3">
        If we find that the offered implied probability is lower than the fair probability (based on the no-vig line), then we’ve officially located the all-elusive bet we want to take – a bet with Positive Expected Value! Obviously, we all want to bet on ‘winners’ but much more importantly we ONLY want to bet on teams where the implied odds are either lower or accurately represent the chance of that team winning.
      </p>
      <p className="mb-3">
        The key to locating valuable betting opportunities is finding an actual winning probability that is greater than or equal to the implied winning probability estimated by the sportsbook based on the fair odds or the odds without the vig baked in.
      </p>
    </div>
  );
};

export default ImpliedOddsWriteUp;
