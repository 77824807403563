import React, { useState, useEffect } from "react";
import {
  postRequest,
  getRequest,
  refreshAccessToken,
  getIsAuthenticated,
  deleteRequest,
} from "../../services/apiService";
import styles from "../login/login.module.scss";
import SuggestionComp from "./Suggestion";
import "./SuggestionComp.scss";
import "../landing-page/LandingPage.scss";
import { useNavigate } from "react-router-dom";
import { addVoteDetailsToSuggestions, bgImage } from "./suggestion-service";
import { AddSuggestion } from "./AddSuggestion";
import { previewSuggestions } from "./suggestion-constants";
import UnauthModal from "../commons/unauth/UnauthModal";

export interface Suggestion {
  id: number;
  content: string;
  created_user_id: number;
  voted_user_details: { [userId: number]: boolean };
  rating: number;
}

//make a boolean that determines if this is temp render if so dont redirect if not authed cause its just a placeholder

interface SuggestionWallProps {
  userId: number;
}
export const SuggestionWall: React.FC<SuggestionWallProps> = ({ userId }) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [blurClass, setBlurClass] = useState<string>("");
  const [showUnauth, setShowUnauth] = useState<boolean>(false);

  const navigate = useNavigate();
  //const location = useLocation();

  useEffect(() => {
    const fetchSuggestions = async () => {
      const isAuthenticated = await getIsAuthenticated();

      if (isAuthenticated) {
        const response = await getRequest("/api/v1/suggestions");

        if (response && response.data && Array.isArray(response.data)) {
          setSuggestions(response.data);
        } else {
          console.error(
            "Unexpected data format from /api/v1/suggestions",
            response
          );
        }
      } else {
        // The user is not authenticated. Try to refresh the access token.
        const refreshSuccessful = await refreshAccessToken();

        if (refreshSuccessful) {
          // The token was successfully refreshed. Reload the page to use the new token.
          window.location.reload();
        } else {
          const augmentedPreviewSuggestions = addVoteDetailsToSuggestions(previewSuggestions, userId);
          if (augmentedPreviewSuggestions.length > 0) delete augmentedPreviewSuggestions[0].voted_user_details[userId];
          setSuggestions(augmentedPreviewSuggestions);
          setBlurClass("blurred no-interaction");
          setShowUnauth(true);
        }
      }
    };

    fetchSuggestions();
  }, [navigate]);

  const handleAddSuggestion = async (newSuggestion: string) => {
    const body = {
      content: newSuggestion,
      userId: userId,
    };
    const newSuggestionData = await postRequest("/api/v1/suggestions", body);

    if (newSuggestionData && newSuggestionData.id) {
      setSuggestions((prevSuggestions) => [
        newSuggestionData,
        ...prevSuggestions,
      ]);
    } else {
      console.error(
        "Unexpected data format when posting new suggestion",
        newSuggestionData
      );
    }
  };

  const handleDeleteSuggestion = async (suggestionId: number) => {
    try {
      await deleteRequest(`/api/v1/suggestions/${suggestionId}`);
      setSuggestions((prevSuggestions) =>
        prevSuggestions.filter((suggestion) => suggestion.id !== suggestionId)
      );
    } catch (error) {
      console.error("Error deleting suggestion", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };


  return (
    <>
    <div className={styles.modalWrappe}>
      <UnauthModal isVisible={showUnauth} message="Sign in to leave some suggestions!" />
    </div>
    <div
      className={styles.transparentWrapper }
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div
        className={`${styles.modalWrapper} d-flex justify-content-center align-items-center flex-column suggestion-modal-width margin-navbar`}
      >
        <div className={styles.header}>
          {" "}
          {/* Header Container */}
          <div className="d-flex align-items-center justify-content-center mb-2">
          </div>
          <div>
            <h4 className={styles.shine}>
              Suggestions
              <span className="tip">
                <img
                  src="https://storage.googleapis.com/assestsbookiebrawler/icons/tool-tip-icon.svg"
                  alt="Info Icon"
                  style={{ width: "16px", height: "16px", marginLeft: "8px" }}
                />
                <span className="tooltiptext">
                  <p>
                    <strong>How to Use this Wall:</strong>
                  </p>

                  <p>
                    <strong>Dream Big</strong>: What have you always wanted to
                    see in a value betting software tool? Share it here!
                  </p>

                  <p>
                    <strong>Specifics Matter</strong>: Detail is king. The more
                    we know, the better we can craft it for you.
                  </p>

                  <p>
                    <strong>Critical Feedback</strong>: Don't hold back; your
                    honest feedback is our growth tool. "must have's" are
                    welcome even if they seem obvious
                  </p>

                  <p>
                    <strong>Vote</strong>: Like an idea? Vote it up to see it
                    come to life in BookieBrawler.
                  </p>
                  <p>
                    Every suggestion brings us a step closer to perfection.
                    Let's build the future of sports betting, together.
                  </p>
                </span>
              </span>
            </h4>
          </div>
        </div>
        <div className="mb-2 pb-2 w-100">
          <AddSuggestion onAdd={handleAddSuggestion} />
        </div>
        <div className={styles.body}>
          <div className="bg-powder-blue text-light-bloo rounded py-1 mb-2">
            {suggestions.map((suggestion, index) => {
              const currentBlurClass = index !== 0 ? blurClass: ""; // Only apply the blurClass if it's not the first suggestion
              return (
                <div key={suggestion.id} className={currentBlurClass}>
                  <SuggestionComp
                    suggestion={suggestion}
                    userId={userId}
                    onDelete={handleDeleteSuggestion}
                  />
                </div>
              );
            })}
          </div>
        </div>


        <div className={`${styles.footer} w-100`}>
          {" "}
        </div>
      </div>
    </div>
    </>
  );
};

export default SuggestionWall;
