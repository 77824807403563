import React, { useState, useEffect } from 'react';

const BonusBetConverter: React.FC = () => {
  const [bonusBetAmount, setBonusBetAmount] = useState<number | string>(100);
  const [bonusBetLine, setBonusBetLine] = useState<number | string>(120);
  const [hedgeLine, setHedgeLine] = useState<number | string>(-130);
  const [hedgeBet, setHedgeBet] = useState<number>(0);
  const [profit, setProfit] = useState<number>(0);
  const [profitPercentage, setProfitPercentage] = useState<number>(0);

  const calculateHedgeBet = (bonusAmount: number, bonusLine: number, hedgeLine: number) => {
    const payoutFromFreeBet = bonusAmount * (bonusLine / 100);
    const netProfit = payoutFromFreeBet / (1 + Math.abs(hedgeLine) / 100);
    const hedgeBetAmount = payoutFromFreeBet - netProfit;
    const profitPct = (netProfit / bonusAmount) * 100;

    return {
      hedgeBetAmount,
      netProfit,
      profitPct
    };
  };

  useEffect(() => {
    const bonusAmount = Number(bonusBetAmount);
    const bonusLine = Number(bonusBetLine);
    const hedgeLineValue = Number(hedgeLine);

    const { hedgeBetAmount, netProfit, profitPct } = calculateHedgeBet(bonusAmount, bonusLine, hedgeLineValue);

    setHedgeBet(hedgeBetAmount);
    setProfit(netProfit);
    setProfitPercentage(profitPct);
  }, [bonusBetAmount, bonusBetLine, hedgeLine]);

  return (
    <div className="container max-w-[350px] w-auto p-4 bg-gray-800 rounded-lg text-white border-[0.1px] border-blue-200">
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <span className='w-1/2 font-bold text-sm'>Free Play Amount</span>
          <input
            type="number"
            value={bonusBetAmount}
            onChange={(e) => setBonusBetAmount(e.target.value)}
            className="w-1/2 p-2 bg-gray-900 rounded"
          />
        </div>
        <div className="flex items-center">
          <span className='w-1/2 font-bold text-sm'>Free Play Line</span>
          <input
            type="number"
            value={bonusBetLine}
            onChange={(e) => setBonusBetLine(e.target.value)}
            className="w-1/2 p-2 bg-gray-900 rounded"
          />
        </div>
        <div className="flex items-center">
          <span className='w-1/2 font-bold text-sm'>Hedge Line</span>
          <input
            type="number"
            value={hedgeLine}
            onChange={(e) => setHedgeLine(e.target.value)}
            className="w-1/2 p-2 bg-gray-900 rounded"
          />
        </div>
        <div className="flex justify-between mt-4">
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">Hedge Bet</span>
            <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">
              ${hedgeBet.toFixed(2)}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">$ Profit</span>
            <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">
              ${profit.toFixed(2)}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">% Profit</span>
            <div className="p-2 bg-gray-900 rounded h-12 flex items-center justify-center text-sm font-medium">
              {profitPercentage.toFixed(2)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusBetConverter;
