import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => (
  <div>
    <Helmet>
      <title>Page Not Found - BookieBrawler</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div className="p-5 ">
      <h1>Page Not Found</h1>
      <p>
        Sorry, the page you are looking for does not exist. Please check the URL
        or go back to the homepage.
      </p>
      <a href="/">Go to Homepage</a>
    </div>
  </div>
);

export default NotFound;
