import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CalculatorGrid from "./calculator-card";
import PillGroup from "./calculator-pill-group";

export const createAnchorTag = (linkTarget: string, hover?: boolean) => {
  return (
    <a href={linkTarget} className="no-underline absolute top-2 right-2">
      {/* <>^</> */}
      <img src={hover? "https://storage.googleapis.com/assestsbookiebrawler/icons/link-icon-blue.png": "https://storage.googleapis.com/assestsbookiebrawler/icons/link-icon-light-blue.png"}></img>
      {/* <button className="ctaButton">Learn More</button> */}
    </a>
  );
};


const BettingCalculators: React.FC = () => {

  return (
    <HelmetProvider>
      <div className="m-4 px-3">
        <Helmet>
          <title>All Betting Calculators & Sports Betting Probability | BookieBrawler</title>
          <meta
            name="description"
            content="Use our free sports betting calculators tailor made to help you profit"
          />
        </Helmet>
        <h1 className="tracking-wide font-bold text-left lg:text-4xl md:text-2xl text-xl font-code-next text-brand-gray-9 pb-4">
          All Betting Calculators & Sports Betting Probability
        </h1>
        <PillGroup />
        <CalculatorGrid />
      </div>
    </HelmetProvider>
  );
};

export default BettingCalculators;
